package com.ecosave.watch.portal.components.esg.marketingandlabeling

import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.MarketingAndLabelingField
import com.ecosave.watch.portal.models.esg.MarketingAndLabelingState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLElement

external interface Section4173Props : Props {
    var state: MarketingAndLabelingState
    var onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
}

val Section417_3 = FC<Section4173Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val state = props.state

    SectionMainTitle {
        title = "417-3 INCIDENTS OF NON-COMPLIANCE CONCERNING MARKETING COMMUNICATIONS"
    }
    SectionSubHeading {
        subHeading =
            "Exclude incidents of non-compliance in which the organization was determined not to be at fault."
    }

    Box {
        className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
        TableHeader {
            header = ""
        }
        TableHeader {
            header = "Current Year"
        }
        TableHeader {
            header = "Previous Year(s)"
        }
        Label {
            label = "Total number of incidents of non-compliance with regulations resulting in a fine or penalty."
        }
        ESGNumberFieldWrapper {
            name = MarketingAndLabelingField.SECTION_417_3_A1.name
            value = state.section4173A1
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        ESGNumberFieldWrapper {
            name = MarketingAndLabelingField.SECTION_417_3_B1.name
            value = state.section4173B1
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        Label {
            label = "Total number of incidents of non-compliance with regulations resulting in a warning."
        }
        ESGNumberFieldWrapper {
            name = MarketingAndLabelingField.SECTION_417_3_A2.name
            value = state.section4173A2
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        ESGNumberFieldWrapper {
            name = MarketingAndLabelingField.SECTION_417_3_B2.name
            value = state.section4173B2
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        Label {
            label = "Total number of incidents of non-compliance with voluntary codes."
        }
        ESGNumberFieldWrapper {
            name = MarketingAndLabelingField.SECTION_417_3_A3.name
            value = state.section4173A3
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        ESGNumberFieldWrapper {
            name = MarketingAndLabelingField.SECTION_417_3_B3.name
            value = state.section4173B3
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        Label {
            label =
                "Total number of incidents of non-compliance with regulations and/or voluntary codes concerning marketing communications, including advertising, promotion, and sponsorship."
        }
        ESGNumberFieldWrapper {
            name = MarketingAndLabelingField.SECTION_417_3_A4.name
            value = state.section4173A4
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        ESGNumberFieldWrapper {
            name = MarketingAndLabelingField.SECTION_417_3_B4.name
            value = state.section4173B4
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        Label {
            label =
                "If the organization has not identified any non-compliance with regulations and/or voluntary codes, a brief statement of this fact is sufficient."
        }
        ESGTextAreaFieldWrapper {
            name = MarketingAndLabelingField.SECTION_417_3_FIELD1.name
            value = state.section4173Field1
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
        ESGTextAreaFieldWrapper {
            name = MarketingAndLabelingField.SECTION_417_3_FIELD2.name
            value = state.section4173Field2
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
    }
}