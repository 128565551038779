package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.GovernanceField
import com.ecosave.watch.portal.models.esg.GovernanceState
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section220Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var state: GovernanceState
}

val Section2_20 = FC<Section220Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val state = props.state

    SectionMainTitle {
        title = "2-20 PROCESS TO DETERMINE REMUNERATION"
    }

    Box {
        Label {
            label = "Describe the process for designing the organization’s remuneration policies and for determining remuneration"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_20_A1.name
            value = state.section220A1
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label =
                "Do the independent highest governance body members oversee the process for determining remuneration, or does an independent remuneration committee do so?"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_20_A2.name
            value = state.section220A2
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "How the view of stakeholders regarding remuneration are sought and taken into consideration"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_20_A3.name
            value = state.section220A3
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label =
                "Are remuneration consultants involved in determining remuneration and, if so, are they independent of the organization, its highest governance body and senior executives?"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_20_A4.name
            value = state.section220A4
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "The results of votes of stakeholders on remuneration policies and proposals, if applicable"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_20_A5.name
            value = state.section220A5
            onChange = { event -> onChangeTextArea(event) }
        }
    }

}