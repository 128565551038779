package com.ecosave.watch.portal.helpers

class Constants {
    companion object {
        const val REQUIRED_FIELD_ERROR_MESSAGE = "This Field is required!"
        const val NOTIFICATION_ERROR_MESSAGE = "An error has occurred, please retry."
        const val GENERIC_EXCEPTION_MESSAGE = "There is a problem at our end. Please retry, and if the issue persists, reach out to the support team."
        const val VALID_EMAIL_ERROR_MESSAGE = "Please add valid email address."
        const val VALID_URL_ERROR_MESSAGE = "Please add valid url address."
        const val NUMBER_MAX_LENGTH = 9
        const val LONG_NUMBER_MAX_LENGTH = 16L
        const val TEXT_AREA_MIN_ROWS = 5
        const val TEXT_AREA_MAX_ROWS = 20
    }
}