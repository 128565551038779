package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class LocalCommunityState(
    var section4131A1: Double? = null,
    var section4131A2: Double? = null,
    var section4131A3: Double? = null,
    var section4131A4: Double? = null,
    var section4131A5: Double? = null,
    var section4131A6: Double? = null,
    var section4131A7: Double? = null,
    var section4131A8: Double? = null,
    var section4131A9: Double? = null,
    var section4132A1: String? = null,
    var section4132A2: String? = null,
    var section4132B1: String? = null,
    var section4132B2: String? = null,
    var section4132C1: String? = null,
    var section4132C2: String? = null,
)
