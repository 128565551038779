package com.ecosave.watch.portal.components.esg.marketingandlabeling

import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.MarketingAndLabelingField
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.MarketingAndLabelingState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

external interface MarketingAndLabelingProps : Props {
    var reportState: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
    var onBlurApiCall: (String, JsonPrimitive, Int?, String?, String) -> Unit
}

val MarketingAndLabeling = FC<MarketingAndLabelingProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.marketingAndLabeling?.let { state ->

        fun stateSetter(state: MarketingAndLabelingState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        marketingAndLabeling = state
                    )
                )
            )
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = MarketingAndLabelingField.valueOf(target.name)
                val propertyValue = target.value.toDoubleOrNull()

                stateSetter(
                    state.copy(
                        section4171A6 = if (propertyName == MarketingAndLabelingField.SECTION_417_1_A6) propertyValue else state.section4171A6
                    )
                )

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName.fieldName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.MARKETING_AND_LABELING.name,
                        reportName = reportState.reportFileName
                    )
                )
            }

            if (isValidLongNumber(target)) {
                val propertyName = MarketingAndLabelingField.valueOf(target.name)
                val propertyValue = target.value.toLongOrNull()

                stateSetter(
                    state.copy(
                        section4172A1 = if (propertyName == MarketingAndLabelingField.SECTION_417_2_A1) propertyValue else state.section4172A1,
                        section4172A2 = if (propertyName == MarketingAndLabelingField.SECTION_417_2_A2) propertyValue else state.section4172A2,
                        section4172A3 = if (propertyName == MarketingAndLabelingField.SECTION_417_2_A3) propertyValue else state.section4172A3,
                        section4172A4 = if (propertyName == MarketingAndLabelingField.SECTION_417_2_A4) propertyValue else state.section4172A4,

                        section4172B1 = if (propertyName == MarketingAndLabelingField.SECTION_417_2_B1) propertyValue else state.section4172B1,
                        section4172B2 = if (propertyName == MarketingAndLabelingField.SECTION_417_2_B2) propertyValue else state.section4172B2,
                        section4172B3 = if (propertyName == MarketingAndLabelingField.SECTION_417_2_B3) propertyValue else state.section4172B3,
                        section4172B4 = if (propertyName == MarketingAndLabelingField.SECTION_417_2_B4) propertyValue else state.section4172B4,

                        section4173A1 = if (propertyName == MarketingAndLabelingField.SECTION_417_3_A1) propertyValue else state.section4173A1,
                        section4173A2 = if (propertyName == MarketingAndLabelingField.SECTION_417_3_A2) propertyValue else state.section4173A2,
                        section4173A3 = if (propertyName == MarketingAndLabelingField.SECTION_417_3_A3) propertyValue else state.section4173A3,
                        section4173A4 = if (propertyName == MarketingAndLabelingField.SECTION_417_3_A4) propertyValue else state.section4173A4,

                        section4173B1 = if (propertyName == MarketingAndLabelingField.SECTION_417_3_B1) propertyValue else state.section4173B1,
                        section4173B2 = if (propertyName == MarketingAndLabelingField.SECTION_417_3_B2) propertyValue else state.section4173B2,
                        section4173B3 = if (propertyName == MarketingAndLabelingField.SECTION_417_3_B3) propertyValue else state.section4173B3,
                        section4173B4 = if (propertyName == MarketingAndLabelingField.SECTION_417_3_B4) propertyValue else state.section4173B4,
                    )
                )

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName.fieldName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.MARKETING_AND_LABELING.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = MarketingAndLabelingField.valueOf(target.name)
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(propertyName.fieldName, JsonPrimitive(propertyValue), changeIndex, field, EsgSection.MARKETING_AND_LABELING.name)
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = MarketingAndLabelingField.valueOf(target.name)
            val propertyValue = target.value

            stateSetter(
                state.copy(
                    section4171A1 = if (propertyName == MarketingAndLabelingField.SECTION_417_1_A1) propertyValue else state.section4171A1,
                    section4171A2 = if (propertyName == MarketingAndLabelingField.SECTION_417_1_A2) propertyValue else state.section4171A2,
                    section4171A3 = if (propertyName == MarketingAndLabelingField.SECTION_417_1_A3) propertyValue else state.section4171A3,
                    section4171A4 = if (propertyName == MarketingAndLabelingField.SECTION_417_1_A4) propertyValue else state.section4171A4,
                    section4171A5 = if (propertyName == MarketingAndLabelingField.SECTION_417_1_A5) propertyValue else state.section4171A5,

                    section4172Field1 = if (propertyName == MarketingAndLabelingField.SECTION_417_2_FIELD1) propertyValue else state.section4172Field1,
                    section4172Field2 = if (propertyName == MarketingAndLabelingField.SECTION_417_2_FIELD2) propertyValue else state.section4172Field2,

                    section4173Field1 = if (propertyName == MarketingAndLabelingField.SECTION_417_3_FIELD1) propertyValue else state.section4173Field1,
                    section4173Field2 = if (propertyName == MarketingAndLabelingField.SECTION_417_3_FIELD2) propertyValue else state.section4173Field2
                )
            )

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName.fieldName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.MARKETING_AND_LABELING.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = MarketingAndLabelingField.valueOf(target.name)
            val propertyValue = target.value
            props.onBlurApiCall(propertyName.fieldName, JsonPrimitive(propertyValue), changeIndex, field, EsgSection.MARKETING_AND_LABELING.name)
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            Section417_1 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onBlurTextArea = onBlurTextArea
                this.onChangeNumber = onChangeNumber
                this.onBlurNumber = onBlurNumber
            }
            Section417_2 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onBlurTextArea = onBlurTextArea
                this.onChangeNumber = onChangeNumber
                this.onBlurNumber = onBlurNumber
            }
            Section417_3 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onBlurTextArea = onBlurTextArea
                this.onChangeNumber = onChangeNumber
                this.onBlurNumber = onBlurNumber
            }
        }
    }
}