package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.models.esg.EsgReportState
import mui.icons.material.InfoOutlined
import mui.material.Checkbox
import mui.material.IconButton
import mui.material.Size
import mui.material.Tooltip
import mui.system.Box
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.useEffect
import react.useEffectOnce
import react.useState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles

external interface SelectEsgSectionsComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
}

@OptIn(ExperimentalStdlibApi::class)
val SelectEsgSectionsComponent = FC<SelectEsgSectionsComponentProps> { props ->

    val esgReportState = props.esgReportStateProps

    var checksState by useState(
        mutableMapOf<EsgSection, Boolean>().also {
            EsgSection.entries.forEach { enumValue ->
                it[enumValue] = false
            }
            it[EsgSection.GENERAL_DISCLOSURES] = true
        }
    )

    useEffectOnce {
        esgReportState.sections.forEach { section ->
            checksState[section] = true
        }
    }

    useEffect(checksState) {

        val list = checksState.filter { it.value }.keys.toMutableList()

        props.esgReportStateSetterProps(
            esgReportState.copy(
                sections = list
            )
        )
    }

    Box {
        className = styles.MAIN_ESG_FORM.cssClass
        Box {
            Checkbox {
                onChange = { _, checked ->

                    checksState = checksState.mapValues {
                        checked || it.key == EsgSection.GENERAL_DISCLOSURES
                    } as MutableMap<EsgSection, Boolean>

                    val sectionsToUpdate =
                        EsgSection.entries.filter { it != EsgSection.GENERAL_DISCLOSURES }.toTypedArray()

                    if (checked) {
                        esgReportState.sections.addAll(sectionsToUpdate)
                    } else {
                        esgReportState.sections.removeAll(sectionsToUpdate)
                    }
                }
            }
            +"Select All?"
        }

        Box {
            className = styles.ESG_CHECKBOXES.cssClass
            checksState.forEach { (label, check) ->
                Box {
                    Checkbox {
                        checked = check
                        disabled = label == EsgSection.GENERAL_DISCLOSURES
                        onChange = { _, checked ->
                            checksState = checksState.toMutableMap().apply {
                                put(label, !check)
                            }
                        }
                    }
                    +label.description
                    Tooltip {
                        IconButton {
                            size = Size.small
                            InfoOutlined()
                        }
                        title = ReactNode(label.info)
                    }
                }
            }
        }
    }
}