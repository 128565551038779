package com.ecosave.watch.portal.models.esg

import kotlin.random.Random
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.uuid.UUID
import kotlinx.uuid.generateUUID

@Serializable
data class PublicPolicyState(
    var sectionPublicPolicyList: MutableList<SectionPublicPolicy> = MutableList(1) { SectionPublicPolicy() }
)

@Serializable
data class SectionPublicPolicy(
    @Transient
    val key: String = UUID.generateUUID(Random).toString(),
    var section4151A1: String? = null,
    var section4151A2: String? = null,
    var section4151A3: Long? = null,
    var section4151A4: String? = null
)