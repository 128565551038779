package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.models.esg.ActivitiesAndWorkersState
import react.FC
import react.Props

external interface Section28Props : Props {
    var state: ActivitiesAndWorkersState
}

val Section2_8 = FC<Section28Props> { props ->

    val state = props.state

    SectionMainTitle {
        title = "=======================================2-8 WORKERS WHO ARE NOT EMPLOYEES================================="
    }

//    TODO: Complete this section later

    SectionSubHeading {
        subHeading = "===========================Repeating rows/section======================="
    }

}