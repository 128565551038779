package com.ecosave.watch.portal.components.esg.customerprivacy

import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.CustomerPrivacyField
import com.ecosave.watch.portal.models.esg.CustomerPrivacyState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLElement

external interface Section4181Props : Props {
    var state: CustomerPrivacyState
    var onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
}

val Section418_1 = FC<Section4181Props> { props ->

    val onChangeNumber = props.onChangeNumber
    val onChangeTextArea = props.onChangeTextArea
    val onBlurNumber = props.onBlurNumber
    val onBlurTextArea = props.onBlurTextArea
    val state = props.state

    SectionMainTitle {
        title = "418-1 SUBSTANTIATED COMPLAINTS CONCERNING BREACHES OF CUSTOMER PRIVACY AND LOSSES OF CUSTOMER DATA"
    }

    Box {
        className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
        TableHeader {
            header = ""
        }
        TableHeader {
            header = "Current Year"
        }
        TableHeader {
            header = "Previous Year"
        }
        Label {
            label = "Total number of complaints concerning breaches of customer privacy - Complaints received from outside."
        }
        ESGNumberFieldWrapper {
            name = CustomerPrivacyField.SECTION_418_1_A1.name
            value = state.section4181A1
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        ESGNumberFieldWrapper {
            name = CustomerPrivacyField.SECTION_418_1_B1.name
            value = state.section4181B1
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        Label {
            label = "Total number of complaints concerning breaches of customer privacy - Complaints from regulatory bodies."
        }
        ESGNumberFieldWrapper {
            name = CustomerPrivacyField.SECTION_418_1_A2.name
            value = state.section4181A2
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        ESGNumberFieldWrapper {
            name = CustomerPrivacyField.SECTION_418_1_B2.name
            value = state.section4181B2
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        Label {
            label = "Total number of identified leaks, thefts, or losses of customer data."
        }
        ESGNumberFieldWrapper {
            name = CustomerPrivacyField.SECTION_418_1_A3.name
            value = state.section4181A3
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        ESGNumberFieldWrapper {
            name = CustomerPrivacyField.SECTION_418_1_B3.name
            value = state.section4181B3
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
        Label {
            label = "If the organization has not identified any substantiated complaints, a brief statement of this fact is sufficient."
        }
        ESGTextAreaFieldWrapper {
            name = CustomerPrivacyField.SECTION_418_1_A4.name
            value = state.section4181A4
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
        ESGTextAreaFieldWrapper {
            name = CustomerPrivacyField.SECTION_418_1_B4.name
            value = state.section4181B4
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
    }
}