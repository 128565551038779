package com.ecosave.watch.portal.components.esg.anticompetitivebehavior

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.AntiCompetitiveBehaviorField
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.models.esg.AntiCompetitiveBehaviorState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLElement

external interface Section2061Props : Props {
    var state: AntiCompetitiveBehaviorState
    var onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var addRowOrSection: (String) -> Unit
    var deleteRowOrSection: (String, Int) -> Unit
}

val Section206_1 = FC<Section2061Props> { props ->

    val onChangeNumber = props.onChangeNumber
    val onChangeTextArea = props.onChangeTextArea
    val onBlurNumber = props.onBlurNumber
    val onBlurTextArea = props.onBlurTextArea
    val state = props.state

    SectionMainTitle {
        title = "206-1 LEGAL ACTIONS FOR ANTI-COMPETITIVE BEHAVIOR, ANTI-TRUST, AND MONOPOLY PRACTICES"
    }

    label {
        +"Number of legal actions pending or completed during the reporting period regarding anti-competitive behavior and violations of anti-trust and monopoly legislation in which the organization has been identified as a participant."
    }
    ESGNumberFieldWrapper {
        name = AntiCompetitiveBehaviorField.SECTION_206_1_Field1.name
        value = state.section2061Field1
        onChange = { event -> onChangeNumber(event, null, null) }
        onBlur = { event -> onBlurNumber(event, null, null) }
    }

    Box {
        className = ESGCommonStyles.TABLE_WRAPPER.cssClass
        DynamicTableWrapper {
            addRowOrSection = {
                props.addRowOrSection(AntiCompetitiveBehaviorField.SECTION_206_1_LEGAL_ACTIONS_OUTCOMES.fieldName)
            }
            items = state.legalActionsOutcomesList
            action = DynamicTableAction.ADD
            addRowOrSectionBtnText = "Add a new row"
            Box {
                className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                TableHeader {
                    header = "Legal actions"
                }
                TableHeader {
                    header = "Main outcomes of completed legal actions, including any decisions or judgements."
                }
            }
        }
        state.legalActionsOutcomesList.forEachIndexed { index, it ->
            DynamicTableWrapper {
                key = it.key
                deleteRowOrSection = {
                    props.deleteRowOrSection(AntiCompetitiveBehaviorField.SECTION_206_1_LEGAL_ACTIONS_OUTCOMES.fieldName, index)
                }
                items = state.legalActionsOutcomesList
                action = DynamicTableAction.DELETE
                variant = DynamicTableVariant.TABLE
                Box {
                    className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                    ESGTextAreaFieldWrapper {
                        name = AntiCompetitiveBehaviorField.SECTION_206_1_A1.name
                        value = it.section2061A1
                        onChange =
                            { event -> onChangeTextArea(event, index, AntiCompetitiveBehaviorField.SECTION_206_1_LEGAL_ACTIONS_OUTCOMES.fieldName) }
                        onBlur =
                            { event -> onBlurTextArea(event, index, AntiCompetitiveBehaviorField.SECTION_206_1_LEGAL_ACTIONS_OUTCOMES.fieldName) }
                    }
                    ESGTextAreaFieldWrapper {
                        name = AntiCompetitiveBehaviorField.SECTION_206_1_B1.name
                        value = it.section2061B1
                        onChange =
                            { event -> onChangeTextArea(event, index, AntiCompetitiveBehaviorField.SECTION_206_1_LEGAL_ACTIONS_OUTCOMES.fieldName) }
                        onBlur =
                            { event -> onBlurTextArea(event, index, AntiCompetitiveBehaviorField.SECTION_206_1_LEGAL_ACTIONS_OUTCOMES.fieldName) }
                    }
                }
            }
        }
    }
}