package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.helpers.common.OperationType
import com.ecosave.watch.portal.helpers.common.handleNotificationStatus
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.services.esg.esgDownloadReport
import kotlinx.coroutines.launch
import mui.icons.material.Delete
import mui.icons.material.Edit
import mui.icons.material.InfoOutlined
import mui.icons.material.PictureAsPdf
import mui.material.Box
import mui.material.IconButton
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableRow
import mui.material.Tooltip
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexWrap
import web.cssom.px

external interface ReportSummaryTableRowPropsComponent : Props {
    var esgReportProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var esgReportListProps: List<EsgReportState>
    var esgReportListStateSetterProps: StateSetter<List<EsgReportState>>
    var esgReportIsClicked: StateSetter<Boolean>
    var esgReportPageNumber: Int
    var esgReportPageNumberSetter: StateSetter<Int>
    var deleteConfirmationOpen: StateSetter<Boolean>
    var deleteConfirmationTitle: StateSetter<String>
    var deleteConfirmationBody: StateSetter<String>
    var deleteConfirmationFile: StateSetter<String>
}

val EsgReportSummaryTableRowComponent = FC<ReportSummaryTableRowPropsComponent> { props ->
    val esgReport = props.esgReportProps
    var isLoading by useState(false)
    val (notificationState, notificationStateSetter) = useState(NotificationState())

    TableRow {
        TableCell {
            align = TableCellAlign.left
            +esgReport.reportFileName
        }
        TableCell {
            align = TableCellAlign.left
            +esgReport.reportTitle
        }
        TableCell {
            align = TableCellAlign.left
            +esgReport.reportFinancialYear.financialYear.toString()
        }
        TableCell {
            align = TableCellAlign.left
            Box {
                sx {
                    display = Display.flex
                    alignItems = AlignItems.center
                    flexWrap = FlexWrap.wrap
                }
                esgReport.modifiedBy?.let {
                    +"${it.firstName} ${it.lastName}"
                    Tooltip {
                        sx {
                            marginLeft = 5.px
                        }
                        InfoOutlined()
                        title = ReactNode(it.emailAddress)
                    }
                }
            }
        }
        TableCell {
            align = TableCellAlign.left
            +esgReport.lastModifiedTime
        }
        TableCell {
            align = TableCellAlign.left
            +"${esgReport.mainTableOfContents?.totalPercentCompleted}"
        }
        TableCell {
            align = TableCellAlign.left

            IconButton {
                PictureAsPdf()
                disabled = isLoading
                onClick = {
                    mainScope.launch {
                        isLoading = true
                        try {
                            val status = esgDownloadReport(esgReport.reportFileName)
                            handleNotificationStatus(status, notificationState, notificationStateSetter, OperationType.DOWNLOAD)
                        } catch (e: Exception) {
                            console.error(e.message)
                        }
                        isLoading = false
                    }
                }
            }
            IconButton {
                Edit()
                onClick = {
                    props.esgReportStateSetterProps(esgReport) // maybe we remove or we don't based on further development.
                    props.esgReportPageNumberSetter(2)
                    props.esgReportIsClicked(true)
                }
            }
            IconButton {
                Delete()
                onClick = {
                    props.deleteConfirmationOpen(true)
                    props.deleteConfirmationBody("Are you sure you want to delete this report ?")
                    props.deleteConfirmationTitle("Confirmation")
                    props.deleteConfirmationFile(esgReport.reportFileName)
                }
            }
        }
    }
    DialogSpinner {
        open = isLoading
        message = "Downloading..."
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            notificationStateSetter(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}