package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.models.esg.TheOrgAndItsRepPracticesState
import react.FC
import react.Props

external interface Section24Props : Props {
    var state: TheOrgAndItsRepPracticesState
}

val Section2_4 = FC<Section24Props> { props ->

    val state = props.state

    SectionMainTitle {
        title = "=======================================2-4 RESTATEMENTS OF INFORMATION=================================="
    }

//    TODO: Complete this section later

    SectionSubHeading {
        subHeading = "===================Repeating rows/section============================"
    }

}