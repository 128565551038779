package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class AntiCorruptionState(
    var section2051A1: Int? = null,
    var section2051A2: Double? = null,
    var section2051A3: String? = null,
    var section2052A1: Int? = null,
    var section2052A2: Double? = null,
    var section2052A3: Int? = null,
    var section2052A4: Double? = null,
    var section2052A5: Int? = null,
    var section2052A6: Double? = null,
    var section2052A7: Int? = null,
    var section2052A8: Double? = null,
    var section2052A9: Int? = null,
    var section2052A10: Double? = null,
    var section2052A11: String? = null,
    var section2053A1: Int? = null,
    var section2053A2: Int? = null,
    var section2053A3: Int? = null,
    var section2053Case1: String? = null,
    var section2053Case2: String? = null,
    var section2052Case3: String? = null,
    var section2053Outcome1: String? = null,
    var section2053Outcome2: String? = null,
    var section2052OutCome3: String? = null
)