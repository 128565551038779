package com.ecosave.watch.portal.components.esg.economicperformance

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.EconomicPerformanceField
import com.ecosave.watch.portal.models.esg.EconomicPerformanceState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import mui.system.sx
import react.FC
import react.Props
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.cssom.px
import web.html.HTMLDivElement
import web.html.HTMLElement

external interface Section2012Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var state: EconomicPerformanceState
    var addRowOrSection: (String) -> Unit
    var deleteRowOrSection: (String, Int) -> Unit
}

val Section201_2 = FC<Section2012Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val state = props.state

    SectionMainTitle {
        title = "201-2 FINANCIAL IMPLICATIONS AND OTHER RISKS AND OPPORTUNITIES DUE TO CLIMATE CHANGE"
    }
    SectionSubHeading {
        subHeading =
            "Risks and opportunities posed by climate change that have the potential to generate substantive changes in operations, revenue, or expenditure, including:"
    }

    Box {
        className = ESGCommonStyles.STICKY_HEADER.cssClass
        DynamicTableWrapper {
            addRowOrSection = {
                props.addRowOrSection(EconomicPerformanceField.SECTION_201_2_RISK.fieldName)
            }
            items = state.financialRisksList
            action = DynamicTableAction.ADD
            variant = DynamicTableVariant.TABLE
            addRowOrSectionBtnText = "Add a new risk"
            Box {
                sx {
                    padding = 20.px
                }
                SectionSubHeading {
                    subHeading = "Risks"
                }
            }
        }
    }

    state.financialRisksList.forEachIndexed { index, it ->
        DynamicTableWrapper {
            key = it.key
            deleteRowOrSection = {
                props.deleteRowOrSection(EconomicPerformanceField.SECTION_201_2_RISK.fieldName, index)
            }
            items = state.financialRisksList
            action = DynamicTableAction.DELETE
            Box {
                className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                SectionSubHeading {
                    subHeading = "Risk ${index + 1}"
                }
                Box {
                    label {
                        className = ESGCommonStyles.LABEL.cssClass
                        +"Provide a description of the risk and its classification as either physical, regulatory, or other."
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_201_2_RISK_A1.name
                        value = it.section2012RiskA1
                        onChange = { event -> onChangeTextArea(event, index, EconomicPerformanceField.SECTION_201_2_RISK.fieldName) }
                        onBlur = { event -> onBlurTextArea(event, index, EconomicPerformanceField.SECTION_201_2_RISK.fieldName) }
                    }
                }
                Box {
                    label {
                        className = ESGCommonStyles.LABEL.cssClass
                        +"Provide a description of the impact associated with the risk."
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_201_2_RISK_A2.name
                        value = it.section2012RiskA2
                        onChange = { event -> onChangeTextArea(event, index, EconomicPerformanceField.SECTION_201_2_RISK.fieldName) }
                        onBlur = { event -> onBlurTextArea(event, index, EconomicPerformanceField.SECTION_201_2_RISK.fieldName) }
                    }
                }
                Box {
                    label {
                        className = ESGCommonStyles.LABEL.cssClass
                        +"What were or are the financial implications of the risk before action is taken?"
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_201_2_RISK_A3.name
                        value = it.section2012RiskA3
                        onChange = { event -> onChangeTextArea(event, index, EconomicPerformanceField.SECTION_201_2_RISK.fieldName) }
                        onBlur = { event -> onBlurTextArea(event, index, EconomicPerformanceField.SECTION_201_2_RISK.fieldName) }
                    }
                }
                Box {
                    label {
                        className = ESGCommonStyles.LABEL.cssClass
                        +"What are the methods used to manage the risk?"
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_201_2_RISK_A4.name
                        value = it.section2012RiskA4
                        onChange = { event -> onChangeTextArea(event, index, EconomicPerformanceField.SECTION_201_2_RISK.fieldName) }
                        onBlur = { event -> onBlurTextArea(event, index, EconomicPerformanceField.SECTION_201_2_RISK.fieldName) }
                    }
                }
                Box {
                    label {
                        className = ESGCommonStyles.LABEL.cssClass
                        +"What are the costs of actions taken to manage the risk?"
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_201_2_RISK_A5.name
                        value = it.section2012RiskA5
                        onChange = { event -> onChangeTextArea(event, index, EconomicPerformanceField.SECTION_201_2_RISK.fieldName) }
                        onBlur = { event -> onBlurTextArea(event, index, EconomicPerformanceField.SECTION_201_2_RISK.fieldName) }
                    }
                }
            }
        }
    }

    Box {
        className = ESGCommonStyles.STICKY_HEADER.cssClass
        DynamicTableWrapper {
            addRowOrSection = {
                props.addRowOrSection(EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName)
            }
            items = state.financialOpportunitiesList
            action = DynamicTableAction.ADD
            variant = DynamicTableVariant.TABLE
            addRowOrSectionBtnText = "Add a new opportunity"
            Box {
                sx {
                    padding = 20.px
                }
                SectionSubHeading {
                    subHeading = "Opportunities"
                }
            }
        }
    }

    state.financialOpportunitiesList.forEachIndexed { index, it ->
        DynamicTableWrapper {
            key = it.key
            deleteRowOrSection = {
                props.deleteRowOrSection(EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName, index)
            }
            items = state.financialOpportunitiesList
            action = DynamicTableAction.DELETE
            Box {
                className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                SectionSubHeading {
                    subHeading = "Opportunity ${index + 1}"
                }
                Box {
                    label {
                        className = ESGCommonStyles.LABEL.cssClass
                        +"Provide a description of the opportunity and its classification as either physical, regulatory, or other."
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_201_2_OPPORTUNITY_A1.name
                        value = it.section2012OpportunityA1
                        onChange = { event -> onChangeTextArea(event, index, EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName) }
                        onBlur = { event -> onBlurTextArea(event, index, EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName) }
                    }
                }
                Box {
                    label {
                        className = ESGCommonStyles.LABEL.cssClass
                        +"Provide a description of the impact associated with the opportunity."
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_201_2_OPPORTUNITY_A2.name
                        value = it.section2012OpportunityA2
                        onBlur = { event -> onBlurTextArea(event, index, EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName) }
                        onChange = { event -> onChangeTextArea(event, index, EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName) }
                    }
                }
                Box {
                    label {
                        className = ESGCommonStyles.LABEL.cssClass
                        +"What were or are the financial implications of the opportunity before action is taken?"
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_201_2_OPPORTUNITY_A3.name
                        value = it.section2012OpportunityA3
                        onBlur = { event -> onBlurTextArea(event, index, EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName) }
                        onChange = { event -> onChangeTextArea(event, index, EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName) }
                    }
                }
                Box {
                    label {
                        className = ESGCommonStyles.LABEL.cssClass
                        +"What are the methods used to manage the opportunity?"
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_201_2_OPPORTUNITY_A4.name
                        value = it.section2012OpportunityA4
                        onBlur = { event -> onBlurTextArea(event, index, EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName) }
                        onChange = { event -> onChangeTextArea(event, index, EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName) }
                    }
                }
                Box {
                    label {
                        className = ESGCommonStyles.LABEL.cssClass
                        +"What are the costs of actions taken to manage the opportunity?"
                    }
                    ESGTextAreaFieldWrapper {
                        name = EconomicPerformanceField.SECTION_201_2_OPPORTUNITY_A5.name
                        value = it.section2012OpportunityA5
                        onBlur = { event -> onBlurTextArea(event, index, EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName) }
                        onChange = { event -> onChangeTextArea(event, index, EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName) }
                    }
                }
            }
        }
    }

    Box {
        label {
            className = ESGCommonStyles.LABEL.cssClass
            +"If the organization does not have a system in place to calculate the financial implications or costs, or to make revenue projections, report its plans and timeline to develop the necessary systems."
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_201_2_FIELD1.name
            value = state.section2012Field1
            onBlur = { event -> onBlurTextArea(event, null, null) }
            onChange = { event -> onChangeTextArea(event, null, null) }
        }
    }
}