package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class EmissionsState(
    var section3051Unit1: String? = null,
    var section3051Unit2: String? = null,
    var section3051Unit3: String? = null,
    var section3051Unit4: String? = null,
    var section3051Unit5: String? = null,
    var section3051Unit6: String? = null,
    var section3051Unit7: String? = null,
    var section3051Unit8: String? = null,
    var section3051Unit9: String? = null,
    var section3051Current1: Int? = null,
    var section3051Current2: Int? = null,
    var section3051Current3: Int? = null,
    var section3051Current4: Int? = null,
    var section3051Current5: Int? = null,
    var section3051Current6: Int? = null,
    var section3051Current7: Int? = null,
    var section3051Current8: Int? = null,
    var section3051Current9: Int? = null,
    var section3051Previous1: Int? = null,
    var section3051Previous2: Int? = null,
    var section3051Previous3: Int? = null,
    var section3051Previous4: Int? = null,
    var section3051Previous5: Int? = null,
    var section3051Previous6: Int? = null,
    var section3051Previous7: Int? = null,
    var section3051Previous8: Int? = null,
    var section3051Previous9: Int? = null,
    var section3051Baseline1: Int? = null,
    var section3051Baseline2: Int? = null,
    var section3051Baseline3: Int? = null,
    var section3051Baseline4: Int? = null,
    var section3051Baseline5: Int? = null,
    var section3051Baseline6: Int? = null,
    var section3051Baseline7: Int? = null,
    var section3051Baseline8: Int? = null,
    var section3051Baseline9: Int? = null,
    var section3052Unit1: String? = null,
    var section3052Unit2: String? = null,
    var section3052Unit3: String? = null,
    var section3052Unit4: String? = null,
    var section3052Unit5: String? = null,
    var section3052Unit6: String? = null,
    var section3052Unit7: String? = null,
    var section3052Unit8: String? = null,
    var section3052Unit9: String? = null,
    var section3052Current1: Int? = null,
    var section3052Current2: Int? = null,
    var section3052Current3: Int? = null,
    var section3052Current4: Int? = null,
    var section3052Current5: Int? = null,
    var section3052Current6: Int? = null,
    var section3052Current7: Int? = null,
    var section3052Current8: Int? = null,
    var section3052Current9: Int? = null,
    var section3052Previous1: Int? = null,
    var section3052Previous2: Int? = null,
    var section3052Previous3: Int? = null,
    var section3052Previous4: Int? = null,
    var section3052Previous5: Int? = null,
    var section3052Previous6: Int? = null,
    var section3052Previous7: Int? = null,
    var section3052Previous8: Int? = null,
    var section3052Previous9: Int? = null,
    var section3052Baseline1: Int? = null,
    var section3052Baseline2: Int? = null,
    var section3052Baseline3: Int? = null,
    var section3052Baseline4: Int? = null,
    var section3052Baseline5: Int? = null,
    var section3052Baseline6: Int? = null,
    var section3052Baseline7: Int? = null,
    var section3052Baseline8: Int? = null,
    var section3052Baseline9: Int? = null,
    var section3053Unit1: String? = null,
    var section3053Unit2: String? = null,
    var section3053Unit3: String? = null,
    var section3053Unit4: String? = null,
    var section3053Unit5: String? = null,
    var section3053Unit6: String? = null,
    var section3053Unit7: String? = null,
    var section3053Unit8: String? = null,
    var section3053Unit9: String? = null,
    var section3053Current1: Int? = null,
    var section3053Current2: Int? = null,
    var section3053Current3: Int? = null,
    var section3053Current4: Int? = null,
    var section3053Current5: Int? = null,
    var section3053Current6: Int? = null,
    var section3053Current7: Int? = null,
    var section3053Current8: Int? = null,
    var section3053Current9: Int? = null,
    var section3053Previous1: Int? = null,
    var section3053Previous2: Int? = null,
    var section3053Previous3: Int? = null,
    var section3053Previous4: Int? = null,
    var section3053Previous5: Int? = null,
    var section3053Previous6: Int? = null,
    var section3053Previous7: Int? = null,
    var section3053Previous8: Int? = null,
    var section3053Previous9: Int? = null,
    var section3053Baseline1: Int? = null,
    var section3053Baseline2: Int? = null,
    var section3053Baseline3: Int? = null,
    var section3053Baseline4: Int? = null,
    var section3053Baseline5: Int? = null,
    var section3053Baseline6: Int? = null,
    var section3053Baseline7: Int? = null,
    var section3053Baseline8: Int? = null,
    var section3053Baseline9: Int? = null,
    var section3054Unit1: String? = null,
    var section3054Unit2: String? = null,
    var section3054Unit3: String? = null,
    var section3054Unit4: String? = null,
    var section3054Unit5: String? = null,
    var section3054Unit6: String? = null,
    var section3054Current1: Int? = null,
    var section3054Current2: Int? = null,
    var section3054Current3: Int? = null,
    var section3054Current4: Int? = null,
    var section3054Current5: Int? = null,
    var section3054Current6: Int? = null,
    var section3054Previous1: Int? = null,
    var section3054Previous2: Int? = null,
    var section3054Previous3: Int? = null,
    var section3054Previous4: Int? = null,
    var section3054Previous5: Int? = null,
    var section3054Previous6: Int? = null,
    var section3054Baseline1: Int? = null,
    var section3054Baseline2: Int? = null,
    var section3054Baseline3: Int? = null,
    var section3054Baseline4: Int? = null,
    var section3054Baseline5: Int? = null,
    var section3054Baseline6: Int? = null,
    var section3055Unit1: String? = null,
    var section3055Unit2: String? = null,
    var section3055Unit3: String? = null,
    var section3055Unit4: String? = null,
    var section3055Current1: Int? = null,
    var section3055Current2: Int? = null,
    var section3055Current3: Int? = null,
    var section3055Current4: Int? = null,
    var section3055Previous1: Int? = null,
    var section3055Previous2: Int? = null,
    var section3055Previous3: Int? = null,
    var section3055Previous4: Int? = null,
    var section3055Baseline1: Int? = null,
    var section3055Baseline2: Int? = null,
    var section3055Baseline3: Int? = null,
    var section3055Baseline4: Int? = null,
    var section3056Current1: Int? = null,
    var section3056Current2: Int? = null,
    var section3056Current3: Int? = null,
    var section3056Current4: Int? = null,
    var section3056Previous1: Int? = null,
    var section3056Previous2: Int? = null,
    var section3056Previous3: Int? = null,
    var section3056Previous4: Int? = null,
    var section3056Baseline1: Int? = null,
    var section3056Baseline2: Int? = null,
    var section3056Baseline3: Int? = null,
    var section3056Baseline4: Int? = null,
    var section3057Unit1: String? = null,
    var section3057Unit2: String? = null,
    var section3057Unit3: String? = null,
    var section3057Unit4: String? = null,
    var section3057Unit5: String? = null,
    var section3057Unit6: String? = null,
    var section3057Current1: Int? = null,
    var section3057Current2: Int? = null,
    var section3057Current3: Int? = null,
    var section3057Current4: Int? = null,
    var section3057Current5: Int? = null,
    var section3057Current6: Int? = null,
    var section3057Previous1: Int? = null,
    var section3057Previous2: Int? = null,
    var section3057Previous3: Int? = null,
    var section3057Previous4: Int? = null,
    var section3057Previous5: Int? = null,
    var section3057Previous6: Int? = null,
    var section3057Baseline1: Int? = null,
    var section3057Baseline2: Int? = null,
    var section3057Baseline3: Int? = null,
    var section3057Baseline4: Int? = null,
    var section3057Baseline5: Int? = null,
    var section3057Baseline6: Int? = null,
)
