package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class MarketPresenceState(
    var section2021Field1: String? = null,
    var section2021A1: Double? = null,
    var section2021A2: Double? = null,
    var section2021A3: Double? = null,
    var section2021A4: String? = null,
    var section2021Field2: String? = null,
    var section2021A5: Double? = null,
    var section2021A6: Double? = null,
    var section2021A7: Double? = null,
    var section2021A8: String? = null,
    var section2021Field3: String? = null,
    var section2021Field4: String? = null,

    var section2022A1: Double? = null,
    var section2022A2: String? = null,
    var section2022A3: String? = null,
    var section2022A4: String? = null
)