package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class ForcedOrCompulsoryLaborState(
    var section4091A1: String? = null,
    var section4091B1: String? = null,
    var section4091C1: String? = null,
    var section4091A2: String? = null,
    var section4091B2: String? = null,
    var section4091C2: String? = null,
    var section4091A3: String? = null,
    var section4091B3: String? = null,
    var section4091C3: String? = null,
)
