package com.ecosave.watch.portal.styles.esg

import web.cssom.ClassName

enum class ESGTable(val cssClass: ClassName) {
    TABLE_CELL(ClassName("esg-summary-cell")),
    TITLE_SECTOR(ClassName("esg-summary-title-sector")),
    ADD_BUTTON(ClassName("esg-table-add-button"))
}

enum class ESGCommonStyles(val cssClass: ClassName) {
    MAIN_ESG_FORM(ClassName("main-esg-form")),
    ESG_FIRST_FONT(ClassName("esg-first-font")),
    ESG_SECOND_FONT(ClassName("esg-secondary-font")),
    ESG_FOURTH_FONT(ClassName("esg-fourth-font")),
    ESG_FOURTH_FONT_COLOR(ClassName("esg-fourth-font-color")),
    ESG_INPUT_ITEM(ClassName("esg-input-item")),
    ESG_INPUT_BUTTON(ClassName("esg-input-button")),
    ESG_CHECKBOXES(ClassName("esg-checkboxes")),
    GRI_TITLE(ClassName("gri-title")),
    ESG_CALENDAR_MAIN(ClassName("esg-calendar-main")),
    ESG_CALENDAR_TEXT_FONT(ClassName("esg-calendar-text-font")),
    ESG_CALENDAR_MAIN_FONT(ClassName("esg-calendar-main-font")),
    ESG_SIX_COLUMNS(ClassName("esg-six-columns")),
    ESG_TWO_COLUMNS(ClassName("esg-two-columns")),
    ESG_THREE_COLUMNS(ClassName("esg-three-columns")),
    ESG_FIVE_COLUMNS(ClassName("esg-five-columns")),
    TOC_ICON_SECOND(ClassName("toc-icon-second")),
    ESG_CIRCULAR_PROGRESS(ClassName("esg-circular-progress")),
    ESG_PREVIEW(ClassName("esg-preview")),
    SECTION_MAIN_LAYOUT(ClassName("section-main-layout")),
    LABEL(ClassName("label")),
    TABLE_WRAPPER(ClassName("table-wrapper")),
    STICKY_HEADER(ClassName("sticky-header")),
    GRID_WRAPPER(ClassName("grid-wrapper")),
    TABLE_GRID_WRAPPER(ClassName("table-wrapper grid-wrapper")),
    SUB_ITEM_LEFT_MARGIN(ClassName("sub-item-left-margin")),
    TWO_COLUMN_TABLE(ClassName("two-column-table grid-wrapper ")),
    THREE_COLUMN_TABLE(ClassName("three-column-table grid-wrapper")),
    DYNAMIC_TABLE_SECTION_LAYOUT(ClassName("dynamic-table-section-layout")),
}

enum class EconomicPerformanceStyles(val cssClass: ClassName) {
    SECTION_201_1_TABLE(ClassName("section_201_1_table grid-wrapper")),
    SECTION_201_4_TABLE(ClassName("section_201_4_table grid-wrapper")),
    SECTION_201_4_TABLE_2(ClassName("section_201_4_table_2 grid-wrapper")),
}

enum class IndirectEconomicImpacts(val cssClass: ClassName) {
    SECTION_203_1_TABLE(ClassName("section_203_1_table table")),
    SECTION_203_1_TABLE_INNER(ClassName("section_203_1_tableInner table")),
    SECTION_203_2_TABLE(ClassName("section_203_2_table table")),
    SECTION_203_2_TABLE_INNER(ClassName("section_203_2_tableInner table")),
}

enum class TaxStyles(val cssClass: ClassName) {
    SECTION_207_TABLE(ClassName("section_207_table table")),
}

enum class MarketPresence(val cssClass: ClassName) {
    SECTION_202_2_TABLE(ClassName("section_202_2_table table")),
}

enum class GeneralDisclosuresStyles(val cssClass: ClassName) {
    SECTION_2_1_LOCATION(ClassName("table")),
    SECTION_2_7_TABLE(ClassName("section_2_7_table table")),
    SECTION_2_9_TABLE(ClassName("table-wrapper table")),
    SECTION_2_12_TABLE(ClassName("section_2_12_table table-wrapper table")),
    SECTION_2_15_TABLE(ClassName("section_2_15_table table-wrapper table")),
}

enum class ESGFormStyles(val cssClass: ClassName) {
    SECTION_415_1_TABLE(ClassName("section_415_1_table grid-wrapper")),
}


