package com.ecosave.watch.portal.components.esg.economicperformance

import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EconomicPerformanceField
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CountriesAndValues
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EconomicPerformanceState
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.EsgCollectionDeleteRow
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.FinancialOpportunities
import com.ecosave.watch.portal.models.esg.FinancialRisks
import com.ecosave.watch.portal.models.esg.RetirementPlans
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles

external interface EconomicPerformanceComponentProps : Props {
    var reportState: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
    var setNotificationMessage: StateSetter<String>
    var setNotificationStatus: StateSetter<NotificationStatus>
    var setOpenAlertNotifications: StateSetter<Boolean>
    var setAddDeleteRowOrSectionApiCallInProgress: StateSetter<Boolean>
    var setAddDeleteRowOrSection: StateSetter<DynamicTableAction>
    var onBlurApiCall: (String, JsonPrimitive, Int?, String?, String) -> Unit
}

val EconomicPerformanceComponent = FC<EconomicPerformanceComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.economicPerformance?.let { state ->

        fun stateSetter(state: EconomicPerformanceState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        economicPerformance = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = EsgSection.ECONOMIC_PERFORMANCE.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        EconomicPerformanceField.SECTION_201_2_RISK.fieldName -> {
                            state.financialRisksList.add(FinancialRisks())
                            props.setNotificationMessage("A new Risk section has been added successfully.")
                        }

                        EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName -> {
                            state.financialOpportunitiesList.add(FinancialOpportunities())
                            props.setNotificationMessage("A new Opportunity section has been added successfully.")
                        }

                        EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName -> {
                            state.retirementPlansList.add(RetirementPlans())
                            props.setNotificationMessage("A new Retirement Plan section has been added successfully.")
                        }

                        EconomicPerformanceField.SECTION_201_4_COUNTRIES_AND_VALUES.fieldName -> {
                            state.countriesAndValuesList.add(CountriesAndValues())
                            props.setNotificationMessage("A new row has been added successfully.")
                        }
                    }
                    stateSetter(state)
                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(props.setNotificationMessage, props.setNotificationStatus, props.setOpenAlertNotifications)
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = EsgSection.ECONOMIC_PERFORMANCE,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        EconomicPerformanceField.SECTION_201_2_RISK.fieldName -> {
                            state.financialRisksList.removeAt(index)
                            props.setNotificationMessage("Section has been deleted successfully.")
                        }

                        EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName -> {
                            state.financialOpportunitiesList.removeAt(index)
                            props.setNotificationMessage("Section has been deleted successfully.")
                        }

                        EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName -> {
                            state.retirementPlansList.removeAt(index)
                        }

                        EconomicPerformanceField.SECTION_201_4_COUNTRIES_AND_VALUES.fieldName -> {
                            state.countriesAndValuesList.removeAt(index)
                            props.setNotificationMessage("Row has been deleted successfully.")
                        }
                    }
                    stateSetter(state)
                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(props.setNotificationMessage, props.setNotificationStatus, props.setOpenAlertNotifications)
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = EconomicPerformanceField.valueOf(target.name)
                val propertyValue = target.value.toDoubleOrNull()

                state.section2013A1 = if (propertyName == EconomicPerformanceField.SECTION_201_3_A1) propertyValue else state.section2013A1

                state.section2014A1 = if (propertyName == EconomicPerformanceField.SECTION_201_4_A1) propertyValue else state.section2014A1
                state.section2014A2 = if (propertyName == EconomicPerformanceField.SECTION_201_4_A2) propertyValue else state.section2014A2
                state.section2014A3 = if (propertyName == EconomicPerformanceField.SECTION_201_4_A3) propertyValue else state.section2014A3
                state.section2014A4 = if (propertyName == EconomicPerformanceField.SECTION_201_4_A4) propertyValue else state.section2014A4
                state.section2014A5 = if (propertyName == EconomicPerformanceField.SECTION_201_4_A5) propertyValue else state.section2014A5
                state.section2014A6 = if (propertyName == EconomicPerformanceField.SECTION_201_4_A6) propertyValue else state.section2014A6
                state.section2014A7 = if (propertyName == EconomicPerformanceField.SECTION_201_4_A7) propertyValue else state.section2014A7
                state.section2014A8 = if (propertyName == EconomicPerformanceField.SECTION_201_4_A8) propertyValue else state.section2014A8

                if (field == EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName && changeIndex != null) {
                    state.retirementPlansList[changeIndex].section2013A7 =
                        if (propertyName == EconomicPerformanceField.SECTION_201_3_A7) propertyValue else state.retirementPlansList[changeIndex].section2013A7
                    state.retirementPlansList[changeIndex].section2013A8 =
                        if (propertyName == EconomicPerformanceField.SECTION_201_3_A8) propertyValue else state.retirementPlansList[changeIndex].section2013A8
                }


                if (field == EconomicPerformanceField.SECTION_201_4_COUNTRIES_AND_VALUES.fieldName && changeIndex != null) {
                    state.countriesAndValuesList[changeIndex].section2014Part2A2 =
                        if (propertyName == EconomicPerformanceField.SECTION_201_4_PART_2_A2) propertyValue else state.countriesAndValuesList[changeIndex].section2014Part2A2
                    state.countriesAndValuesList[changeIndex].section2014Part2A3 =
                        if (propertyName == EconomicPerformanceField.SECTION_201_4_PART_2_A3) propertyValue else state.countriesAndValuesList[changeIndex].section2014Part2A3
                    state.countriesAndValuesList[changeIndex].section2014Part2A4 =
                        if (propertyName == EconomicPerformanceField.SECTION_201_4_PART_2_A4) propertyValue else state.countriesAndValuesList[changeIndex].section2014Part2A4
                    state.countriesAndValuesList[changeIndex].section2014Part2A5 =
                        if (propertyName == EconomicPerformanceField.SECTION_201_4_PART_2_A5) propertyValue else state.countriesAndValuesList[changeIndex].section2014Part2A5
                    state.countriesAndValuesList[changeIndex].section2014Part2A6 =
                        if (propertyName == EconomicPerformanceField.SECTION_201_4_PART_2_A6) propertyValue else state.countriesAndValuesList[changeIndex].section2014Part2A6
                    state.countriesAndValuesList[changeIndex].section2014Part2A7 =
                        if (propertyName == EconomicPerformanceField.SECTION_201_4_PART_2_A7) propertyValue else state.countriesAndValuesList[changeIndex].section2014Part2A7
                    state.countriesAndValuesList[changeIndex].section2014Part2A8 =
                        if (propertyName == EconomicPerformanceField.SECTION_201_4_PART_2_A8) propertyValue else state.countriesAndValuesList[changeIndex].section2014Part2A8
                    state.countriesAndValuesList[changeIndex].section2014Part2A9 =
                        if (propertyName == EconomicPerformanceField.SECTION_201_4_PART_2_A9) propertyValue else state.countriesAndValuesList[changeIndex].section2014Part2A9
                }

                stateSetter(state)
                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName.fieldName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.ECONOMIC_PERFORMANCE.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = EconomicPerformanceField.valueOf(target.name)
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(propertyName.fieldName, JsonPrimitive(propertyValue), changeIndex, field, EsgSection.ECONOMIC_PERFORMANCE.name)
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = EconomicPerformanceField.valueOf(target.name)
            val propertyValue = target.value

            state.section2011A1 = if (propertyName == EconomicPerformanceField.SECTION_201_1_A1) propertyValue else state.section2011A1
            state.section2011A2 = if (propertyName == EconomicPerformanceField.SECTION_201_1_A2) propertyValue else state.section2011A2
            state.section2011A3 = if (propertyName == EconomicPerformanceField.SECTION_201_1_A3) propertyValue else state.section2011A3
            state.section2011A4 = if (propertyName == EconomicPerformanceField.SECTION_201_1_A4) propertyValue else state.section2011A4
            state.section2011A5 = if (propertyName == EconomicPerformanceField.SECTION_201_1_A5) propertyValue else state.section2011A5
            state.section2011A6 = if (propertyName == EconomicPerformanceField.SECTION_201_1_A6) propertyValue else state.section2011A6
            state.section2011A7 = if (propertyName == EconomicPerformanceField.SECTION_201_1_A7) propertyValue else state.section2011A7
            state.section2011Field1 = if (propertyName == EconomicPerformanceField.SECTION_201_1_FIELD1) propertyValue else state.section2011Field1
            state.section2011Field2 = if (propertyName == EconomicPerformanceField.SECTION_201_1_FIELD2) propertyValue else state.section2011Field2

            if (field == EconomicPerformanceField.SECTION_201_2_RISK.fieldName && changeIndex != null) {
                state.financialRisksList[changeIndex].section2012RiskA1 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_2_RISK_A1) propertyValue else state.financialRisksList[changeIndex].section2012RiskA1
                state.financialRisksList[changeIndex].section2012RiskA2 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_2_RISK_A2) propertyValue else state.financialRisksList[changeIndex].section2012RiskA2
                state.financialRisksList[changeIndex].section2012RiskA3 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_2_RISK_A3) propertyValue else state.financialRisksList[changeIndex].section2012RiskA3
                state.financialRisksList[changeIndex].section2012RiskA4 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_2_RISK_A4) propertyValue else state.financialRisksList[changeIndex].section2012RiskA4
                state.financialRisksList[changeIndex].section2012RiskA5 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_2_RISK_A5) propertyValue else state.financialRisksList[changeIndex].section2012RiskA5
            }

            if (field == EconomicPerformanceField.SECTION_201_2_OPPORTUNITY.fieldName && changeIndex != null) {
                state.financialOpportunitiesList[changeIndex].section2012OpportunityA1 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_2_OPPORTUNITY_A1) propertyValue else state.financialOpportunitiesList[changeIndex].section2012OpportunityA1
                state.financialOpportunitiesList[changeIndex].section2012OpportunityA2 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_2_OPPORTUNITY_A2) propertyValue else state.financialOpportunitiesList[changeIndex].section2012OpportunityA2
                state.financialOpportunitiesList[changeIndex].section2012OpportunityA3 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_2_OPPORTUNITY_A3) propertyValue else state.financialOpportunitiesList[changeIndex].section2012OpportunityA3
                state.financialOpportunitiesList[changeIndex].section2012OpportunityA4 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_2_OPPORTUNITY_A4) propertyValue else state.financialOpportunitiesList[changeIndex].section2012OpportunityA4
                state.financialOpportunitiesList[changeIndex].section2012OpportunityA5 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_2_OPPORTUNITY_A5) propertyValue else state.financialOpportunitiesList[changeIndex].section2012OpportunityA5
            }

            state.section2012Field1 = if (propertyName == EconomicPerformanceField.SECTION_201_2_FIELD1) propertyValue else state.section2012Field1

            state.section2013A2 = if (propertyName == EconomicPerformanceField.SECTION_201_3_A2) propertyValue else state.section2013A2
            state.section2013A3 = if (propertyName == EconomicPerformanceField.SECTION_201_3_A3) propertyValue else state.section2013A3
            state.section2013A4 = if (propertyName == EconomicPerformanceField.SECTION_201_3_A4) propertyValue else state.section2013A4
            state.section2013A5 = if (propertyName == EconomicPerformanceField.SECTION_201_3_A5) propertyValue else state.section2013A5

            state.section2014Part2Field1 =
                if (propertyName == EconomicPerformanceField.SECTION_201_4_PART2_FIELD1) propertyValue else state.section2014Part2Field1

            stateSetter(state)
            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName.fieldName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.ECONOMIC_PERFORMANCE.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = EconomicPerformanceField.valueOf(target.name)
            val propertyValue = target.value
            props.onBlurApiCall(propertyName.fieldName, JsonPrimitive(propertyValue), changeIndex, field, EsgSection.ECONOMIC_PERFORMANCE.name)
        }

        val onChangeTextField: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = EconomicPerformanceField.valueOf(target.name)
            val propertyValue = target.value

            if (field == EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName && changeIndex != null) {
                state.retirementPlansList[changeIndex].section2013A6 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_3_A6) propertyValue else state.retirementPlansList[changeIndex].section2013A6
                state.retirementPlansList[changeIndex].section2013A9 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_3_A9) propertyValue else state.retirementPlansList[changeIndex].section2013A9
                state.retirementPlansList[changeIndex].section2013A10 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_3_A10) propertyValue else state.retirementPlansList[changeIndex].section2013A10
                state.retirementPlansList[changeIndex].section2013A11 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_3_A11) propertyValue else state.retirementPlansList[changeIndex].section2013A11
            }

            if (field == EconomicPerformanceField.SECTION_201_4_COUNTRIES_AND_VALUES.fieldName && changeIndex != null) {
                state.countriesAndValuesList[changeIndex].section2014Part2A1 =
                    if (propertyName == EconomicPerformanceField.SECTION_201_4_PART_2_A1) propertyValue else state.countriesAndValuesList[changeIndex].section2014Part2A1
            }

            stateSetter(state)
            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName.fieldName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.ECONOMIC_PERFORMANCE.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextField: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = EconomicPerformanceField.valueOf(target.name)
            val propertyValue = target.value
            props.onBlurApiCall(propertyName.fieldName, JsonPrimitive(propertyValue), changeIndex, field, EsgSection.ECONOMIC_PERFORMANCE.name)
        }

        Box {
            className = styles.SECTION_MAIN_LAYOUT.cssClass

            Section201_1 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onBlurTextArea = onBlurTextArea
            }

            Section201_2 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onBlurTextArea = onBlurTextArea
                this.addRowOrSection = addRowOrSection
                this.deleteRowOrSection = deleteRowOrSection
            }

            Section201_3 {
                this.state = state
                this.onChangeNumber = onChangeNumber
                this.onBlurNumber = onBlurNumber
                this.onChangeTextArea = onChangeTextArea
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextField = onChangeTextField
                this.onBlurTextField = onBlurTextField
                this.addRowOrSection = addRowOrSection
                this.deleteRowOrSection = deleteRowOrSection
            }

            Section201_4 {
                this.state = state
                this.onChangeNumber = onChangeNumber
                this.onBlurNumber = onBlurNumber
                this.onChangeTextField = onChangeTextField
                this.onBlurTextField = onBlurTextField
                this.onChangeTextArea = onChangeTextArea
                this.onBlurTextArea = onBlurTextArea
                this.addRowOrSection = addRowOrSection
                this.deleteRowOrSection = deleteRowOrSection
            }
        }
    }
}
