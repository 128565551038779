package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class DiversityAndEqualOpportunityState(
    var section4051Current1: Double? = null,
    var section4051Current2: Double? = null,
    var section4051Current3: Double? = null,
    var section4051Current4: Double? = null,
    var section4051Current5: Double? = null,
    var section4051Current6: Double? = null,
    var section4051Current7: Double? = null,
    var section4051Current8: Double? = null,
    var section4051Current9: Double? = null,
    var section4051Current10: Double? = null,
    var section4051Current11: Double? = null,
    var section4051Current12: Double? = null,
    var section4051Previous1: Double? = null,
    var section4051Previous2: Double? = null,
    var section4051Previous3: Double? = null,
    var section4051Previous4: Double? = null,
    var section4051Previous5: Double? = null,
    var section4051Previous6: Double? = null,
    var section4051Previous7: Double? = null,
    var section4051Previous8: Double? = null,
    var section4051Previous9: Double? = null,
    var section4051Previous10: Double? = null,
    var section4051Previous11: Double? = null,
    var section4051Previous12: Double? = null,
    var section4052RatioOfBasic1: String? = null,
    var section4052RatioOfBasic2: String? = null,
    var section4052RatioOfBasic3: String? = null,
    var section4052Current1: String? = null,
    var section4052Current2: String? = null,
    var section4052Current3: String? = null,
    var section4052Previous1: String? = null,
    var section4052Previous2: String? = null,
    var section4052Previous3: String? = null,
    var section4052RatioOfRemuneration1: String? = null,
    var section4052RatioOfRemuneration2: String? = null,
    var section4052RatioOfRemuneration3: String? = null,
    var section4052Part2Current1: String? = null,
    var section4052Part2Current2: String? = null,
    var section4052Part2Current3: String? = null,
    var section4052Part2Previous1: String? = null,
    var section4052Part2Previous2: String? = null,
    var section4052Part2Previous3: String? = null,
)
