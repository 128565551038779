package com.ecosave.watch.portal.components.esg.marketpresence

import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGRadioButtonWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.common.YesNoEnum
import com.ecosave.watch.portal.helpers.esg.MarketPresenceField
import com.ecosave.watch.portal.models.esg.MarketPresenceState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.MarketPresence
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLInputElement

external interface Section2021Props : Props {
    var onChangeRadio: (ChangeEvent<HTMLInputElement>) -> Unit
    var onChangeText: (FormEvent<HTMLDivElement>) -> Unit
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var onChangeNumber: (FormEvent<HTMLDivElement>) -> Unit
    var state: MarketPresenceState
}

val Section202_1 = FC<Section2021Props> { props ->

    val onChangeRadio = props.onChangeRadio
    val onChangeText = props.onChangeText
    val onChangeTextArea = props.onChangeTextArea
    val onChangeNumber = props.onChangeNumber
    val state = props.state

    SectionMainTitle {
        title = "202-1 RATIOS OF STANDARD ENTRY LEVEL WAGE BY GENDER COMPARED TO LOCAL MINIMUM WAGE"
    }

    Box {
        Label {
            label = "Are a significant proportion of employees compensated based on wages subject to minimum wage rules?"
        }
        ESGRadioButtonWrapper {
            name = MarketPresenceField.SECTION_202_1_FIELD1.name
            value = state.section2021Field1
            onChange = { event, _ -> onChangeRadio(event) }
        }
    }
    if (state.section2021Field1 == YesNoEnum.YES.description) {
        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            Box {
                className = MarketPresence.SECTION_202_2_TABLE.cssClass
                Label {
                    label = "Total entry level wage employees"
                }
                ESGNumberFieldWrapper {
                    name = MarketPresenceField.SECTION_202_1_A1.name
                    value = state.section2021A1
                    onChange = { event -> onChangeNumber(event) }
                }
                Label {
                    label = "Male entry level wage employees"
                }
                ESGNumberFieldWrapper {
                    name = MarketPresenceField.SECTION_202_1_A2.name
                    value = state.section2021A2
                    onChange = { event -> onChangeNumber(event) }
                }
                Label {
                    label = "Female entry level wage employees"
                }
                ESGNumberFieldWrapper {
                    name = MarketPresenceField.SECTION_202_1_A3.name
                    value = state.section2021A3
                    onChange = { event -> onChangeNumber(event) }
                }
                Label {
                    label = "Relevant ratio of entry level wage employees"
                }
                ESGTextFieldWrapper {
                    name = MarketPresenceField.SECTION_202_1_A4.name
                    value = state.section2021A4
                    onChange = { event -> onChangeText(event) }
                }
            }
        }
    }
    Box {
        Label {
            label = "Are a significant proportion of other workers compensated based on wages subject to minimum wage rules?"
        }
        ESGRadioButtonWrapper {
            name = MarketPresenceField.SECTION_202_1_FIELD2.name
            value = state.section2021Field2
            onChange = { event, _ -> onChangeRadio(event) }
        }
    }
    if (state.section2021Field2 == YesNoEnum.YES.description) {
        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            Box {
                className = MarketPresence.SECTION_202_2_TABLE.cssClass
                Label {
                    label = "Total entry level wage workers"
                }
                ESGNumberFieldWrapper {
                    name = MarketPresenceField.SECTION_202_1_A5.name
                    value = state.section2021A5
                    onChange = { event -> onChangeNumber(event) }
                }
                Label {
                    label = "Male entry level wage workers"
                }
                ESGNumberFieldWrapper {
                    name = MarketPresenceField.SECTION_202_1_A6.name
                    value = state.section2021A6
                    onChange = { event -> onChangeNumber(event) }
                }
                Label {
                    label = "Female entry level wage workers"
                }
                ESGNumberFieldWrapper {
                    name = MarketPresenceField.SECTION_202_1_A7.name
                    value = state.section2021A7
                    onChange = { event -> onChangeNumber(event) }
                }
                Label {
                    label = "Relevant ratio of entry level wage workers"
                }
                ESGTextFieldWrapper {
                    name = MarketPresenceField.SECTION_202_1_A8.name
                    value = state.section2021A8
                    onChange = { event -> onChangeText(event) }
                }
            }
        }
    }
    Box {
        Label {
            label = "Definition used for “significant locations of operation”"
        }
        ESGTextAreaFieldWrapper {
            name = MarketPresenceField.SECTION_202_1_FIELD3.name
            value = state.section2021Field3
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "If local minimum wages change significantly between locations of operation, note the differences:"
        }
        ESGTextAreaFieldWrapper {
            name = MarketPresenceField.SECTION_202_1_FIELD4.name
            value = state.section2021Field4
            onChange = { event -> onChangeTextArea(event) }
        }
    }
}