package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.esg.EsgSubSection
import com.ecosave.watch.portal.helpers.esg.TheOrgAndItsRepPracticesField
import com.ecosave.watch.portal.helpers.esg.autoSaveEsgField
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.esg.savingErrorMessage
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.TheOrgAndItsRepPracticesState
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles


external interface TheOrgAndItsRepPracticesComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
    var setNotificationMessage: StateSetter<String>
    var setNotificationStatus: StateSetter<NotificationStatus>
    var setOpenAlertNotifications: StateSetter<Boolean>
}


val TheOrgAndItsRepPracticesComponent = FC<TheOrgAndItsRepPracticesComponentProps> { props ->

    val esgState = props.esgReportStateProps
    val esgStateSetter = props.esgReportStateSetterProps

    esgState.report?.theOrgAndItsRepPractices?.let { state ->

        fun stateSetter(state: TheOrgAndItsRepPracticesState) {
            esgStateSetter(
                esgState.copy(
                    report = esgState.report.copy(
                        theOrgAndItsRepPractices = state
                    )
                )
            )
        }

        val onChangeText: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            val target = event.target as HTMLInputElement
            val propertyName = TheOrgAndItsRepPracticesField.valueOf(target.name)
            val propertyValue = target.value
            stateSetter(
                state.copy(
                    NATURE_OF_OWNERSHIP_LEGAL_FORM = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD1) propertyValue else state.NATURE_OF_OWNERSHIP_LEGAL_FORM,
                    section21Field2 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD2) propertyValue else state.section21Field2,
                    section21Field3 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD3) propertyValue else state.section21Field3,
                    section21Field4 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD4) propertyValue else state.section21Field4,
                    section21Field5 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD5) propertyValue else state.section21Field5,
                    section21Field6 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD6) propertyValue else state.section21Field6,
                    section21Field7 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD7) propertyValue else state.section21Field7,
                    section21Field8 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD8) propertyValue else state.section21Field8
                )
            )
            props.setInputState(
                CurrentInputState(
                    childPath = propertyName.fieldName,
                    value = JsonPrimitive(propertyValue.trim()),
                    objectName = EsgSubSection.THE_ORG_AND_ITS_REP_PRACTICES.name,
                    reportName = esgState.reportFileName
                )
            )
        }

        val onChangeRadio: (ChangeEvent<HTMLInputElement>) -> Unit = { event ->
            resetPreviousPatchState(props.setInputState)
            var status: ApiCallStatus?
            val propertyName = TheOrgAndItsRepPracticesField.valueOf(event.target.name)
            val propertyValue = event.target.value
            stateSetter(
                state.copy(
                    section22Field2 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_2_FIELD2) propertyValue else state.section22Field2,
                    section22Field3 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_2_FIELD3) propertyValue else state.section22Field3,
                    section22Field4 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_2_FIELD4) propertyValue else state.section22Field4,
                    section22Field6 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_2_FIELD6) propertyValue else state.section22Field6,

                    section25Field3 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_5_FIELD3) propertyValue else state.section25Field3
                )
            )
            mainScope.launch {
                status = autoSaveEsgField(
                    propertyName.fieldName,
                    JsonPrimitive(propertyValue),
                    EsgSubSection.THE_ORG_AND_ITS_REP_PRACTICES.name,
                    esgState.reportFileName
                )
                savingErrorMessage(props.setNotificationMessage, props.setNotificationStatus, props.setOpenAlertNotifications, status)
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = TheOrgAndItsRepPracticesField.valueOf(target.name)
            val propertyValue = target.value
            stateSetter(
                state.copy(
                    section22Field1 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_2_FIELD1) propertyValue else state.section22Field1,
                    section22Field5 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_2_FIELD5) propertyValue else state.section22Field5,

                    section23Field1 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_3_FIELD1) propertyValue else state.section23Field1,
                    section23Field2 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_3_FIELD2) propertyValue else state.section23Field2,
                    section23Field3 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_3_FIELD3) propertyValue else state.section23Field3,
                    section23Field4 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_3_FIELD4) propertyValue else state.section23Field4,
                    section23Field5 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_3_FIELD5) propertyValue else state.section23Field5,

                    section25Field1 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_5_FIELD1) propertyValue else state.section25Field1,
                    section25Field2 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_5_FIELD2) propertyValue else state.section25Field2,
                    section25Field4 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_5_FIELD4) propertyValue else state.section25Field4,
                    section25Field5 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_5_FIELD5) propertyValue else state.section25Field5,
                    section25Field6 = if (propertyName == TheOrgAndItsRepPracticesField.SECTION_2_5_FIELD6) propertyValue else state.section25Field6
                )
            )
            props.setInputState(
                CurrentInputState(
                    childPath = propertyName.fieldName,
                    value = JsonPrimitive(propertyValue.trim()),
                    objectName = EsgSubSection.THE_ORG_AND_ITS_REP_PRACTICES.name,
                    reportName = esgState.reportFileName
                )
            )
        }

        Box {
            className = styles.SECTION_MAIN_LAYOUT.cssClass

            Section2_1 {
                this.state = state
                this.onChangeText = onChangeText
            }
            Section2_2 {
                this.state = state
                this.onChangeRadio = onChangeRadio
                this.onChangeTextArea = onChangeTextArea
            }
            Section2_3 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
            }
            Section2_4 {
                this.state = state
            }
            Section2_5 {
                this.state = state
                this.onChangeRadio = onChangeRadio
                this.onChangeTextArea = onChangeTextArea
            }
        }
    }
}
