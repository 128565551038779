package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class TaxState(
    var section2071A1: String? = null,
    var section2071A2: String? = null,
    var section2071A3: String? = null,
    var section2071A4: String? = null,
    var section2071A5: String? = null,
    var section2071A6: String? = null,

    var section2072A1: String? = null,
    var section2072A2: String? = null,
    var section2072A3: String? = null,
    var section2072A4: String? = null,
    var section2072A5: String? = null,
    var section2072A6: String? = null,

    var section2073A1: String? = null,
    var section2073A2: String? = null,
    var section2073A3: String? = null,

    var section2074A1: String? = null,
    var section2074Part2A1: String? = null,
    var section2074Part2A2: Int? = null,
    var section2074Part2A3: Double? = null,
    var section2074Part2A4: Double? = null,
    var section2074Part2A5: Double? = null,
    var section2074Part2A6: Double? = null,
    var section2074Part2A7: Double? = null,
    var section2074Part2A8: Double? = null,
    var section2074Part2A9: String? = null,
)
