package com.ecosave.watch.portal.components.esg.publicpolicy

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.PublicPolicyField
import com.ecosave.watch.portal.models.esg.PublicPolicyState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.ESGFormStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLElement

external interface Section4151Props : Props {
    var state: PublicPolicyState
    var onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var onChangeTextField: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurTextField: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var addRowOrSection: (String) -> Unit
    var deleteRowOrSection: (String, Int) -> Unit
}

val Section415_1 = FC<Section4151Props> { props ->

    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val onChangeTextField = props.onChangeTextField
    val onBlurTextField = props.onBlurTextField
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val state = props.state

    SectionMainTitle {
        title = "415-1 POLITICAL CONTRIBUTIONS"
    }

    SectionSubHeading {
        subHeading =
            "What is the total monetary value of financial and in-kind political contributions made both directly and indirectly to recipients/beneficiaries?"
    }

    Box {
        className = ESGCommonStyles.TABLE_WRAPPER.cssClass
        DynamicTableWrapper {
            addRowOrSection = {
                props.addRowOrSection(PublicPolicyField.PUBLIC_POLICY_LIST.fieldName)
            }
            items = state.sectionPublicPolicyList
            action = DynamicTableAction.ADD
            addRowOrSectionBtnText = "Add a new row"
            Box {
                className = ESGFormStyles.SECTION_415_1_TABLE.cssClass
                TableHeader {
                    header = "Country"
                }
                TableHeader {
                    header = "Recipient"
                }
                TableHeader {
                    header = "Total Monetary Value"
                }
                TableHeader {
                    header = "If applicable, how the monetary value of in-kind contributions was estimated."
                }
            }
        }
        state.sectionPublicPolicyList.forEachIndexed { index, it ->
            DynamicTableWrapper {
                key = it.key
                deleteRowOrSection = {
                    props.deleteRowOrSection(PublicPolicyField.PUBLIC_POLICY_LIST.fieldName, index)
                }
                items = state.sectionPublicPolicyList
                action = DynamicTableAction.DELETE
                variant = DynamicTableVariant.TABLE
                Box {
                    className = ESGFormStyles.SECTION_415_1_TABLE.cssClass
                    ESGTextFieldWrapper {
                        name = PublicPolicyField.SECTION_415_1_A1.name
                        value = it.section4151A1
                        onChange = { event -> onChangeTextField(event, index, PublicPolicyField.PUBLIC_POLICY_LIST.fieldName) }
                        onBlur = { event -> onBlurTextField(event, index, PublicPolicyField.PUBLIC_POLICY_LIST.fieldName) }
                    }
                    ESGTextFieldWrapper {
                        name = PublicPolicyField.SECTION_415_1_A2.name
                        value = it.section4151A2
                        onChange = { event -> onChangeTextField(event, index, PublicPolicyField.PUBLIC_POLICY_LIST.fieldName) }
                        onBlur = { event -> onBlurTextField(event, index, PublicPolicyField.PUBLIC_POLICY_LIST.fieldName) }
                    }
                    ESGNumberFieldWrapper {
                        name = PublicPolicyField.SECTION_415_1_A3.name
                        value = it.section4151A3
                        onChange = { event -> onChangeNumber(event, index, PublicPolicyField.PUBLIC_POLICY_LIST.fieldName) }
                        onBlur = { event -> onBlurNumber(event, index, PublicPolicyField.PUBLIC_POLICY_LIST.fieldName) }
                    }
                    ESGTextAreaFieldWrapper {
                        name = PublicPolicyField.SECTION_415_1_A4.name
                        value = it.section4151A4
                        onChange = { event -> onChangeTextArea(event, index, PublicPolicyField.PUBLIC_POLICY_LIST.fieldName) }
                        onBlur = { event -> onBlurTextArea(event, index, PublicPolicyField.PUBLIC_POLICY_LIST.fieldName) }
                        minimumRows = 1
                        maximumRows = 4
                    }
                }
            }
        }
    }
}