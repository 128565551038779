package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.styles.SummaryStyles
import com.ecosave.watch.portal.components.common.Loading
import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.helpers.common.OperationType
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.common.handleNotificationStatus
import com.ecosave.watch.portal.helpers.esg.ReportSummaryEnums
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.ReportFinancialYear
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.esg.deleteReport
import com.ecosave.watch.portal.services.esg.getAllReports
import com.ecosave.watch.portal.styles.BillingTableStyles
import com.ecosave.watch.portal.styles.esg.ESGTable
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.useEffectOnce
import react.useState
import web.cssom.FontWeight
import web.cssom.pct
import web.cssom.px

external interface EsgReportSummaryComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var esgReportIsClickedStateProps: Boolean
    var esgReportIsClickedStateSetterProps: StateSetter<Boolean>
    var esgReportPageNumber: Int
    var esgReportPageNumberSetter: StateSetter<Int>

}

val EsgReportSummaryComponent = FC<EsgReportSummaryComponentProps> { props ->
    val (esgReportListState, esgReportListStateSetter) = useState(
        listOf(EsgReportState(reportFinancialYear = ReportFinancialYear()))
    )
    var isLoading by useState(true)
    val esgReportIsClickedStateSetter = props.esgReportIsClickedStateSetterProps
    val (notificationState, notificationStateSetter) = useState(NotificationState())
    val (deleteOpenState, deleteOpenStateSetter) = useState(false)
    val (deleteTitleState, deleteTitleStateSetter) = useState("")
    val (deleteBodyState, deleteBodyStateSetter) = useState("")
    val (deleteFileNameState, deleteFileStateSetter) = useState("")
    val globalState = useGlobalState()

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.ESG_REPORTING.title)
        mainScope.launch {
            isLoading = true
            val reports = getAllReports()
            if (reports == null) {
                notificationStateSetter(
                    notificationState.copy(
                        status = NotificationStatus.ERROR,
                        message = "There is an error fetching ESG Reports, please retry.",
                        visible = true
                    )
                )
            } else {
                esgReportListStateSetter(reports)
            }
            isLoading = false
        }
    }
    Box {
        Box {
            className = ESGTable.TITLE_SECTOR.cssClass
            Box {
                className = ESGTable.TITLE_SECTOR.cssClass
                sx {
                    width = 40.pct
                }
                Box {
                    sx {
                        width = 50.pct
                        marginRight = 20.px
                    }
                    Button {
                        variant = ButtonVariant.contained
                        className = ESGTable.ADD_BUTTON.cssClass
                        startIcon = startIcon.also {
                            Add {
                                sx {
                                    marginRight = 5.px
                                }
                            }
                        }
                        onClick = {
                            esgReportIsClickedStateSetter(true)
                        }
                        +"Create a Report"
                    }
                }
            }
        }
        Box {
            TableContainer {
                className = CommonStyles.TABLE_HEIGHT.cssClass
                Table {
                    stickyHeader = true
                    TableHead {
                        TableRow {
                            for (reportData in ReportSummaryEnums.entries) {
                                TableCell {
                                    sx {
                                        fontWeight = FontWeight.bold
                                    }
                                    align = TableCellAlign.left
                                    +reportData.description
                                }
                            }
                        }
                    }
                    TableBody {
                        if (isLoading) {
                            TableRow {
                                TableCell {
                                    className = BillingTableStyles.TABLE_CELL.cssClass
                                    colSpan = 8
                                    align = TableCellAlign.center
                                    Box {
                                        sx {
                                            height = 400.px
                                        }
                                        Loading()
                                    }
                                }
                            }
                        } else {
                            className = SummaryStyles.SUMMARY_EFFECTS.cssClass
                            for (esgReport in esgReportListState) {
                                EsgReportSummaryTableRowComponent {
                                    esgReportPageNumber = props.esgReportPageNumber
                                    esgReportPageNumberSetter = props.esgReportPageNumberSetter
                                    esgReportIsClicked = esgReportIsClickedStateSetter
                                    esgReportProps = esgReport
                                    esgReportStateSetterProps = props.esgReportStateSetterProps
                                    esgReportListProps = esgReportListState
                                    this.esgReportListStateSetterProps = esgReportListStateSetter
                                    deleteConfirmationOpen = deleteOpenStateSetter
                                    deleteConfirmationTitle = deleteTitleStateSetter
                                    deleteConfirmationBody = deleteBodyStateSetter
                                    deleteConfirmationFile = deleteFileStateSetter
                                }
                            }
                            ConfirmationDialog {
                                open = deleteOpenState
                                title = deleteTitleState
                                body = deleteBodyState
                                actionResult = {
                                    if (it) {
                                        mainScope.launch {
                                            val status = deleteReport(deleteFileNameState)
                                            esgReportListStateSetter { list ->
                                                list.filter { it.reportFileName != deleteFileNameState }
                                            }
                                            handleNotificationStatus(
                                                status,
                                                notificationState,
                                                notificationStateSetter,
                                                OperationType.DELETE
                                            )
                                        }
                                        deleteOpenStateSetter(false)
                                    } else {
                                        deleteOpenStateSetter(false)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        AlertNotifications {
            open = notificationState.visible
            status = notificationState.status
            message = notificationState.message
            closeNotification = {
                notificationStateSetter(
                    notificationState.copy(
                        visible = false
                    )
                )
            }
        }
    }
}