package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class RightsOfIndigenousPeoplesState(
    var section4111Current1: Int? = null,
    var section4111Previous1: Int? = null,
    var section4111A1: String? = null,
    var section4111B1: String? = null,
    var section4111C1: String? = null,
    var section4111D1: String? = null,
)
