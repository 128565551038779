package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class StakeholderEngagementState(
    var section229A1: String? = null,
    var section229B1: String? = null,
    var section229C1: String? = null,
    var section229Field1: String? = null,

    // Field to be used in future - added by Dmitriy
    var section229A2: String? = null,
    var section229B2: String? = null,
    var section229C2: String? = null,
    var section229A3: String? = null,
    var section229B3: String? = null,
    var section229C3: String? = null,


    var section230A1: Int? = null,
    var section230A2: Int? = null,
    var section230A3: Double? = null,
    var section230B1: Int? = null,
    var section230B2: Int? = null,
    var section230B3: Double? = null,
    var section230Field1: String? = null
)