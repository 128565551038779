package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.GovernanceField
import com.ecosave.watch.portal.models.esg.GovernanceState
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section211Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var state: GovernanceState
}

val Section2_11 = FC<Section211Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val state = props.state

    SectionMainTitle {
        title = "2-11 CHAIR OF THE HIGHEST GOVERNANCE BODY"
    }

    Box {
        Label {
            label = "Name of chair of the highest governance body\n"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_11_A1.name
            value = state.section211A1
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label =
                "Are they a senior executive? If yes, explain their function within the organization’s management, the reasons for this arrangement, and how conflicts of interest are prevented and mitigated?"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_11_A2.name
            value = state.section211A2
            onChange = { event -> onChangeTextArea(event) }
        }
    }

}