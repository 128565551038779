package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.GovernanceField
import com.ecosave.watch.portal.models.esg.GovernanceState
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section219Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var state: GovernanceState
}

val Section2_19 = FC<Section219Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val state = props.state

    SectionMainTitle {
        title = "2-19 REMUNERATION POLICIES"
    }

    Box {
        Label {
            label = "Fixed pay and variable pay"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_19_A1.name
            value = state.section219A1
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "Sign-on bonuses or recruitment incentive payments"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_19_A2.name
            value = state.section219A2
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "Termination payments"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_19_A3.name
            value = state.section219A3
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "Clawbacks"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_19_A4.name
            value = state.section219A4
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "Retirement benefits"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_19_A5.name
            value = state.section219A5
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "Others"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_19_A6.name
            value = state.section219A6
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label =
                "Describe how the remuneration policies for members of the highest governance body and senior executives relate to their objectives and performance in relation to the management of the organization’s impacts on the economy, environment, and people."
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_19_A7.name
            value = state.section219A7
            onChange = { event -> onChangeTextArea(event) }
        }
    }
}