package com.ecosave.watch.portal.models.esg

import kotlin.random.Random
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.uuid.UUID
import kotlinx.uuid.generateUUID

@Serializable
data class EconomicPerformanceState(
    var section2011A1: String? = null,
    var section2011A2: String? = null,
    var section2011A3: String? = null,
    var section2011A4: String? = null,
    var section2011A5: String? = null,
    var section2011A6: String? = null,
    var section2011A7: String? = null,
    var section2011Field1: String? = null,
    var section2011Field2: String? = null,

    var financialRisksList: MutableList<FinancialRisks> = MutableList(1) { FinancialRisks() },
    var financialOpportunitiesList: MutableList<FinancialOpportunities> = MutableList(1) { FinancialOpportunities() },
    var section2012Field1: String? = null,

    var section2013A1: Double? = null,
    var section2013A2: String? = null,
    var section2013A3: String? = null,
    var section2013A4: String? = null,
    var section2013A5: String? = null,
    var retirementPlansList: MutableList<RetirementPlans> = MutableList(1) { RetirementPlans() },

    var section2014A1: Double? = null,
    var section2014A2: Double? = null,
    var section2014A3: Double? = null,
    var section2014A4: Double? = null,
    var section2014A5: Double? = null,
    var section2014A6: Double? = null,
    var section2014A7: Double? = null,
    var section2014A8: Double? = null,
    var countriesAndValuesList: MutableList<CountriesAndValues> = MutableList(1) { CountriesAndValues() },
    var section2014Part2Field1: String? = null
)

@Serializable
data class FinancialRisks(
    @Transient
    val key: String = UUID.generateUUID(Random).toString(),
    var section2012RiskA1: String? = null,
    var section2012RiskA2: String? = null,
    var section2012RiskA3: String? = null,
    var section2012RiskA4: String? = null,
    var section2012RiskA5: String? = null
)

@Serializable
data class FinancialOpportunities(
    @Transient
    val key: String = UUID.generateUUID(Random).toString(),
    var section2012OpportunityA1: String? = null,
    var section2012OpportunityA2: String? = null,
    var section2012OpportunityA3: String? = null,
    var section2012OpportunityA4: String? = null,
    var section2012OpportunityA5: String? = null
)

@Serializable
data class RetirementPlans(
    @Transient
    val key: String = UUID.generateUUID(Random).toString(),
    var section2013A6: String? = null,
    var section2013A7: Double? = null,
    var section2013A8: Double? = null,
    var section2013A9: String? = null,
    var section2013A10: String? = null,
    var section2013A11: String? = null
)

@Serializable
data class CountriesAndValues(
    @Transient
    val key: String = UUID.generateUUID(Random).toString(),
    var section2014Part2A1: String? = null,
    var section2014Part2A3: Double? = null,
    var section2014Part2A2: Double? = null,
    var section2014Part2A4: Double? = null,
    var section2014Part2A5: Double? = null,
    var section2014Part2A6: Double? = null,
    var section2014Part2A7: Double? = null,
    var section2014Part2A8: Double? = null,
    var section2014Part2A9: Double? = null
)
