package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class GovernanceState(
    var section29A1: Int? = null,
    var section29A2: Int? = null,
    var section29A3: String? = null,
    var section29A4: Int? = null,
    var section29A5: Int? = null,
    var section29A6: Int? = null,
    var section29A7: Int? = null,
    var section29A8: Int? = null,
    var section29A9: String? = null,
    var section29A10: String? = null,
    var section29Field1: String? = null,
    var section29Field2: String? = null,

    var section210A1: String? = null,
    var section210A2: String? = null,
    var section210A3: String? = null,
    var section210A4: String? = null,
    var section210A5: String? = null,
    var section210A6: String? = null,
    var section210Field1: String? = null,

    var section211A1: String? = null,
    var section211A2: String? = null,

    var section212A1: String? = null,
    var section212A2: String? = null,
    var section212A3: String? = null,
    var section212A4: String? = null,
    var section212A5: String? = null,

    var section213A1: String? = null,
    var section213A2: String? = null,
    var section213A3: String? = null,
    var section213A4: String? = null,
    var section214A1: String? = null,
    var section214A2: String? = null,
    var section215A1: String? = null,
    var section215A2: String? = null,
    var section215A3: String? = null,
    var section215A4: String? = null,
    var section215A5: String? = null,
    var section215A6: String? = null,
    var section215A7: String? = null,
    var section215A8: String? = null,
    var section215A9: String? = null,
    var section215A10: String? = null,
    var section215A11: String? = null,
    var section216A1: String? = null,
    var section216A2: String? = null,

    var section217A1: String? = null,

    var section218A1: String? = null,
    var section218A2: String? = null,
    var section218A3: String? = null,
    var section218A4: String? = null,

    var section219A1: String? = null,
    var section219A2: String? = null,
    var section219A3: String? = null,
    var section219A4: String? = null,
    var section219A5: String? = null,
    var section219A6: String? = null,
    var section219A7: String? = null,

    var section220A1: String? = null,
    var section220A2: String? = null,
    var section220A3: String? = null,
    var section220A4: String? = null,
    var section220A5: String? = null,

    var section221B1: Double? = null,
    var section221B2: Double? = null,
    var section221B3: String? = null,

    var section221B4: Double? = null,
    var section221B5: Double? = null,
    var section221B6: String? = null,

    var section221C1: Double? = null,
    var section221C2: Double? = null,
    var section221C3: String? = null,

    var section221C4: Double? = null,
    var section221C5: Double? = null,
    var section221C6: String? = null,
    var section221Field1: String? = null,

    )