package com.ecosave.watch.portal.components.esg.tax


import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.helpers.esg.TaxField
import com.ecosave.watch.portal.models.esg.TaxState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.TaxStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section2071Props : Props {
    var onChange: (FormEvent<HTMLDivElement>) -> Unit
    var state: TaxState
}

val Section207_1 = FC<Section2071Props> { props ->

    val onChangeFunction = props.onChange
    val state = props.state

    SectionMainTitle {
        title = "207-1 APPROACH TO TAX"
    }
    SectionSubHeading {
        subHeading =
            "Description of the organization’s tax approach:"
    }

    Box {
        className = ESGCommonStyles.TABLE_WRAPPER.cssClass
        Box {
            className = TaxStyles.SECTION_207_TABLE.cssClass
            Label {
                label = "Is a tax strategy used?"
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_1_A1.name
                value = state.section2071A1
                onChange = { event -> onChangeFunction(event) }
            }
            Label {
                label = "Link to tax strategy if available"
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_1_A2.name
                value = state.section2071A2
                onChange = { event -> onChangeFunction(event) }
            }
            Label {
                label =
                    "What is the Governance Body or Executive-Level position within the organization that formally reviews and approves the tax strategy?"
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_1_A3.name
                value = state.section2071A3
                onChange = { event -> onChangeFunction(event) }
            }
            Label {
                label = "What is the frequency of the review?"
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_1_A4.name
                value = state.section2071A4
                onChange = { event -> onChangeFunction(event) }
            }
            Label {
                label = "What is the approach to regulatory compliance?"
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_1_A5.name
                value = state.section2071A5
                onChange = { event -> onChangeFunction(event) }
            }
            Label {
                label = "How the approach to tax is linked to the business and sustainable development strategies of the organization?"
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_1_A6.name
                value = state.section2071A6
                onChange = { event -> onChangeFunction(event) }
            }

        }
    }

}




