package com.ecosave.watch.portal.helpers.esg

const val debounceDelay: Long = 800

object ReportConstants {
    const val ESG_FINANCIAL_YEAR_FOUR_DIGITS = "This Field must have four digits."
    const val ESG_CALENDAR_YEAR_MAX_DIGITS = 4
    const val ESG_CREATE_REPORT_PAGE = 0
    const val ESG_SELECT_SECTIONS = 1
    const val ESG_TOC_SECTIONS = 2
    const val ESG_CHOSEN_SECTION_PAGE = 3
    const val TEXT_AREA_MIN_ROWS = 5
    const val TEXT_AREA_MAX_ROWS = 20
    const val DOWNLOAD_SUCCESS = "Download Completed"
    const val DOWNLOAD_FAILED = "Download Failed"
    const val DELETED_SUCCESS = "Successfully Deleted"
    const val DELETED_FAILED = "Deletion Failed"
}

