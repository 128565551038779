package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.components.common.DialogWrapper
import com.ecosave.watch.portal.components.esg.anticompetitivebehavior.AntiCompetitiveBehaviorComponent
import com.ecosave.watch.portal.components.esg.customerhealthnsafety.CustomerHealthAndSafetyComponent
import com.ecosave.watch.portal.components.esg.customerprivacy.CustomerPrivacy
import com.ecosave.watch.portal.components.esg.economicperformance.EconomicPerformanceComponent
import com.ecosave.watch.portal.components.esg.generaldisclosures.ActivitiesAndWorkersComponent
import com.ecosave.watch.portal.components.esg.generaldisclosures.GovernanceComponent
import com.ecosave.watch.portal.components.esg.generaldisclosures.TheOrgAndItsRepPracticesComponent
import com.ecosave.watch.portal.components.esg.indirecteconomicimpacts.IndirectEconomicImpactsComponent
import com.ecosave.watch.portal.components.esg.marketingandlabeling.MarketingAndLabeling
import com.ecosave.watch.portal.components.esg.marketpresence.MarketPresenceComponent
import com.ecosave.watch.portal.components.esg.procurementpractices.ProcurementPracticesComponent
import com.ecosave.watch.portal.components.esg.publicpolicy.PublicPolicy
import com.ecosave.watch.portal.components.esg.tax.TaxComponent
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.useDebounce
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.ReportConstants.ESG_CHOSEN_SECTION_PAGE
import com.ecosave.watch.portal.helpers.esg.ReportConstants.ESG_CREATE_REPORT_PAGE
import com.ecosave.watch.portal.helpers.esg.ReportConstants.ESG_SELECT_SECTIONS
import com.ecosave.watch.portal.helpers.esg.ReportConstants.ESG_TOC_SECTIONS
import com.ecosave.watch.portal.helpers.esg.TOCSectionsEnum
import com.ecosave.watch.portal.helpers.esg.autoSaveEsgCollectionField
import com.ecosave.watch.portal.helpers.esg.autoSaveEsgField
import com.ecosave.watch.portal.helpers.esg.debounceDelay
import com.ecosave.watch.portal.helpers.esg.isEsgFormValid
import com.ecosave.watch.portal.models.esg.ActivitiesAndWorkersState
import com.ecosave.watch.portal.models.esg.AntiCompetitiveBehaviorState
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgReportData
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.EsgReportValidationState
import com.ecosave.watch.portal.models.esg.GovernanceState
import com.ecosave.watch.portal.models.esg.IndirectEconomicImpactsState
import com.ecosave.watch.portal.models.esg.ProcurementPracticesState
import com.ecosave.watch.portal.models.esg.ReportFinancialYear
import com.ecosave.watch.portal.models.esg.StakeholderEngagementState
import com.ecosave.watch.portal.models.esg.StrategyPoliciesPracticesState
import com.ecosave.watch.portal.models.esg.TheOrgAndItsRepPracticesState
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.esg.createEsgReport
import com.ecosave.watch.portal.services.esg.updateEsgReport
import io.ktor.http.*
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.DialogContent
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.dom.html.ReactHTML
import react.useEffect
import react.useState
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.TextAlignLast
import web.cssom.px
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles

external interface EsgMainComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var esgReportValidationStateProps: EsgReportValidationState
    var esgReportValidationStateSetterProps: StateSetter<EsgReportValidationState>
    var esgReportSummarySwitchStateSetterProps: StateSetter<Boolean>
    var esgReportPageNumber: Int
    var esgReportPageNumberSetter: StateSetter<Int>
}

val EsgMainComponent = FC<EsgMainComponentProps> { props ->

    val debounce = useDebounce(debounceDelay)
    val pageNumber = props.esgReportPageNumber
    val pageNumberSetter = props.esgReportPageNumberSetter
    val reportState = props.esgReportStateProps
    val setReportState = props.esgReportStateSetterProps
    val esgReportValidationState = props.esgReportValidationStateProps
    val esgReportValidationStateSetter = props.esgReportValidationStateSetterProps
    val esgReportSummarySwitchStateSetter = props.esgReportSummarySwitchStateSetterProps
    var updatedReport: EsgReportState? by useState(null)
    val (openAlertNotifications, setOpenAlertNotifications) = useState(false)
    val (notificationStatus, setNotificationStatus) = useState(NotificationStatus.SUCCESS)
    val (notificationMessage, setNotificationMessage) = useState("")
    val (inputState, setInputState) = useState(CurrentInputState(null, "", 0, JsonPrimitive(""), "", ""))
    var tableOfContentsClickedSection by useState<TOCSectionsEnum>()
    val (addDeleteRowOrSectionApiCallInProgress, setAddDeleteRowOrSectionApiCallInProgress) = useState(false)
    val (addDeleteRowOrSection, setAddDeleteRowOrSection) = useState(DynamicTableAction.ADD)
    var showAutoSaveErrorDialog by useState(false)
    var isLoading by useState(false)
    val autoSaveFailureData by useState(mutableMapOf<String, CurrentInputState>())

    suspend fun autoSave(fieldName: String, parentPath: String?, index: Int?, value: JsonPrimitive?, objectName: String): ApiCallStatus {
        val status: ApiCallStatus?
        if (parentPath == null) {
            status = autoSaveEsgField(
                path = fieldName,
                value = value,
                objectName = objectName,
                reportName = reportState.reportFileName
            )
        } else {
            status = autoSaveEsgCollectionField(
                parentPath = parentPath,
                childPath = fieldName,
                index = index!!,
                value = value,
                objectName = objectName,
                reportName = reportState.reportFileName
            )
        }

        if (status == ApiCallStatus.FAILURE) {
            val currentState = CurrentInputState(
                parentPath = parentPath,
                childPath = fieldName,
                index = index,
                value = value,
                objectName = objectName,
                reportName = reportState.reportFileName
            )
            autoSaveFailureData[currentState.childPath] = currentState
            showAutoSaveErrorDialog = true
        }
        return status
    }

    val handler: () -> Unit = {
        mainScope.launch {
            autoSave(inputState.childPath, inputState.parentPath, inputState.index ?: 0, inputState.value, inputState.objectName)
        }
    }


    useEffect(reportState) {
        if (inputState.childPath !== "") {
            debounce(handler)
        }
    }

    val onBlurApiCall: (String, JsonPrimitive, Int?, String?, String) -> Unit = { fieldName, value, index, parentPath, objectName ->
        mainScope.launch {
            autoSave(fieldName, parentPath, index, value, objectName)
        }
    }

    fun showNotification(message: String, status: NotificationStatus) {
        setNotificationStatus(status)
        setNotificationMessage(message)
        setOpenAlertNotifications(true)
    }

    Box {
        className = styles.MAIN_ESG_FORM.cssClass
        Box {
            className = styles.GRI_TITLE.cssClass
            Typography {
                component = ReactHTML.h4
                variant = TypographyVariant.h4
                when (pageNumber) {
                    ESG_CREATE_REPORT_PAGE -> +"Create Your Report"
                    ESG_SELECT_SECTIONS -> +"Which ESG Report Sections Would You Like To Create"
                    ESG_TOC_SECTIONS -> +"Select A Section To Edit"
                }
            }
        }
        when (pageNumber) {
            ESG_CREATE_REPORT_PAGE -> {
                CreateReportComponent {
                    esgReportStateProps = reportState
                    esgReportStateSetterProps = setReportState
                    esgReportValidationStateProps = esgReportValidationState
                    esgReportValidationStateSetterProps = esgReportValidationStateSetter
                }
            }

            ESG_SELECT_SECTIONS -> {
                SelectEsgSectionsComponent {
                    esgReportStateProps = reportState
                    esgReportStateSetterProps = setReportState
                }
            }

            ESG_TOC_SECTIONS -> {
                TableOfContentsComponent {
                    esgReportStateProps = reportState
                    esgReportStateSetterProps = setReportState
                    tocClickedSection = {
                        tableOfContentsClickedSection = it
                        pageNumberSetter(3)
                    }
                }
            }

            ESG_CHOSEN_SECTION_PAGE -> {
                val selectedComponent = when (tableOfContentsClickedSection) {
                    TOCSectionsEnum.THE_ORG_AND_ITS_REP_PRACTICES -> TheOrgAndItsRepPracticesComponent
                    TOCSectionsEnum.ACTIVITIES_AND_WORKERS -> ActivitiesAndWorkersComponent
                    TOCSectionsEnum.GOVERNANCE -> GovernanceComponent
                    TOCSectionsEnum.STRATEGY_POLICIES_PRACTICES -> StrategyPoliciesPracticesComponent
                    TOCSectionsEnum.PROCUREMENT_PRACTICES -> ProcurementPracticesComponent
                    TOCSectionsEnum.ANTI_COMPETITIVE_BEHAVIOR -> AntiCompetitiveBehaviorComponent
                    TOCSectionsEnum.STAKEHOLDER_ENGAGEMENT -> StakeholderEngagementComponent
                    TOCSectionsEnum.OCCUPATION_HEALTH_AND_SAFETY -> OccupationHealthAndSafetyComponent
                    TOCSectionsEnum.ECONOMIC_PERFORMANCE -> EconomicPerformanceComponent
                    TOCSectionsEnum.INDIRECT_ECONOMIC_IMPACTS -> IndirectEconomicImpactsComponent
                    TOCSectionsEnum.MARKET_PRESENCE -> MarketPresenceComponent
                    TOCSectionsEnum.TAX -> TaxComponent
                    TOCSectionsEnum.CUSTOMER_HEALTH_AND_SAFETY -> CustomerHealthAndSafetyComponent
                    TOCSectionsEnum.PUBLIC_POLICY -> PublicPolicy
                    TOCSectionsEnum.MARKETING_AND_LABELING -> MarketingAndLabeling
                    TOCSectionsEnum.CUSTOMER_PRIVACY -> CustomerPrivacy
                    else -> null
                }
                selectedComponent?.let {
                    it {
                        this.reportState = reportState
                        this.setReportState = setReportState
                        this.setInputState = setInputState
                        this.setNotificationMessage = setNotificationMessage
                        this.setNotificationStatus = setNotificationStatus
                        this.setOpenAlertNotifications = setOpenAlertNotifications
                        this.setAddDeleteRowOrSectionApiCallInProgress = setAddDeleteRowOrSectionApiCallInProgress
                        this.setAddDeleteRowOrSection = setAddDeleteRowOrSection
                        this.onBlurApiCall = onBlurApiCall
                    }
                }
            }
        }
    }
    Box {
        className = styles.ESG_INPUT_BUTTON.cssClass

        sx {
            paddingBottom = 20.px
            paddingTop = 20.px
            textAlignLast = TextAlignLast.end
        }
        Button {
            variant = ButtonVariant.outlined
            onClick = {
                if (pageNumber == 0) {
                    esgReportSummarySwitchStateSetter(false)
                } else {
                    pageNumberSetter(pageNumber - 1)
                }
            }
            +"Back"
        }
        Button {
            variant = ButtonVariant.outlined
            onClick = {
                when (pageNumber) {
                    ESG_CREATE_REPORT_PAGE -> {
                        if (isEsgFormValid(reportState, esgReportValidationState, esgReportValidationStateSetter)) {
                            mainScope.launch {
                                val reportResponse = createEsgReport(reportState)
                                if (reportResponse == HttpStatusCode.Conflict) {
                                    setNotificationStatus(NotificationStatus.ERROR)
                                    setNotificationMessage("Report file name already exists.")
                                    setOpenAlertNotifications(true)
                                } else {
                                    pageNumberSetter(ESG_SELECT_SECTIONS)
                                }
                            }
                        }
                    }

                    ESG_SELECT_SECTIONS -> {
                        mainScope.launch {
                            val updatedReportResult = updateEsgReport(reportState)
                            if (updatedReportResult == null) {
                                setNotificationStatus(NotificationStatus.ERROR)
                                setNotificationMessage("There has been an error updating sections, please retry.")
                                setOpenAlertNotifications(true)
                            } else {
                                setReportState(updatedReportResult)
                                pageNumberSetter(ESG_TOC_SECTIONS)
                            }
                        }
                    }

                    ESG_TOC_SECTIONS -> {
                        setReportState(
                            EsgReportState(
                                reportFinancialYear = ReportFinancialYear(), report = EsgReportData(
                                    theOrgAndItsRepPractices = TheOrgAndItsRepPracticesState(),
                                    activitiesAndWorkers = ActivitiesAndWorkersState(),
                                    governance = GovernanceState(),
                                    strategyPoliciesPractices = StrategyPoliciesPracticesState(),
                                    stakeholderEngagement = StakeholderEngagementState(),
                                    procurementPractices = ProcurementPracticesState(),
                                    antiCompetitiveBehavior = AntiCompetitiveBehaviorState(),
                                    indirectEconomicImpacts = IndirectEconomicImpactsState()
                                )
                            )
                        )
                        esgReportSummarySwitchStateSetter(false)
                    }

                    else -> {
                        mainScope.launch {
                            updatedReport = updateEsgReport(reportState)
                            //TODO IGNORE will be addressed in next PR according to Salman
                        }
                        pageNumberSetter(2)
                    }
                }
            }
            when (pageNumber) {
                ESG_TOC_SECTIONS -> +"Summary"
                ESG_CHOSEN_SECTION_PAGE -> +"Table Of Contents"
                else -> +"Next"
            }
        }
    }
    AlertNotifications {
        open = openAlertNotifications
        message = notificationMessage
        status = notificationStatus
        closeNotification = {
            setOpenAlertNotifications(false)
        }
    }
    DialogSpinner {
        open = addDeleteRowOrSectionApiCallInProgress
        message = when (addDeleteRowOrSection) {
            DynamicTableAction.ADD -> "Adding..."
            DynamicTableAction.DELETE -> "Deleting..."
        }
    }
    DialogWrapper {
        open = showAutoSaveErrorDialog
        DialogContent {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                gap = 20.px
            }
            Box {
                +"We encountered an issue. Either your system is not connected to network or our server is down. As a result, your recent data might not have been saved successfully."
            }
            Button {
                variant = ButtonVariant.contained
                disabled = isLoading
                onClick = {
                    mainScope.launch {
                        isLoading = true

                        val keysToRemove = mutableListOf<String>()

                        autoSaveFailureData.forEach { (key, value) ->
                            if (autoSave(value.childPath, value.parentPath, value.index, value.value, value.objectName) == ApiCallStatus.SUCCESS) {
                                keysToRemove.add(key)
                            }
                        }

                        keysToRemove.forEach { key ->
                            autoSaveFailureData.remove(key)
                        }

                        if (autoSaveFailureData.isEmpty()) {
                            showNotification("Data has been saved successfully.", NotificationStatus.SUCCESS)
                            showAutoSaveErrorDialog = false
                        } else {
                            showNotification(Constants.GENERIC_EXCEPTION_MESSAGE, NotificationStatus.ERROR)
                        }
                        isLoading = false
                    }
                }
                if (isLoading) +"Saving data..." else +"Retry and save data"
            }
        }
    }
}