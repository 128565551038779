package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class WasteState(
    var section3061A1: String? = null,
    var section3061A2: String? = null,
    var section3061A3: String? = null,
    var section3061B1: String? = null,
    var section3061B2: String? = null,
    var section3061B3: String? = null,
    var section3061C1: String? = null,
    var section3061C2: String? = null,
    var section3061C3: String? = null,
    var section3061D1: String? = null,
    var section3061D2: String? = null,
    var section3061D3: String? = null,
    var section3062A1: String? = null,
    var section3062A2: String? = null,
    var section3062A3: String? = null,
    var section3063Waste3: String? = null,
    var section3063Waste4: String? = null,
    var section3063Waste5: String? = null,
    var section3063Waste6: String? = null,
    var section3063Unit3: String? = null,
    var section3063Unit4: String? = null,
    var section3063Unit5: String? = null,
    var section3063Unit6: String? = null,
    var section3063Current1: Int? = null,
    var section3063Current2: Int? = null,
    var section3063Current3: Int? = null,
    var section3063Current4: Int? = null,
    var section3063Current5: Int? = null,
    var section3063Current6: Int? = null,
    var section3063Previous1: Int? = null,
    var section3063Previous2: Int? = null,
    var section3063Previous3: Int? = null,
    var section3063Previous4: Int? = null,
    var section3063Previous5: Int? = null,
    var section3063Previous6: Int? = null,
    var section3063Baseline1: Int? = null,
    var section3063Baseline2: Int? = null,
    var section3063Baseline3: Int? = null,
    var section3063Baseline4: Int? = null,
    var section3063Baseline5: Int? = null,
    var section3063Baseline6: Int? = null,
    var section3064Current1: Int? = null,
    var section3064Current2: Int? = null,
    var section3064Current3: Int? = null,
    var section3064Current4: Int? = null,
    var section3064Current5: Int? = null,
    var section3064Current6: Int? = null,
    var section3064Current7: Int? = null,
    var section3064Current8: Int? = null,
    var section3064Current9: Int? = null,
    var section3064Current10: Int? = null,
    var section3064Current11: Int? = null,
    var section3064Current12: Int? = null,
    var section3064Current13: Int? = null,
    var section3064Current14: Int? = null,
    var section3064Current15: Int? = null,
    var section3064Current16: Int? = null,
    var section3064Current17: Int? = null,
    var section3064Current18: Int? = null,
    var section3064Current19: Int? = null,
    var section3064Current20: Int? = null,
    var section3064Current21: Int? = null,
    var section3064Current22: Int? = null,
    var section3064Previous1: Int? = null,
    var section3064Previous2: Int? = null,
    var section3064Previous3: Int? = null,
    var section3064Previous4: Int? = null,
    var section3064Previous5: Int? = null,
    var section3064Previous6: Int? = null,
    var section3064Previous7: Int? = null,
    var section3064Previous8: Int? = null,
    var section3064Previous9: Int? = null,
    var section3064Previous10: Int? = null,
    var section3064Previous11: Int? = null,
    var section3064Previous12: Int? = null,
    var section3064Previous13: Int? = null,
    var section3064Previous14: Int? = null,
    var section3064Previous15: Int? = null,
    var section3064Previous16: Int? = null,
    var section3064Previous17: Int? = null,
    var section3064Previous18: Int? = null,
    var section3064Previous19: Int? = null,
    var section3064Previous20: Int? = null,
    var section3064Previous21: Int? = null,
    var section3064Previous22: Int? = null,
    var section3064Baseline1: Int? = null,
    var section3064Baseline2: Int? = null,
    var section3064Baseline3: Int? = null,
    var section3064Baseline4: Int? = null,
    var section3064Baseline5: Int? = null,
    var section3064Baseline6: Int? = null,
    var section3064Baseline7: Int? = null,
    var section3064Baseline8: Int? = null,
    var section3064Baseline9: Int? = null,
    var section3064Baseline10: Int? = null,
    var section3064Baseline11: Int? = null,
    var section3064Baseline12: Int? = null,
    var section3064Baseline13: Int? = null,
    var section3064Baseline14: Int? = null,
    var section3064Baseline15: Int? = null,
    var section3064Baseline16: Int? = null,
    var section3064Baseline17: Int? = null,
    var section3064Baseline18: Int? = null,
    var section3064Baseline19: Int? = null,
    var section3064Baseline20: Int? = null,
    var section3064Baseline21: Int? = null,
    var section3064Baseline22: Int? = null,
    var section3065Current1: Int? = null,
    var section3065Current2: Int? = null,
    var section3065Current3: Int? = null,
    var section3065Current4: Int? = null,
    var section3065Current5: Int? = null,
    var section3065Current6: Int? = null,
    var section3065Current7: Int? = null,
    var section3065Current8: Int? = null,
    var section3065Current9: Int? = null,
    var section3065Current10: Int? = null,
    var section3065Current11: Int? = null,
    var section3065Current12: Int? = null,
    var section3065Current13: Int? = null,
    var section3065Current14: Int? = null,
    var section3065Current15: Int? = null,
    var section3065Current16: Int? = null,
    var section3065Current17: Int? = null,
    var section3065Current18: Int? = null,
    var section3065Current19: Int? = null,
    var section3065Current20: Int? = null,
    var section3065Current21: Int? = null,
    var section3065Current22: Int? = null,
    var section3065Current23: Int? = null,
    var section3065Current24: Int? = null,
    var section3065Current25: Int? = null,
    var section3065Current26: Int? = null,
    var section3065Current27: Int? = null,
    var section3065Current28: Int? = null,
    var section3065Previous1: Int? = null,
    var section3065Previous2: Int? = null,
    var section3065Previous3: Int? = null,
    var section3065Previous4: Int? = null,
    var section3065Previous5: Int? = null,
    var section3065Previous6: Int? = null,
    var section3065Previous7: Int? = null,
    var section3065Previous8: Int? = null,
    var section3065Previous9: Int? = null,
    var section3065Previous10: Int? = null,
    var section3065Previous11: Int? = null,
    var section3065Previous12: Int? = null,
    var section3065Previous13: Int? = null,
    var section3065Previous14: Int? = null,
    var section3065Previous15: Int? = null,
    var section3065Previous16: Int? = null,
    var section3065Previous17: Int? = null,
    var section3065Previous18: Int? = null,
    var section3065Previous19: Int? = null,
    var section3065Previous20: Int? = null,
    var section3065Previous21: Int? = null,
    var section3065Previous22: Int? = null,
    var section3065Previous23: Int? = null,
    var section3065Previous24: Int? = null,
    var section3065Previous25: Int? = null,
    var section3065Previous26: Int? = null,
    var section3065Previous27: Int? = null,
    var section3065Previous28: Int? = null,
    var section3065Baseline1: Int? = null,
    var section3065Baseline2: Int? = null,
    var section3065Baseline3: Int? = null,
    var section3065Baseline4: Int? = null,
    var section3065Baseline5: Int? = null,
    var section3065Baseline6: Int? = null,
    var section3065Baseline7: Int? = null,
    var section3065Baseline8: Int? = null,
    var section3065Baseline9: Int? = null,
    var section3065Baseline10: Int? = null,
    var section3065Baseline11: Int? = null,
    var section3065Baseline12: Int? = null,
    var section3065Baseline13: Int? = null,
    var section3065Baseline14: Int? = null,
    var section3065Baseline15: Int? = null,
    var section3065Baseline16: Int? = null,
    var section3065Baseline17: Int? = null,
    var section3065Baseline18: Int? = null,
    var section3065Baseline19: Int? = null,
    var section3065Baseline20: Int? = null,
    var section3065Baseline21: Int? = null,
    var section3065Baseline22: Int? = null,
    var section3065Baseline23: Int? = null,
    var section3065Baseline24: Int? = null,
    var section3065Baseline25: Int? = null,
    var section3065Baseline26: Int? = null,
    var section3065Baseline27: Int? = null,
    var section3065Baseline28: Int? = null,
)
