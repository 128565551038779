package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.ActivitiesAndWorkersField
import com.ecosave.watch.portal.models.esg.ActivitiesAndWorkersState
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section26Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var state: ActivitiesAndWorkersState
}

val Section2_6 = FC<Section26Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val state = props.state

    SectionMainTitle {
        title = "2-6 ACTIVITIES, VALUE CHAIN, AND OTHER BUSINESS RELATIONSHIPS"
    }

    Box {
        Label {
            label = "Sectors in which organization is active"
        }
        ESGTextAreaFieldWrapper {
            name = ActivitiesAndWorkersField.SECTION_2_6_FIELD1.name
            value = state.section26Field1
            onChange = { event -> onChangeTextArea(event) }
        }
    }

    Box {
        Label {
            label =
                "Describe the value chain, including the activities, products, services, and markets served; the supply chain; and the entities downstream from the organization and their activities"
        }
        ESGTextAreaFieldWrapper {
            name = ActivitiesAndWorkersField.SECTION_2_6_FIELD2.name
            value = state.section26Field2
            onChange = { event -> onChangeTextArea(event) }
        }
    }

    Box {
        Label {
            label = "Other relevant business relationships"
        }
        ESGTextAreaFieldWrapper {
            name = ActivitiesAndWorkersField.SECTION_2_6_FIELD3.name
            value = state.section26Field3
            onChange = { event -> onChangeTextArea(event) }
        }
    }

    Box {
        Label {
            label = "Describe significant changes compared to previous reporting period"
        }
        ESGTextAreaFieldWrapper {
            name = ActivitiesAndWorkersField.SECTION_2_6_FIELD4.name
            value = state.section26Field4
            onChange = { event -> onChangeTextArea(event) }
        }
    }

}