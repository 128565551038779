package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.helpers.esg.TheOrgAndItsRepPracticesField
import com.ecosave.watch.portal.models.esg.TheOrgAndItsRepPracticesState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.GeneralDisclosuresStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section21Props : Props {
    var onChangeText: (FormEvent<HTMLDivElement>) -> Unit
    var state: TheOrgAndItsRepPracticesState
}

val Section2_1 = FC<Section21Props> { props ->

    val onChangeText = props.onChangeText
    val state = props.state

    SectionMainTitle {
        title = "2-1: ORGANIZATIONAL DETAILS"
    }

    Box {
        Label {
            label = "Nature of Ownership & Legal Form"
        }
        ESGTextFieldWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD1.name
            value = state.NATURE_OF_OWNERSHIP_LEGAL_FORM
            onChange = { event -> onChangeText(event) }
        }
    }

    Box {
        Label {
            label = "Legal Name Of Organization"
        }
        ESGTextFieldWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD2.name
            value = state.section21Field2
            onChange = { event -> onChangeText(event) }
        }
    }

    SectionSubHeading {
        subHeading = "Location of Headquarters"
    }

    Box {
        className = ESGCommonStyles.TABLE_WRAPPER.cssClass
        Box {
            className = GeneralDisclosuresStyles.SECTION_2_1_LOCATION.cssClass

            Box {
                Label {
                    label = "Street Address:"
                }
                ESGTextFieldWrapper {
                    name = TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD3.name
                    value = state.section21Field3
                    onChange = { event -> onChangeText(event) }
                }
            }
            Box {
                Label {
                    label = "Street Address Line 2:"
                }
                ESGTextFieldWrapper {
                    name = TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD4.name
                    value = state.section21Field4
                    onChange = { event -> onChangeText(event) }
                }
            }
            Box {
                Label {
                    label = "City:"
                }
                ESGTextFieldWrapper {
                    name = TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD5.name
                    value = state.section21Field5
                    onChange = { event -> onChangeText(event) }
                }
            }
            Box {
                Label {
                    label = "State:"
                }
                ESGTextFieldWrapper {
                    name = TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD6.name
                    value = state.section21Field6
                    onChange = { event -> onChangeText(event) }
                }
            }
            Box {
                Label {
                    label = "Zip Code:"
                }
                ESGTextFieldWrapper {
                    name = TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD7.name
                    value = state.section21Field7
                    onChange = { event -> onChangeText(event) }
                }
            }
        }
    }

    SectionSubHeading {
        subHeading = "Countries of Operation"
    }
    Box {
        Label {
            label = "Countries of Operation"
        }
        ESGTextFieldWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_1_FIELD8.name
            value = state.section21Field8
            onChange = { event -> onChangeText(event) }
        }
    }
}