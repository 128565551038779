package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.GovernanceField
import com.ecosave.watch.portal.models.esg.GovernanceState
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section217Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var state: GovernanceState
}

val Section2_17 = FC<Section217Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val state = props.state

    SectionMainTitle {
        title = "2-17 COLLECTIVE KNOWLEDGE OF THE HIGHEST GOVERNANCE BODY"
    }

    Box {
        Label {
            label =
                "What are the measures to advance the collective knowledge, skills, and experience of the highest governance body on sustainable development?"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_17_A1.name
            value = state.section217A1
            onChange = { event -> onChangeTextArea(event) }
        }
    }
}