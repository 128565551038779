package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class FreedomOfAssociationAndCollectiveBargainingState(
    var section4071A1: String? = null,
    var section4071A2: String? = null,
    var section4071B1: String? = null,
    var section4071B2: String? = null,
    var section4071C1: String? = null,
    var section4071C2: String? = null,
)
