package com.ecosave.watch.portal.models.esg

import com.ecosave.watch.portal.helpers.esg.EsgSection
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonPrimitive

@Serializable
data class ReportFinancialYear(
    val financialYear: Int? = null,
    val calendarDateStart: String = "",
    val calendarDateEnd: String = ""
)

@Serializable
data class ModifiedBy(
    val firstName: String,
    val lastName: String,
    val companyId: Int,
    val companyName: String,
    val jobTitle: String,
    val userRole: String,
    val emailAddress: String
)

@Serializable
data class EsgReportState(
    var reportFileName: String = "",
    var reportTitle: String = "",
    val report: EsgReportData? = EsgReportData(),
    val projectName: String = "",
    var companyName: String = "",
    val organizationName: String? = null,
    val reportFinancialYear: ReportFinancialYear,
    val modifiedBy: ModifiedBy? = null,
    val lastModifiedTime: String = "",
    var mainTableOfContents: MainTableOfContents? = null,
    var sections: MutableList<EsgSection> = mutableListOf(),
    val lastModifiedBy: String = "",
    var tableOfContents: List<EsgTableOfContent>? = null,
)

@Serializable
data class EsgReportData(
    val activitiesAndWorkers: ActivitiesAndWorkersState? = null,
    val antiCompetitiveBehavior: AntiCompetitiveBehaviorState? = null,
    val antiCorruption: AntiCorruptionState? = null,
    val biodiversity: BiodiversityState? = null,
    val childLabor: ChildLaborState? = null,
    val customerHealthAndSafety: CustomerHealthAndSafetyState? = null,
    val customerPrivacy: CustomerPrivacyState? = null,
    val disclosuresOnMaterialTopics: DisclosuresOnMaterialTopicsState? = null,
    val diversityAndEqualOpportunity: DiversityAndEqualOpportunityState? = null,
    val economicPerformance: EconomicPerformanceState? = null,
    val emissions: EmissionsState? = null,
    val employment: EmploymentState? = null,
    val energy: EnergyState? = null,
    val energy2: Energy2State? = null,
    val environmentalCompliance: EnvironmentalComplianceState? = null,
    val forcedOrCompulsoryLabor: ForcedOrCompulsoryLaborState? = null,
    val freedomOfAssociationAndCollectiveBargaining: FreedomOfAssociationAndCollectiveBargainingState? = null,
    val governance: GovernanceState? = null,
    val humanRightsAssessment: HumanRightsAssessmentState? = null,
    val indirectEconomicImpacts: IndirectEconomicImpactsState? = null,
    val laborOrManagementRelations: LaborOrManagementRelationsState? = null,
    val localCommunity: LocalCommunityState? = null,
    val marketingAndLabeling: MarketingAndLabelingState? = null,
    val marketPresence: MarketPresenceState? = null,
    val materials: MaterialsState? = null,
    val nonDiscrimination: NonDiscriminationState? = null,
    val occupationalHealthAndSafety: OccupationalHealthAndSafetyState? = null,
    val procurementPractices: ProcurementPracticesState? = null,
    val publicPolicy: PublicPolicyState? = null,
    val rightsOfIndigenousPeoples: RightsOfIndigenousPeoplesState? = null,
    val securityPractices: SecurityPracticesState? = null,
    val socioeconomicCompliance: SocioeconomicComplianceState? = null,
    val stakeholderEngagement: StakeholderEngagementState? = null,
    val strategyPoliciesPractices: StrategyPoliciesPracticesState? = null,
    val supplierEnvironmentalAssessment: SupplierEnvironmentalAssessmentState? = null,
    val supplierSocialAssessment: SupplierSocialAssessmentState? = null,
    val tax: TaxState? = null,
    val theOrgAndItsRepPractices: TheOrgAndItsRepPracticesState? = null,
    val trainingAndEducation: TrainingAndEducationState? = null,
    val waste: WasteState? = null,
    val waterAndEffluents: WaterAndEffluentsState? = null,
    val waterAndEffluents2: WaterAndEffluents2State? = null,
    val waterAndEffluents3: WaterAndEffluents3State? = null,
    var reportCoverInfo: ReportCoverInfoState? = null,
)

@Serializable
data class EsgTableOfContent(
    val mainTitle: EsgSection,
    val esgContents: List<EsgContent>
)

@Serializable
data class EsgContent(
    val subTitle: String? = null,
    val items: List<String>
)

@Serializable
data class MainTitleStatusTracker(
    val esgSection: EsgSection,
    var percentCompleted: Int,
    val modifiedBy: ModifiedBy? = null
)

@Serializable
data class SubTitleStatusTracker(
    val subTitle: String? = null,
    val subSection: String? = null,
    var percentCompleted: Int = 0,
    var modifiedBy: ModifiedBy? = null,
    val modifiedDateTime: String? = null
)

@Serializable
data class ItemStatusTracker(
    val item: String,
    var completed: Boolean
)

@Serializable
data class EnrichedSubContent(
    val subTitleStatusTracker: SubTitleStatusTracker? = SubTitleStatusTracker(),
    val itemStatusTrackerList: List<ItemStatusTracker>
)

@Serializable
data class EnrichedTableOfContent(
    val mainTitleStatusTracker: MainTitleStatusTracker,
    val enrichedSubContents: List<EnrichedSubContent>
)

@Serializable
data class MainTableOfContents(
    var totalPercentCompleted: Int,
    val enrichedTableOfContents: List<EnrichedTableOfContent>?
)

@Serializable
data class UpdateEsgSection(
    val reportFileName: String,
    val selectedEsgSections: List<EsgSection>
)

@Serializable
data class UpdateEnRichedToc(
    val reportFileName: String,
    val mainTableOfContents: MainTableOfContents
)

data class CurrentInputState (
    val  parentPath: String? = null,
    val childPath: String,
    val index: Int?= null,
    val value: JsonPrimitive? = null,
    val objectName: String,
    val reportName: String
)