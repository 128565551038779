package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class HumanRightsAssessmentState(
    var section4121A1: Int? = null,
    var section4121A2: Double? = null,
    var section4122A1: Int? = null,
    var section4122A2: Double? = null,
    var section4122B1: Int? = null,
    var section4122B2: Double? = null,
    var section4123A1: Int? = null,
    var section4123A2: Double? = null,
)
