package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.GovernanceField
import com.ecosave.watch.portal.models.esg.GovernanceState
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section214Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var state: GovernanceState
}

val Section2_14 = FC<Section214Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val state = props.state

    SectionMainTitle {
        title = "2-14 ROLE OF THE HIGHEST GOVERNANCE BODY IN SUSTAINABILITY REPORTING"
    }

    Box {
        Label {
            label =
                "Provide a report whether the highest governance body is responsible for reviewing and approving the reported information, including the organization’s material topics, and if so, describe the process for reviewing and approving the information"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_14_A1.name
            value = state.section214A1
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label =
                "If the highest governance body is not responsible for reviewing and approving the reported information, including the organization’s material topics, explain the reason for this."
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_14_A2.name
            value = state.section214A2
            onChange = { event -> onChangeTextArea(event) }
        }
    }
}