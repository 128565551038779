package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class MaterialsState(
    var section3011A1: Double? = null,
    var section3011A2: Double? = null,
    var section3011A3: Double? = null,
    var section3011B1: Double? = null,
    var section3011B2: Double? = null,
    var section3011B3: Double? = null,
    var section3011C1: Double? = null,
    var section3011C2: Double? = null,
    var section3011C3: Double? = null,
    var section3012Unit1: String? = null,
    var section3012Unit2: String? = null,
    var section3012Unit3: String? = null,
    var section3012Current1: Int? = null,
    var section3012Current2: Int? = null,
    var section3012Current3: Double? = null,
    var section3012Previous1: Int? = null,
    var section3012Previous2: Int? = null,
    var section3012Previous3: Double? = null,
    var section3013Unit1: String? = null,
    var section3013Unit2: String? = null,
    var section3013Unit3: String? = null,
    var section3013Current1: Int? = null,
    var section3013Current2: Int? = null,
    var section3013Current3: Double? = null,
    var section3013Previous1: Int? = null,
    var section3013Previous2: Int? = null,
    var section3013Previous3: Double? = null,
)
