package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class ChildLaborState(
    var section4081A1: String? = null,
    var section4081A2: String? = null,
    var section4081B1: String? = null,
    var section4081B2: String? = null,
    var section4081C1: String? = null,
    var section4081C2: String? = null,
)