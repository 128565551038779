package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.helpers.common.currentYear
import com.ecosave.watch.portal.helpers.esg.validateCompanyName
import com.ecosave.watch.portal.helpers.esg.validateFinancialYear
import com.ecosave.watch.portal.helpers.esg.validateReportName
import com.ecosave.watch.portal.helpers.esg.validateReportTitle
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.EsgReportValidationState

import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControl
import mui.material.FormControlVariant
import mui.material.FormHelperText
import mui.material.MenuItem
import mui.material.Select
import mui.material.TextField
import mui.system.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.html.HTMLInputElement
import web.html.InputType
import com.ecosave.watch.portal.helpers.esg.CreateReportFields as fields
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles

external interface CreateReportComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var esgReportValidationStateProps: EsgReportValidationState
    var esgReportValidationStateSetterProps: StateSetter<EsgReportValidationState>
}

val CreateReportComponent = FC<CreateReportComponentProps> { props ->
    var esgReportState = props.esgReportStateProps
    var esgReportStateSetter = props.esgReportStateSetterProps
    var esgReportValidationState = props.esgReportValidationStateProps
    var esgReportValidationStateSetter = props.esgReportValidationStateSetterProps

    Box {
        className = styles.MAIN_ESG_FORM.cssClass
        Box {
            Button {
                className = styles.ESG_PREVIEW.cssClass
                variant = ButtonVariant.contained
                +"Cover Image"
            }
        }
        Box {
            Box {
                label {
                    className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                    +fields.REPORT_NAME.label
                }
                TextField {
                    className = styles.ESG_CALENDAR_TEXT_FONT.cssClass
                    type = InputType.text
                    variant = FormControlVariant.outlined
                    value = esgReportState.reportFileName

                    onChange = {
                        val target = it.target as HTMLInputElement
                        esgReportStateSetter(
                            esgReportState.copy(
                                reportFileName = target.value
                            )
                        )
                    }
                    error = esgReportValidationState.reportFileNameErrorState
                    helperText = esgReportValidationState.reportFileNameErrorMessage
                    onBlur = {
                        validateReportName(
                            esgReportState,
                            esgReportValidationState,
                            esgReportValidationStateSetter
                        )
                    }
                }
                label {
                    className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                    +fields.REPORT_TITLE.label
                }
                TextField {
                    className = styles.ESG_CALENDAR_TEXT_FONT.cssClass
                    type = InputType.text
                    variant = FormControlVariant.outlined
                    value = esgReportState.reportTitle

                    onChange = {
                        val target = it.target as HTMLInputElement
                        val updatedReportTitle = target.value
                        esgReportStateSetter(
                            esgReportState.copy(
                                reportTitle = updatedReportTitle,
                                report = esgReportState.report?.copy(
                                    reportCoverInfo = esgReportState.report?.reportCoverInfo?.copy(
                                        reportTitle = updatedReportTitle,
                                        coverImagePath = "images/cover.png"

                                    )
                                )
                            )
                        )
                    }

                    error = esgReportValidationState.reportTitleErrorState
                    helperText = esgReportValidationState.reportTitleErrorMessage
                    onBlur = {
                        validateReportTitle(
                            esgReportState,
                            esgReportValidationState,
                            esgReportValidationStateSetter
                        )
                    }
                }
                label {
                    className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                    +fields.COMPANY_NAME.label
                }
                TextField {
                    className = styles.ESG_CALENDAR_TEXT_FONT.cssClass
                    type = InputType.text
                    variant = FormControlVariant.outlined
                    value = esgReportState.companyName

                    onChange = {
                        val target = it.target as HTMLInputElement
                        esgReportStateSetter(
                            esgReportState.copy(
                                companyName = target.value
                            )
                        )
                    }
                    error = esgReportValidationState.companyNameErrorState
                    helperText = esgReportValidationState.companyNameErrorMessage
                    onBlur = {
                        validateCompanyName(
                            esgReportState,
                            esgReportValidationState,
                            esgReportValidationStateSetter
                        )
                    }
                }
            }
            Box {
                label {
                    className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                    +fields.REPORT_FINANCIAL_INFO.label
                }
            }
            Box {
                className = styles.ESG_CALENDAR_MAIN.cssClass
                Box {
                    label {
                        className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                        +fields.REPORT_FINANCIAL_YEAR.label
                    }
                    Box {
                        className = styles.ESG_CALENDAR_TEXT_FONT.cssClass
                        FormControl {
                            fullWidth = true
                            Select {
                                fullWidth = true
                                value = esgReportState.reportFinancialYear.financialYear
                                for (n in 2000..currentYear) {
                                    MenuItem {
                                        value = n.toString()
                                        +n.toString()
                                    }
                                    onChange = { it, _ ->
                                        val selectedYear = it.target.value.toIntOrNull()
                                        if (selectedYear != null) {
                                            val startDate = "${selectedYear}-01-01"
                                            val endDate = "${selectedYear}-12-31"

                                            esgReportStateSetter(
                                                esgReportState.copy(
                                                    reportFinancialYear = esgReportState.reportFinancialYear.copy(
                                                        financialYear = selectedYear,
                                                        calendarDateStart = startDate,
                                                        calendarDateEnd = endDate
                                                    )
                                                )
                                            )
                                        }
                                    }
                                    onBlur = {
                                        validateFinancialYear(
                                            esgReportState,
                                            esgReportValidationState,
                                            esgReportValidationStateSetter
                                        )
                                    }
                                }
                            }
                            error = esgReportValidationState.reportFinancialYear.financialYearErrorState
                            if (esgReportValidationState.reportFinancialYear.financialYearErrorState) {
                                FormHelperText {
                                    +esgReportValidationState.reportFinancialYear.financialYearErrorMessage
                                }
                            }
                        }
                    }
                }
                Box {
                    label {
                        className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                        +fields.REPORT_CAL_DATE_START.label
                    }
                    CalendarDatePicker {
                        esgReportStateProps = esgReportState
                        esgReportStateSetterProps = esgReportStateSetter
                        esgReportValidationStateProps = esgReportValidationState
                        esgReportValidationStateSetterProps = esgReportValidationStateSetter
                        dateStart = true
                        id = "start-date"
                    }
                }
                Box {
                    label {
                        className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                        +fields.REPORT_CAL_DATE_END.label
                    }
                    CalendarDatePicker {
                        esgReportStateProps = esgReportState
                        esgReportStateSetterProps = esgReportStateSetter
                        esgReportValidationStateProps = esgReportValidationState
                        esgReportValidationStateSetterProps = esgReportValidationStateSetter
                        dateStart = false
                        id = "end-date"
                    }
                }
            }
        }
    }
}

