package com.ecosave.watch.portal.helpers.esg

import com.ecosave.watch.portal.helpers.billing.mainScope
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EnrichedSubContent
import com.ecosave.watch.portal.models.esg.EsgCollectionPatch
import com.ecosave.watch.portal.models.esg.EsgCollectionPatchUpdate
import com.ecosave.watch.portal.models.esg.EsgPatch
import com.ecosave.watch.portal.models.esg.EsgPatchUpdate
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.services.esg.autoSaveEsg
import com.ecosave.watch.portal.services.esg.autoSaveEsgCollection
import com.ecosave.watch.portal.services.esg.patchEsgReport
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import react.StateSetter

fun getAllPercentages(inputSubContents: List<EnrichedSubContent>): Int {
    var totalPercentage = 0
    for (i in inputSubContents) {
        i.subTitleStatusTracker?.let {
            totalPercentage += it.percentCompleted
        }
    }
    return totalPercentage / inputSubContents.size
}

fun getPercentageComplete(inputReport: EsgReportState): Int {
    var totalPercentages = 0
    inputReport.mainTableOfContents?.enrichedTableOfContents?.let {
        it.forEach { item ->
            totalPercentages += item.mainTitleStatusTracker.percentCompleted
        }
        totalPercentages /= it.size
    }
    return totalPercentages
}

fun esgPatch(path: String, value: JsonPrimitive, objectName: String, reportName: String) {
    val updatedField = EsgPatch(
        op = "UPSERT",
        path = path,
        value = value
    )
    val patch =
        EsgPatchUpdate(objectName, reportName, updatedField)
    mainScope.launch {
        patchEsgReport(patch)
    }
}

suspend fun autoSaveEsgField(path: String, value: JsonPrimitive?, objectName: String, reportName: String): ApiCallStatus {

    val updatedField = EsgPatch(
        op = "UPSERT",
        path = path,
        value = value
    )
    val patch = EsgPatchUpdate(objectName, reportName, updatedField)
    return autoSaveEsg(patch)
}

suspend fun autoSaveEsgCollectionField(
    parentPath: String,
    childPath: String,
    index: Int,
    value: JsonPrimitive?,
    objectName: String,
    reportName: String
): ApiCallStatus {

    val updatedField = EsgCollectionPatch(
        pathFirst = parentPath,
        pathSecond = childPath,
        index = index,
        value = value
    )
    val patch = EsgCollectionPatchUpdate(objectName, reportName, updatedField)
    return autoSaveEsgCollection(patch)
}

fun savingErrorMessage(
    setNotificationMessage: StateSetter<String>,
    setNotificationStatus: StateSetter<NotificationStatus>,
    setOpenAlertNotifications: StateSetter<Boolean>,
    status: ApiCallStatus?
) {
    if (status == ApiCallStatus.FAILURE || status == null) {
        setNotificationMessage("error while saving...")
        setNotificationStatus(NotificationStatus.ERROR)
        setOpenAlertNotifications(true)
    }
}

fun failureErrorMessage(
    setNotificationMessage: StateSetter<String>,
    setNotificationStatus: StateSetter<NotificationStatus>,
    setOpenAlertNotifications: StateSetter<Boolean>
) {
    setNotificationMessage("An error has occurred, please retry.")
    setNotificationStatus(NotificationStatus.ERROR)
    setOpenAlertNotifications(true)
}


fun resetPreviousPatchState(stateSetter: StateSetter<CurrentInputState>) {
    stateSetter(
        CurrentInputState(
            childPath = "",
            value = JsonPrimitive(""),
            objectName = "",
            reportName = ""
        )
    )
}