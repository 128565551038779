package com.ecosave.watch.portal.components.esg.economicperformance

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.EconomicPerformanceField
import com.ecosave.watch.portal.models.esg.EconomicPerformanceState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import mui.system.sx
import react.FC
import react.Props
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.cssom.px
import web.html.HTMLDivElement
import web.html.HTMLElement

external interface Section2013Props : Props {
    var state: EconomicPerformanceState
    var onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var onChangeTextField: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurTextField: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var addRowOrSection: (String) -> Unit
    var deleteRowOrSection: (String, Int) -> Unit
}

val Section201_3 = FC<Section2013Props> { props ->

    val onChangeNumber = props.onChangeNumber
    val onChangeTextArea = props.onChangeTextArea
    val onBlurNumber = props.onBlurNumber
    val onBlurTextArea = props.onBlurTextArea
    val onChangeTextField = props.onChangeTextField
    val onBlurTextField = props.onBlurTextField
    val state = props.state

    SectionMainTitle {
        title = "201-3 DEFINED BENEFIT PLAN OBLIGATIONS AND OTHER RETIREMENT PLANS"
    }
    Box {
        label {
            +"If the plan’s liabilities are met by the organization’s general resources, the estimated value of those liabilities."
        }
        ESGNumberFieldWrapper {
            name = EconomicPerformanceField.SECTION_201_3_A1.name
            value = state.section2013A1
            onChange = { event -> onChangeNumber(event, null, null) }
            onBlur = { event -> onBlurNumber(event, null, null) }
        }
    }
    SectionSubHeading {
        subHeading = "If a separate fund exists to pay the plan’s pension liabilities:"
    }

    Box {
        className = ESGCommonStyles.GRID_WRAPPER.cssClass
        Box {
            label {
                +"The extent to which the scheme’s liabilities are estimated to be covered by the assets that have been set aside to meet them."
            }
            ESGTextAreaFieldWrapper {
                name = EconomicPerformanceField.SECTION_201_3_A2.name
                value = state.section2013A2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"The basis on which that estimate has been arrived at."
            }
            ESGTextAreaFieldWrapper {
                name = EconomicPerformanceField.SECTION_201_3_A3.name
                value = state.section2013A3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"When that estimate was made?"
            }
            ESGTextAreaFieldWrapper {
                name = EconomicPerformanceField.SECTION_201_3_A4.name
                value = state.section2013A4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }

    Box {
        label {
            +"If a separate fund to pay for pension liabilities is not fully covered, please describe your organization's strategy to work towards full coverage."
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_201_3_A5.name
            value = state.section2013A5
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
    }

    Box {
        className = ESGCommonStyles.STICKY_HEADER.cssClass
        DynamicTableWrapper {
            addRowOrSection = {
                props.addRowOrSection(EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName)
            }
            items = state.retirementPlansList
            action = DynamicTableAction.ADD
            variant = DynamicTableVariant.TABLE
            addRowOrSectionBtnText = "Add a new retirement plan"
            Box {
                sx {
                    padding = 20.px
                }
                SectionSubHeading {
                    subHeading = "For each retirement plan offered, please specify:"
                }
            }
        }
    }

    state.retirementPlansList.forEachIndexed { index, it ->
        DynamicTableWrapper {
            key = it.key
            deleteRowOrSection = {
                props.deleteRowOrSection(EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName, index)
            }
            items = state.retirementPlansList
            action = DynamicTableAction.DELETE
            Box {
                className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                SectionSubHeading {
                    subHeading = "Retirement Plan ${index + 1}"
                }
                label {
                    className = ESGCommonStyles.LABEL.cssClass
                    +"Plan Name"
                }
                ESGTextFieldWrapper {
                    name = EconomicPerformanceField.SECTION_201_3_A6.name
                    value = it.section2013A6
                    onChange = { event -> onChangeTextField(event, index, EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName) }
                    onBlur = { event -> onBlurTextField(event, index, EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName) }
                }
                label {
                    className = ESGCommonStyles.LABEL.cssClass
                    +"Percentage of salary contributed by the employee."
                }
                ESGNumberFieldWrapper {
                    name = EconomicPerformanceField.SECTION_201_3_A7.name
                    value = it.section2013A7
                    onChange = { event -> onChangeNumber(event, index, EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName) }
                    onBlur = { event -> onBlurNumber(event, index, EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName) }
                }
                label {
                    className = ESGCommonStyles.LABEL.cssClass
                    +"Percentage of salary contributed by the employer."
                }
                ESGNumberFieldWrapper {
                    name = EconomicPerformanceField.SECTION_201_3_A8.name
                    value = it.section2013A8
                    onChange = { event -> onChangeNumber(event, index, EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName) }
                    onBlur = { event -> onBlurNumber(event, index, EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName) }
                }
                SectionSubHeading {
                    subHeading = "What is the level of employee participation in this retirement plan? Please indicate:"
                }
                label {
                    className = ESGCommonStyles.LABEL.cssClass
                    +"Mandatory or Voluntary?"
                }
                ESGTextFieldWrapper {
                    name = EconomicPerformanceField.SECTION_201_3_A9.name
                    value = it.section2013A9
                    onChange = { event -> onChangeTextField(event, index, EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName) }
                    onBlur = { event -> onBlurTextField(event, index, EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName) }
                }
                label {
                    className = ESGCommonStyles.LABEL.cssClass
                    +"Regional, Country-based, or other?"
                }
                ESGTextFieldWrapper {
                    name = EconomicPerformanceField.SECTION_201_3_A10.name
                    value = it.section2013A10
                    onChange = { event -> onChangeTextField(event, index, EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName) }
                    onBlur = { event -> onBlurTextField(event, index, EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName) }
                }
                label {
                    className = ESGCommonStyles.LABEL.cssClass
                    +"Whether the scheme has a financial impact (impact on company valuations)."
                }
                ESGTextFieldWrapper {
                    name = EconomicPerformanceField.SECTION_201_3_A11.name
                    value = it.section2013A11
                    onChange = { event -> onChangeTextField(event, index, EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName) }
                    onBlur = { event -> onBlurTextField(event, index, EconomicPerformanceField.SECTION_201_3_RETIREMENT_PLAN.fieldName) }
                }
            }
        }
    }
}