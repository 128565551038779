package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class EmploymentState(
    var section4011Current1: Int? = null,
    var section4011Current2: Int? = null,
    var section4011Current3: Int? = null,
    var section4011Current4: Int? = null,
    var section4011Current5: Int? = null,
    var section4011Current6: Int? = null,
    var section4011Current7: Int? = null,
    var section4011Current8: Int? = null,
    var section4011Current9: Int? = null,
    var section4011Current10: Int? = null,
    var section4011Current11: Int? = null,
    var section4011Current12: Int? = null,
    var section4011Current13: Int? = null,
    var section4011Current14: Int? = null,
    var section4011Current15: Int? = null,
    var section4011Current16: Int? = null,
    var section4011Current17: Int? = null,
    var section4011Current18: Int? = null,
    var section4011Current19: Int? = null,
    var section4011Current20: Int? = null,
    var section4011Current21: Int? = null,
    var section4011Current22: Int? = null,
    var section4011Current23: Int? = null,
    var section4011Current24: Int? = null,
    var section4011Previous1: Int? = null,
    var section4011Previous2: Int? = null,
    var section4011Previous3: Int? = null,
    var section4011Previous4: Int? = null,
    var section4011Previous5: Int? = null,
    var section4011Previous6: Int? = null,
    var section4011Previous7: Int? = null,
    var section4011Previous8: Int? = null,
    var section4011Previous9: Int? = null,
    var section4011Previous10: Int? = null,
    var section4011Previous11: Int? = null,
    var section4011Previous12: Int? = null,
    var section4011Previous13: Int? = null,
    var section4011Previous14: Int? = null,
    var section4011Previous15: Int? = null,
    var section4011Previous16: Int? = null,
    var section4011Previous17: Int? = null,
    var section4011Previous18: Int? = null,
    var section4011Previous19: Int? = null,
    var section4011Previous20: Int? = null,
    var section4011Previous21: Int? = null,
    var section4011Previous22: Int? = null,
    var section4011Previous23: Int? = null,
    var section4011Previous24: Int? = null,
    var section4012Line1: String? = null,
    var section4012Line2: String? = null,
    var section4012Line3: String? = null,
    var section4012Line4: String? = null,
    var section4012Line5: String? = null,
    var section4012Line6: String? = null,
    var section4012Line7: String? = null,
    var section4012Line8: String? = null,
    var section4012Line9: String? = null,
    var section4012Line10: String? = null,
    var section4012Line11: String? = null,
    var section4012Line12: String? = null,
    var section4012Line13: String? = null,
    var section4012Line14: String? = null,
    var section4012Line15: String? = null,
    var section4012Line16: String? = null,
    var section4012Line17: String? = null,
    var section4012Line18: String? = null,
    var section4012Line19: String? = null,
    var section4012Line20: String? = null,
    var section4012Line21: String? = null,
    var section4012Line22: String? = null,
    var section4012Line23: String? = null,
    var section4012Line24: String? = null,
    var section4012Line25: String? = null,
    var section4012Line26: String? = null,
    var section4012Line27: String? = null,
    var section4012Line28: String? = null,
    var section4012Line29: String? = null,
    var section4012Line30: String? = null,
    var section4012Line31: String? = null,
    var section4012Line32: String? = null,
    var section4012Line33: String? = null,
    var section4012Line34: String? = null,
    var section4012Line35: String? = null,
    var section4012Line36: String? = null,
    var section4012Line37: String? = null,
    var section4012Line38: String? = null,
    var section4012Line39: String? = null,
    var section4012Line40: String? = null,
    var section4012Line41: String? = null,
    var section4012Line42: String? = null,
    var section4012Line43: String? = null,
    var section4012Line44: String? = null,
    var section4012Line45: String? = null,
    var section4012Line46: String? = null,
    var section4012Line47: String? = null,
    var section4012Line48: String? = null,
    var section4012Line49: String? = null,
    var section4012Line50: String? = null,
    var section4012Line51: String? = null,
    var section4012Line52: String? = null,
    var section4012Line53: String? = null,
    var section4012Line54: String? = null,
    var section4013Current1: Int? = null,
    var section4013Current2: Int? = null,
    var section4013Current3: Int? = null,
    var section4013Current4: Int? = null,
    var section4013Current5: Int? = null,
    var section4013Current6: Int? = null,
    var section4013Current7: Int? = null,
    var section4013Current8: Int? = null,
    var section4013Current9: Int? = null,
    var section4013Current10: Int? = null,
    var section4013Current11: Int? = null,
    var section4013Current12: Int? = null,
    var section4013Current13: Int? = null,
    var section4013Current14: Int? = null,
    var section4013Current15: Int? = null,
    var section4013Current16: Int? = null,
    var section4013Current17: Int? = null,
    var section4013Current18: Int? = null,
    var section4013Current19: Int? = null,
    var section4013Current20: Int? = null,
    var section4013Current21: Int? = null,
    var section4013Current22: Int? = null,
    var section4013Current23: Int? = null,
    var section4013Current24: Int? = null,
    var section4013Previous1: Int? = null,
    var section4013Previous2: Int? = null,
    var section4013Previous3: Int? = null,
    var section4013Previous4: Int? = null,
    var section4013Previous5: Int? = null,
    var section4013Previous6: Int? = null,
    var section4013Previous7: Int? = null,
    var section4013Previous8: Int? = null,
    var section4013Previous9: Int? = null,
    var section4013Previous10: Int? = null,
    var section4013Previous11: Int? = null,
    var section4013Previous12: Int? = null,
    var section4013Previous13: Int? = null,
    var section4013Previous14: Int? = null,
    var section4013Previous15: Int? = null,
    var section4013Previous16: Int? = null,
    var section4013Previous17: Int? = null,
    var section4013Previous18: Int? = null,
    var section4013Previous19: Int? = null,
    var section4013Previous20: Int? = null,
    var section4013Previous21: Int? = null,
    var section4013Previous22: Int? = null,
    var section4013Previous23: Int? = null,
    var section4013Previous24: Int? = null,
)
