package com.ecosave.watch.portal.models.esg

import kotlin.random.Random
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.uuid.UUID
import kotlinx.uuid.generateUUID

@Serializable
data class AntiCompetitiveBehaviorState(
    var section2061Field1: Long? = null,
    var legalActionsOutcomesList: MutableList<LegalActionsOutcomes> = MutableList(1) { LegalActionsOutcomes() },
)

@Serializable
data class LegalActionsOutcomes(
    @Transient
    val key: String = UUID.generateUUID(Random).toString(),
    var section2061A1: String? = null,
    var section2061B1: String? = null
)
