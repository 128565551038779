package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class EnvironmentalComplianceState(
    var section3071Unit2: String? = null,
    var section3071Unit3: String? = null,
    var section3071Unit4: String? = null,
    var section3071Current1: Int? = null,
    var section3071Current2: Int? = null,
    var section3071Current3: Int? = null,
    var section3071Current4: Int? = null,
    var section3071Previous1: Int? = null,
    var section3071Previous2: Int? = null,
    var section3071Previous3: Int? = null,
    var section3071Previous4: Int? = null,
    var section3071Baseline1: Int? = null,
    var section3071Baseline2: Int? = null,
    var section3071Baseline3: Int? = null,
    var section3071Baseline4: Int? = null,
)
