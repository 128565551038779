package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class EnergyState(
    var section3021Unit1: String? = null,
    var section3021Unit2: String? = null,
    var section3021Unit3: String? = null,
    var section3021Unit4: String? = null,
    var section3021Unit5: String? = null,
    var section3021Unit6: String? = null,
    var section3021Unit7: String? = null,
    var section3021Unit8: String? = null,
    var section3021Unit9: String? = null,
    var section3021Unit10: String? = null,
    var section3021Unit11: String? = null,
    var section3021Unit12: String? = null,
    var section3021Unit13: String? = null,
    var section3021Unit14: String? = null,
    var section3021Unit15: String? = null,
    var section3021Unit16: String? = null,
    var section3021Unit17: String? = null,
    var section3021Unit18: String? = null,
    var section3021Unit19: String? = null,
    var section3021Current1: Int? = null,
    var section3021Current2: Int? = null,
    var section3021Current3: Int? = null,
    var section3021Current4: Int? = null,
    var section3021Current5: Int? = null,
    var section3021Current6: Int? = null,
    var section3021Current7: Int? = null,
    var section3021Current8: Int? = null,
    var section3021Current9: Int? = null,
    var section3021Current10: Int? = null,
    var section3021Current11: Int? = null,
    var section3021Current12: Int? = null,
    var section3021Current13: Int? = null,
    var section3021Current14: Int? = null,
    var section3021Current15: Int? = null,
    var section3021Current16: Int? = null,
    var section3021Current17: Int? = null,
    var section3021Current18: Int? = null,
    var section3021Current19: Int? = null,
    var section3021Previous1: Int? = null,
    var section3021Previous2: Int? = null,
    var section3021Previous3: Int? = null,
    var section3021Previous4: Int? = null,
    var section3021Previous5: Int? = null,
    var section3021Previous6: Int? = null,
    var section3021Previous7: Int? = null,
    var section3021Previous8: Int? = null,
    var section3021Previous9: Int? = null,
    var section3021Previous10: Int? = null,
    var section3021Previous11: Int? = null,
    var section3021Previous12: Int? = null,
    var section3021Previous13: Int? = null,
    var section3021Previous14: Int? = null,
    var section3021Previous15: Int? = null,
    var section3021Previous16: Int? = null,
    var section3021Previous17: Int? = null,
    var section3021Previous18: Int? = null,
    var section3021Previous19: Int? = null,
    var section3021Baseline1: Int? = null,
    var section3021Baseline2: Int? = null,
    var section3021Baseline3: Int? = null,
    var section3021Baseline4: Int? = null,
    var section3021Baseline5: Int? = null,
    var section3021Baseline6: Int? = null,
    var section3021Baseline7: Int? = null,
    var section3021Baseline8: Int? = null,
    var section3021Baseline9: Int? = null,
    var section3021Baseline10: Int? = null,
    var section3021Baseline11: Int? = null,
    var section3021Baseline12: Int? = null,
    var section3021Baseline13: Int? = null,
    var section3021Baseline14: Int? = null,
    var section3021Baseline15: Int? = null,
    var section3021Baseline16: Int? = null,
    var section3021Baseline17: Int? = null,
    var section3021Baseline18: Int? = null,
    var section3021Baseline19: Int? = null,
    var section3022Unit1: String? = null,
    var section3022Unit2: String? = null,
    var section3022Unit3: String? = null,
    var section3022Unit4: String? = null,
    var section3022Unit5: String? = null,
    var section3022Unit6: String? = null,
    var section3022Unit7: String? = null,
    var section3022Unit8: String? = null,
    var section3022Unit9: String? = null,
    var section3022Unit10: String? = null,
    var section3022Unit11: String? = null,
    var section3022Unit12: String? = null,
    var section3022Unit13: String? = null,
    var section3022Unit14: String? = null,
    var section3022Unit15: String? = null,
    var section3022Unit16: String? = null,
    var section3022Unit17: String? = null,
    var section3022Unit18: String? = null,
    var section3022Unit19: String? = null,
    var section3022Current1: Int? = null,
    var section3022Current2: Int? = null,
    var section3022Current3: Int? = null,
    var section3022Current4: Int? = null,
    var section3022Current5: Int? = null,
    var section3022Current6: Int? = null,
    var section3022Current7: Int? = null,
    var section3022Current8: Int? = null,
    var section3022Current9: Int? = null,
    var section3022Current10: Int? = null,
    var section3022Current11: Int? = null,
    var section3022Current12: Int? = null,
    var section3022Current13: Int? = null,
    var section3022Current14: Int? = null,
    var section3022Current15: Int? = null,
    var section3022Current16: Int? = null,
    var section3022Current17: Int? = null,
    var section3022Current18: Int? = null,
    var section3022Current19: Int? = null,
    var section3022Previous1: Int? = null,
    var section3022Previous2: Int? = null,
    var section3022Previous3: Int? = null,
    var section3022Previous4: Int? = null,
    var section3022Previous5: Int? = null,
    var section3022Previous6: Int? = null,
    var section3022Previous7: Int? = null,
    var section3022Previous8: Int? = null,
    var section3022Previous9: Int? = null,
    var section3022Previous10: Int? = null,
    var section3022Previous11: Int? = null,
    var section3022Previous12: Int? = null,
    var section3022Previous13: Int? = null,
    var section3022Previous14: Int? = null,
    var section3022Previous15: Int? = null,
    var section3022Previous16: Int? = null,
    var section3022Previous17: Int? = null,
    var section3022Previous18: Int? = null,
    var section3022Previous19: Int? = null,
    var section3022Baseline1: Int? = null,
    var section3022Baseline2: Int? = null,
    var section3022Baseline3: Int? = null,
    var section3022Baseline4: Int? = null,
    var section3022Baseline5: Int? = null,
    var section3022Baseline6: Int? = null,
    var section3022Baseline7: Int? = null,
    var section3022Baseline8: Int? = null,
    var section3022Baseline9: Int? = null,
    var section3022Baseline10: Int? = null,
    var section3022Baseline11: Int? = null,
    var section3022Baseline12: Int? = null,
    var section3022Baseline13: Int? = null,
    var section3022Baseline14: Int? = null,
    var section3022Baseline15: Int? = null,
    var section3022Baseline16: Int? = null,
    var section3022Baseline17: Int? = null,
    var section3022Baseline18: Int? = null,
    var section3022Baseline19: Int? = null,
    var section3023Unit1: String? = null,
    var section3023Unit2: String? = null,
    var section3023Unit3: String? = null,
    var section3023Current1: Int? = null,
    var section3023Current2: Int? = null,
    var section3023Current3: Int? = null,
    var section3023Previous1: Int? = null,
    var section3023Previous2: Int? = null,
    var section3023Previous3: Int? = null,
    var section3023Baseline1: Int? = null,
    var section3023Baseline2: Int? = null,
    var section3023Baseline3: Int? = null,
)
