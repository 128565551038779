package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGRadioButtonWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.GovernanceField
import com.ecosave.watch.portal.models.esg.GovernanceState
import com.ecosave.watch.portal.styles.esg.GeneralDisclosuresStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLInputElement

external interface Section215Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var onChangeRadio: (ChangeEvent<HTMLInputElement>) -> Unit
    var state: GovernanceState
}

val Section2_15 = FC<Section215Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onChangeRadio = props.onChangeRadio
    val state = props.state

    SectionMainTitle {
        title = "2-15 CONFLICTS OF INTEREST"
    }

    Box {
        className = GeneralDisclosuresStyles.SECTION_2_15_TABLE.cssClass
        TableHeader {
            header = "Type of conflict of interest"
        }
        TableHeader {
            header = "Is it disclosed to stakeholders?"
        }
        Label {
            label = "Cross-board membership"
        }
        ESGRadioButtonWrapper {
            name = GovernanceField.SECTION_2_15_A1.name
            value = state.section215A1
            onChange = { event, _ -> onChangeRadio(event) }
        }
        Label {
            label = "Cross-shareholding with suppliers and other stakeholders"
        }
        ESGRadioButtonWrapper {
            name = GovernanceField.SECTION_2_15_A2.name
            value = state.section215A2
            onChange = { event, _ -> onChangeRadio(event) }
        }
        Label {
            label = "Cross-shareholding with suppliers and other stakeholders"
        }
        ESGRadioButtonWrapper {
            name = GovernanceField.SECTION_2_15_A3.name
            value = state.section215A3
            onChange = { event, _ -> onChangeRadio(event) }
        }
        Label {
            label = "Cross-shareholding with suppliers and other stakeholders"
        }
        ESGRadioButtonWrapper {
            name = GovernanceField.SECTION_2_15_A4.name
            value = state.section215A4
            onChange = { event, _ -> onChangeRadio(event) }
        }
        Label {
            label = "Cross-shareholding with suppliers and other stakeholders"
        }
        ESGRadioButtonWrapper {
            name = GovernanceField.SECTION_2_15_A5.name
            value = state.section215A5
            onChange = { event, _ -> onChangeRadio(event) }
        }
    }
    Box {
        Label {
            label =
                "What's the role of the highest governance body to ensure conflicts of interest are prevented and mitigated"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_15_A6.name
            value = state.section215A6
            onChange = { event -> onChangeTextArea(event) }
        }
    }
}