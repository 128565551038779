package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class DisclosuresOnMaterialTopicsState(
    var section31A1: String? = null,
    var section31A2: String? = null,
    var section31A3: String? = null,
    var section31A4: String? = null,
    var section31A5: String? = null,
    var section31A6: String? = null,
    var section31A7: String? = null,
    var section31A8: String? = null,
    var section31A9: String? = null,
    var section31A10: String? = null,
    var section31A11: String? = null,
    var section31A12: String? = null,
    var section32M1: String? = null,
    var section32M2: String? = null,
    var section32M3: String? = null,
    var section32C1: String? = null,
    var section32C2: String? = null,
    var section32C3: String? = null,
    var section33A1: String? = null,
    var section33A2: String? = null,
    var section33A3: String? = null,
    var section33A4: String? = null,
    var section33A5: String? = null,
    var section33A6: String? = null,
    var section33A7: String? = null,
    var section33A8: String? = null,
    var section33Part2A1: String? = null,      //GRI 3-3 PART 2
    var section33Part2A2: String? = null,
    var section33Part2A3: String? = null,
    var section33Part2B1: String? = null,
    var section33Part2B2: String? = null,
    var section33Part2B3: String? = null,
    var section33Part2C1: String? = null,
    var section33Part2C2: String? = null,
    var section33Part2C3: String? = null,
    var section33Part2D1: String? = null,
    var section33Part2D2: String? = null,
    var section33Part2D3: String? = null,
    var section33Part2E1: String? = null,
    var section33Part2E2: String? = null,
    var section33Part2E3: String? = null,
    var section33Part2F1: String? = null,
    var section33Part2F2: String? = null,
    var section33Part2F3: String? = null,
)
