package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class WaterAndEffluents3State(
    var section3035Unit1: String? = null,
    var section3035Unit2: String? = null,
    var section3035Unit3: String? = null,
    var section3035Unit4: String? = null,
    var section3035Unit5: String? = null,
    var section3035Unit6: String? = null,
    var section3035Unit7: String? = null,
    var section3035Unit8: String? = null,
    var section3035Unit9: String? = null,
    var section3035Unit10: String? = null,
    var section3035Unit11: String? = null,
    var section3035Unit12: String? = null,
    var section3035Unit13: String? = null,
    var section3035Unit14: String? = null,
    var section3035Unit15: String? = null,
    var section3035Unit16: String? = null,
    var section3035Unit17: String? = null,
    var section3035Unit18: String? = null,
    var section3035Unit19: String? = null,
    var section3035Unit20: String? = null,
    var section3035Unit21: String? = null,
    var section3035Unit22: String? = null,
    var section3035Unit23: String? = null,
    var section3035Unit24: String? = null,
    var section3035Unit25: String? = null,
    var section3035Unit26: String? = null,
    var section3035Unit27: String? = null,
    var section3035Unit28: String? = null,
    var section3035Unit29: String? = null,
    var section3035Unit30: String? = null,
    var section3035Unit31: String? = null,
    var section3035Current1: Int? = null,
    var section3035Current2: Int? = null,
    var section3035Current3: Int? = null,
    var section3035Current4: Int? = null,
    var section3035Current5: Int? = null,
    var section3035Current6: Int? = null,
    var section3035Current7: Int? = null,
    var section3035Current8: Int? = null,
    var section3035Current9: Int? = null,
    var section3035Current10: Int? = null,
    var section3035Current11: Int? = null,
    var section3035Current12: Int? = null,
    var section3035Current13: Int? = null,
    var section3035Current14: Int? = null,
    var section3035Current15: Int? = null,
    var section3035Current16: Int? = null,
    var section3035Current17: Int? = null,
    var section3035Current18: Int? = null,
    var section3035Current19: Int? = null,
    var section3035Current20: Int? = null,
    var section3035Current21: Int? = null,
    var section3035Current22: Int? = null,
    var section3035Current23: Int? = null,
    var section3035Current24: Int? = null,
    var section3035Current25: Int? = null,
    var section3035Current26: Int? = null,
    var section3035Current27: Int? = null,
    var section3035Current28: Int? = null,
    var section3035Current29: Int? = null,
    var section3035Current30: Int? = null,
    var section3035Current31: Int? = null,
    var section3035Previous1: Int? = null,
    var section3035Previous2: Int? = null,
    var section3035Previous3: Int? = null,
    var section3035Previous4: Int? = null,
    var section3035Previous5: Int? = null,
    var section3035Previous6: Int? = null,
    var section3035Previous7: Int? = null,
    var section3035Previous8: Int? = null,
    var section3035Previous9: Int? = null,
    var section3035Previous10: Int? = null,
    var section3035Previous11: Int? = null,
    var section3035Previous12: Int? = null,
    var section3035Previous13: Int? = null,
    var section3035Previous14: Int? = null,
    var section3035Previous15: Int? = null,
    var section3035Previous16: Int? = null,
    var section3035Previous17: Int? = null,
    var section3035Previous18: Int? = null,
    var section3035Previous19: Int? = null,
    var section3035Previous20: Int? = null,
    var section3035Previous21: Int? = null,
    var section3035Previous22: Int? = null,
    var section3035Previous23: Int? = null,
    var section3035Previous24: Int? = null,
    var section3035Previous25: Int? = null,
    var section3035Previous26: Int? = null,
    var section3035Previous27: Int? = null,
    var section3035Previous28: Int? = null,
    var section3035Previous29: Int? = null,
    var section3035Previous30: Int? = null,
    var section3035Previous31: Int? = null,
    var section3035Baseline1: Int? = null,
    var section3035Baseline2: Int? = null,
    var section3035Baseline3: Int? = null,
    var section3035Baseline4: Int? = null,
    var section3035Baseline5: Int? = null,
    var section3035Baseline6: Int? = null,
    var section3035Baseline7: Int? = null,
    var section3035Baseline8: Int? = null,
    var section3035Baseline9: Int? = null,
    var section3035Baseline10: Int? = null,
    var section3035Baseline11: Int? = null,
    var section3035Baseline12: Int? = null,
    var section3035Baseline13: Int? = null,
    var section3035Baseline14: Int? = null,
    var section3035Baseline15: Int? = null,
    var section3035Baseline16: Int? = null,
    var section3035Baseline17: Int? = null,
    var section3035Baseline18: Int? = null,
    var section3035Baseline19: Int? = null,
    var section3035Baseline20: Int? = null,
    var section3035Baseline21: Int? = null,
    var section3035Baseline22: Int? = null,
    var section3035Baseline23: Int? = null,
    var section3035Baseline24: Int? = null,
    var section3035Baseline25: Int? = null,
    var section3035Baseline26: Int? = null,
    var section3035Baseline27: Int? = null,
    var section3035Baseline28: Int? = null,
    var section3035Baseline29: Int? = null,
    var section3035Baseline30: Int? = null,
    var section3035Baseline31: Int? = null,
)

