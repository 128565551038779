package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class Energy2State(
    var section3024Unit1: String? = null,
    var section3024Unit2: String? = null,
    var section3024Unit3: String? = null,
    var section3024Unit4: String? = null,
    var section3024Unit5: String? = null,
    var section3024Current1: Int? = null,
    var section3024Current2: Int? = null,
    var section3024Current3: Int? = null,
    var section3024Current4: Int? = null,
    var section3024Current5: Int? = null,
    var section3024Previous1: Int? = null,
    var section3024Previous2: Int? = null,
    var section3024Previous3: Int? = null,
    var section3024Previous4: Int? = null,
    var section3024Previous5: Int? = null,
    var section3024Baseline1: Int? = null,
    var section3024Baseline2: Int? = null,
    var section3024Baseline3: Int? = null,
    var section3024Baseline4: Int? = null,
    var section3024Baseline5: Int? = null,
    var section3025Product1: String? = null,
    var section3025Product2: String? = null,
    var section3025Product3: String? = null,
    var section3025Unit1: String? = null,
    var section3025Unit2: String? = null,
    var section3025Unit3: String? = null,
    var section3025Current1: Int? = null,
    var section3025Current2: Int? = null,
    var section3025Current3: Int? = null,
    var section3025Previous1: Int? = null,
    var section3025Previous2: Int? = null,
    var section3025Previous3: Int? = null,
    var section3025Baseline1: Int? = null,
    var section3025Baseline2: Int? = null,
    var section3025Baseline3: Int? = null,
    var section3025TotalUnit1: Int? = null,
    var section3025TotalCurrent1: Int? = null,
    var section3025TotalPrevious1: Int? = null,
    var section3025TotalBaseline1: Int? = null,
)