package com.ecosave.watch.portal.components.esg.publicpolicy

import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.PublicPolicyField
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.EsgCollectionDeleteRow
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.PublicPolicyState
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.models.esg.SectionPublicPolicy
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

external interface PublicPolicyProps : Props {
    var reportState: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
    var setAddDeleteRowOrSectionApiCallInProgress: StateSetter<Boolean>
    var setAddDeleteRowOrSection: StateSetter<DynamicTableAction>
    var setNotificationMessage: StateSetter<String>
    var setNotificationStatus: StateSetter<NotificationStatus>
    var setOpenAlertNotifications: StateSetter<Boolean>
    var onBlurApiCall: (String, JsonPrimitive, Int?, String?, String) -> Unit
}

val PublicPolicy = FC<PublicPolicyProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.publicPolicy?.let { state ->

        fun stateSetter(state: PublicPolicyState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        publicPolicy = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = EsgSection.PUBLIC_POLICY.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        PublicPolicyField.PUBLIC_POLICY_LIST.fieldName -> {
                            state.sectionPublicPolicyList.add(SectionPublicPolicy())
                            props.setNotificationMessage("A new row has been added successfully.")
                        }
                    }
                    stateSetter(state)
                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(props.setNotificationMessage, props.setNotificationStatus, props.setOpenAlertNotifications)
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = EsgSection.PUBLIC_POLICY,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        PublicPolicyField.PUBLIC_POLICY_LIST.fieldName -> {
                            state.sectionPublicPolicyList.removeAt(index)
                            props.setNotificationMessage("Row has been deleted successfully.")
                        }
                    }
                    stateSetter(state)
                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(props.setNotificationMessage, props.setNotificationStatus, props.setOpenAlertNotifications)
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeTextField: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = PublicPolicyField.valueOf(target.name)
            val propertyValue = target.value

            if (field == PublicPolicyField.PUBLIC_POLICY_LIST.fieldName && changeIndex != null) {
                state.sectionPublicPolicyList[changeIndex].section4151A1 =
                    if (propertyName == PublicPolicyField.SECTION_415_1_A1) propertyValue else state.sectionPublicPolicyList[changeIndex].section4151A1
                state.sectionPublicPolicyList[changeIndex].section4151A2 =
                    if (propertyName == PublicPolicyField.SECTION_415_1_A2) propertyValue else state.sectionPublicPolicyList[changeIndex].section4151A2
            }

            stateSetter(state)
            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName.fieldName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.PUBLIC_POLICY.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextField: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = PublicPolicyField.valueOf(target.name)
            val propertyValue = target.value
            props.onBlurApiCall(propertyName.fieldName, JsonPrimitive(propertyValue), changeIndex, field, EsgSection.PUBLIC_POLICY.name)
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = PublicPolicyField.valueOf(target.name)
            val propertyValue = target.value

            if (field == PublicPolicyField.PUBLIC_POLICY_LIST.fieldName && changeIndex != null) {
                state.sectionPublicPolicyList[changeIndex].section4151A4 =
                    if (propertyName == PublicPolicyField.SECTION_415_1_A4) propertyValue else state.sectionPublicPolicyList[changeIndex].section4151A4

            }

            stateSetter(state)
            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName.fieldName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.PUBLIC_POLICY.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = PublicPolicyField.valueOf(target.name)
            val propertyValue = target.value
            props.onBlurApiCall(propertyName.fieldName, JsonPrimitive(propertyValue), changeIndex, field, EsgSection.PUBLIC_POLICY.name)
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = PublicPolicyField.valueOf(target.name)
                val propertyValue = target.value.toLongOrNull()

                if (field == PublicPolicyField.PUBLIC_POLICY_LIST.fieldName && changeIndex != null) {
                    state.sectionPublicPolicyList[changeIndex].section4151A3 =
                        if (propertyName == PublicPolicyField.SECTION_415_1_A3) propertyValue else state.sectionPublicPolicyList[changeIndex].section4151A3
                }

                stateSetter(state)
                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName.fieldName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.PUBLIC_POLICY.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = PublicPolicyField.valueOf(target.name)
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(propertyName.fieldName, JsonPrimitive(propertyValue), changeIndex, field, EsgSection.PUBLIC_POLICY.name)
            }
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            Section415_1 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onBlurTextArea = onBlurTextArea
                this.onChangeNumber = onChangeNumber
                this.onBlurNumber = onBlurNumber
                this.onChangeTextField = onChangeTextField
                this.onBlurTextField = onBlurTextField
                this.addRowOrSection = addRowOrSection
                this.deleteRowOrSection = deleteRowOrSection
            }
        }
    }
}