package com.ecosave.watch.portal.components.esg.formcontrols

import com.ecosave.watch.portal.helpers.Colors
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.Color
import web.cssom.TextDecoration
import web.cssom.px

external interface SectionMainTitleProps : Props {
    var title: String
}

val SectionMainTitle = FC<SectionMainTitleProps> {
    ReactHTML.label {
        css {
            color = Color(Colors.AQUA)
            fontSize = 25.px
            textDecoration = TextDecoration.underline
        }
        +it.title
    }
}

external interface SectionSubHeadingProps : Props {
    var subHeading: String
}

val SectionSubHeading = FC<SectionSubHeadingProps> {
    ReactHTML.label {
        css {
            color = Color(Colors.HALF_GREEN)
            fontSize = 20.px
        }
        +it.subHeading
    }
}

