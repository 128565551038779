package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.helpers.common.isValidInteger
import com.ecosave.watch.portal.helpers.esg.ActivitiesAndWorkersField
import com.ecosave.watch.portal.helpers.esg.EsgSubSection
import com.ecosave.watch.portal.models.esg.ActivitiesAndWorkersState
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgReportState
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles


external interface ActivitiesAndWorkersComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
}


val ActivitiesAndWorkersComponent = FC<ActivitiesAndWorkersComponentProps> { props ->

    val esgState = props.esgReportStateProps
    val esgStateSetter = props.esgReportStateSetterProps

    esgState.report?.activitiesAndWorkers?.let { state ->

        fun stateSetter(state: ActivitiesAndWorkersState) {
            esgStateSetter(
                esgState.copy(
                    report = esgState.report.copy(
                        activitiesAndWorkers = state
                    )
                )
            )
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            val target = event.target as HTMLInputElement
            if (isValidInteger(target)) {
                val propertyName = ActivitiesAndWorkersField.valueOf(target.name)
                val propertyValue = target.value.toIntOrNull()
                stateSetter(
                    state.copy(
                        section27A1 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A1) propertyValue else state.section27A1,
                        section27A2 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A2) propertyValue else state.section27A2,
                        section27A3 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A3) propertyValue else state.section27A3,
                        section27A4 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A4) propertyValue else state.section27A4,
                        section27A5 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A5) propertyValue else state.section27A5,
                        section27A6 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A6) propertyValue else state.section27A6,
                        section27A7 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A7) propertyValue else state.section27A7,
                        section27A8 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A8) propertyValue else state.section27A8,
                        section27A9 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A9) propertyValue else state.section27A9,
                        section27A10 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A10) propertyValue else state.section27A10,
                        section27A11 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A11) propertyValue else state.section27A11,
                        section27A12 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A12) propertyValue else state.section27A12,
                        section27A13 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A13) propertyValue else state.section27A13,
                        section27A14 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A14) propertyValue else state.section27A14,
                        section27A15 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A15) propertyValue else state.section27A15,
                        section27A16 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A16) propertyValue else state.section27A16,
                        section27A17 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A17) propertyValue else state.section27A17,
                        section27A18 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A18) propertyValue else state.section27A18,
                        section27A19 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A19) propertyValue else state.section27A19,
                        section27A20 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A20) propertyValue else state.section27A20,
                        section27A21 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A21) propertyValue else state.section27A21,
                        section27A22 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A22) propertyValue else state.section27A22,
                        section27A23 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A23) propertyValue else state.section27A23,
                        section27A24 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_A24) propertyValue else state.section27A24,
                        section27B1 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B1) propertyValue else state.section27B1,
                        section27B2 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B2) propertyValue else state.section27B2,
                        section27B3 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B3) propertyValue else state.section27B3,
                        section27B4 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B4) propertyValue else state.section27B4,
                        section27B5 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B5) propertyValue else state.section27B5,
                        section27B6 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B6) propertyValue else state.section27B6,
                        section27B7 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B7) propertyValue else state.section27B7,
                        section27B8 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B8) propertyValue else state.section27B8,
                        section27B9 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B9) propertyValue else state.section27B9,
                        section27B10 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B10) propertyValue else state.section27B10,
                        section27B11 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B11) propertyValue else state.section27B11,
                        section27B12 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B12) propertyValue else state.section27B12,
                        section27B13 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B13) propertyValue else state.section27B13,
                        section27B14 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B14) propertyValue else state.section27B14,
                        section27B15 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B15) propertyValue else state.section27B15,
                        section27B16 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B16) propertyValue else state.section27B16,
                        section27B17 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B17) propertyValue else state.section27B17,
                        section27B18 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B18) propertyValue else state.section27B18,
                        section27B19 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B19) propertyValue else state.section27B19,
                        section27B20 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B20) propertyValue else state.section27B20,
                        section27B21 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B21) propertyValue else state.section27B21,
                        section27B22 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B22) propertyValue else state.section27B22,
                        section27B23 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B23) propertyValue else state.section27B23,
                        section27B24 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_B24) propertyValue else state.section27B24
                    )
                )
                props.setInputState(
                    CurrentInputState(
                        childPath = propertyName.fieldName,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSubSection.ACTIVITIES_AND_WORKERS.name,
                        reportName = esgState.reportFileName
                    )
                )
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = ActivitiesAndWorkersField.valueOf(target.name)
            val propertyValue = target.value
            stateSetter(
                state.copy(
                    section26Field1 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_6_FIELD1) propertyValue else state.section26Field1,
                    section26Field2 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_6_FIELD2) propertyValue else state.section26Field2,
                    section26Field3 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_6_FIELD3) propertyValue else state.section26Field3,
                    section26Field4 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_6_FIELD4) propertyValue else state.section26Field4,

                    section27Field1 = if (propertyName == ActivitiesAndWorkersField.SECTION_2_7_FIELD1) propertyValue else state.section27Field1
                )
            )
            props.setInputState(
                CurrentInputState(
                    childPath = propertyName.fieldName,
                    value = JsonPrimitive(propertyValue.trim()),
                    objectName = EsgSubSection.ACTIVITIES_AND_WORKERS.name,
                    reportName = esgState.reportFileName
                )
            )
        }

        Box {
            className = styles.SECTION_MAIN_LAYOUT.cssClass

            Section2_6 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
            }
            Section2_7 {
                this.state = state
                this.onChangeTextArea = onChangeTextArea
                this.onChangeNumber = onChangeNumber
            }
            Section2_8 {
                this.state = state
            }
        }
    }
}