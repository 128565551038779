package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class CustomerHealthAndSafetyState(
    var section4161A1: String? = null,
    var section4162Current1: Int? = null,
    var section4162Current2: Int? = null,
    var section4162Current3: Int? = null,
    var section4162Current4: Int? = null,
    var section4162Previous1: Int? = null,
    var section4162Previous2: Int? = null,
    var section4162Previous3: Int? = null,
    var section4162Previous4: Int? = null,
    var section4162Field1: String? = null,
)
