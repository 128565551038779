package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class ActivitiesAndWorkersState(
    var section26Field1: String? = null,
    var section26Field2: String? = null,
    var section26Field3: String? = null,
    var section26Field4: String? = null,

    var section27A1: Int? = null,
    var section27B1: Int? = null,
    var section27A2: Int? = null,
    var section27B2: Int? = null,
    var section27A3: Int? = null,
    var section27B3: Int? = null,
    var section27A4: Int? = null,
    var section27B4: Int? = null,
    var section27A5: Int? = null,
    var section27B5: Int? = null,
    var section27A6: Int? = null,
    var section27B6: Int? = null,
    var section27A7: Int? = null,
    var section27B7: Int? = null,
    var section27A8: Int? = null,
    var section27B8: Int? = null,
    var section27A9: Int? = null,
    var section27B9: Int? = null,
    var section27A10: Int? = null,
    var section27B10: Int? = null,
    var section27A11: Int? = null,
    var section27B11: Int? = null,
    var section27A12: Int? = null,
    var section27B12: Int? = null,
    var section27A13: Int? = null,
    var section27B13: Int? = null,
    var section27A14: Int? = null,
    var section27B14: Int? = null,
    var section27A15: Int? = null,
    var section27B15: Int? = null,
    var section27A16: Int? = null,
    var section27B16: Int? = null,
    var section27A17: Int? = null,
    var section27B17: Int? = null,
    var section27A18: Int? = null,
    var section27B18: Int? = null,
    var section27A19: Int? = null,
    var section27B19: Int? = null,
    var section27A20: Int? = null,
    var section27B20: Int? = null,
    var section27A21: Int? = null,
    var section27B21: Int? = null,
    var section27A22: Int? = null,
    var section27B22: Int? = null,
    var section27A23: Int? = null,
    var section27B23: Int? = null,
    var section27A24: Int? = null,
    var section27B24: Int? = null,

    var section27Field1: String? = null,

    var section28A1: String? = null,
    var section28A2: String? = null,
    var section28A3: String? = null,

    var section28B1: Int? = null,
    var section28B2: String? = null,
    var section28B3: String? = null,
    var section28B4: String? = null,

    var section28C1: Int? = null,
    var section28C2: String? = null,
    var section28C3: String? = null,
    var section28C4: String? = null,
    var section28Field1: String? = null,
    var section28Field2: String? = null,

    // these are the fields for our bright future by Dmitry
    var section28A4: String? = null,
    var section28B5: String? = null,
    var section28C5: String? = null,

)