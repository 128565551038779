package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class NonDiscriminationState(
    var section4061A1: String? = null,
    var section4061A2: String? = null,
    var section4061A3: String? = null,
    var section4061B1: String? = null,
    var section4061B2: String? = null,
    var section4061B3: String? = null,
    var section4061C1: String? = null,
    var section4061C2: String? = null,
    var section4061C3: String? = null,
    var section4061D1: String? = null,
    var section4061D2: String? = null,
    var section4061D3: String? = null,
)
