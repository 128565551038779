package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.GovernanceField
import com.ecosave.watch.portal.models.esg.GovernanceState
import com.ecosave.watch.portal.styles.esg.GeneralDisclosuresStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section212Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var state: GovernanceState
}

val Section2_12 = FC<Section212Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val state = props.state

    SectionMainTitle {
        title = "2-12: ROLE OF THE HIGHEST GOVERNANCE BODY IN OVERSEEING THE MANAGEMENT OF IMPACTS"
    }

    Box {
        Label {
            label =
                "Describe the role of the highest governance body and senior execs in developing, approving, and updating the organization’s purpose, value, or mission statements, strategies, policies, and goals related to sustainable development"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_12_A1.name
            value = state.section212A1
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        className = GeneralDisclosuresStyles.SECTION_2_12_TABLE.cssClass
        Label {
            label =
                "Describe the role of the highest governance body in overseeing the organization’s due diligence and other processes to identify and manage the organization’s impacts on the economy, environment, and people"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_12_A2.name
            value = state.section212A2
            onChange = { event -> onChangeTextArea(event) }
        }
        Label {
            label = "How the highest governance body engages with stakeholders to support these processes"
            subItemLeftMargin = true
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_12_A3.name
            value = state.section212A3
            onChange = { event -> onChangeTextArea(event) }
        }
        Label {
            label = "How the highest governance body considers the outcomes of these processes"
            subItemLeftMargin = true
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_12_A4.name
            value = state.section212A4
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label =
                "What's the role of the highest governance body in reviewing the effectiveness of the organization’s processes, and frequency of this review"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_12_A5.name
            value = state.section212A5
            onChange = { event -> onChangeTextArea(event) }
        }
    }
}