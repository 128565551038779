package com.ecosave.watch.portal.components.esg.economicperformance

import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.EconomicPerformanceField
import com.ecosave.watch.portal.models.esg.EconomicPerformanceState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.EconomicPerformanceStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLElement

external interface Section2011Props : Props {
    var state: EconomicPerformanceState
    var onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
}

val Section201_1 = FC<Section2011Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val state = props.state

    SectionMainTitle {
        title = "201-1 DIRECT ECONOMIC VALUE GENERATED AND DISTRIBUTED (EVG&D)"
    }
    SectionSubHeading {
        subHeading =
            "Direct economic value generated and distributed (EVG&D) on an accruals basis, including the basic components for the organization’s global operations as listed below. If data are presented on a cash basis, report the justification for this decision in addition to reporting the following basic components:"
    }

    Box {
        className = EconomicPerformanceStyles.SECTION_201_1_TABLE.cssClass
        TableHeader {
            header = "Total Organization / Country / Region / Market Level"
        }
        TableHeader {
            header = ""
        }
        Label {
            label = "Direct economic value generated (Revenue)"
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_201_1_A1.name
            value = state.section2011A1
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
        Label {
            label = "Operating costs"
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_201_1_A2.name
            value = state.section2011A2
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
        Label {
            label = "Employee wages and benefits"
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_201_1_A3.name
            value = state.section2011A3
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
        Label {
            label = "Payments to providers of capital"
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_201_1_A4.name
            value = state.section2011A4
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
        Label {
            label = "Payments to government by country"
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_201_1_A5.name
            value = state.section2011A5
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
        Label {
            label = "Community investments"
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_201_1_A6.name
            value = state.section2011A6
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
        Label {
            label = "Economic value retained (Profits/Losses)"
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_201_1_A7.name
            value = state.section2011A7
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
    }

    Box {
        label {
            className = ESGCommonStyles.LABEL.cssClass
            +"Where significant, report EVG&D separately at country, regional, or market levels, and the criteria used for defining significance."
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_201_1_FIELD1.name
            value = state.section2011Field1
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
    }
    Box {
        label {
            className = ESGCommonStyles.LABEL.cssClass
            +"Provide criteria for defining significance if EVG&D reports are separated into different categories. If applicable, data should come from the organization’s audited financial or profit and loss (P&L) statement."
        }
        ESGTextAreaFieldWrapper {
            name = EconomicPerformanceField.SECTION_201_1_FIELD2.name
            value = state.section2011Field2
            onChange = { event -> onChangeTextArea(event, null, null) }
            onBlur = { event -> onBlurTextArea(event, null, null) }
        }
    }
}