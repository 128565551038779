package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class SupplierSocialAssessmentState(
    var section4141A1: Double? = null,
    var section4142A1: Int? = null,
    var section4142B1: Int? = null,
    var section4142C1: Double? = null,
    var section4142D1: Double? = null,
)
