package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.TheOrgAndItsRepPracticesField
import com.ecosave.watch.portal.models.esg.TheOrgAndItsRepPracticesState
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section23Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var state: TheOrgAndItsRepPracticesState
}

val Section2_3 = FC<Section23Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val state = props.state

    SectionMainTitle {
        title = "2-3 REPORTING PERIOD, FREQUENCY AND CONTACT POINT"
    }

    Box {
        Label {
            label = "What is the reporting period?"
        }
        ESGTextAreaFieldWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_3_FIELD1.name
            value = state.section23Field1
            onChange = { event -> onChangeTextArea(event) }
        }
    }

    Box {
        Label {
            label = "How frequent is the reporting period?"
        }
        ESGTextAreaFieldWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_3_FIELD2.name
            value = state.section23Field2
            onChange = { event -> onChangeTextArea(event) }
        }
    }

    Box {
        Label {
            label = "Is the reporting period for financial reporting different? If so, why?"
        }
        ESGTextAreaFieldWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_3_FIELD3.name
            value = state.section23Field3
            onChange = { event -> onChangeTextArea(event) }
        }
    }

    Box {
        Label {
            label = "Publication date of the report"
        }
        ESGTextAreaFieldWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_3_FIELD4.name
            value = state.section23Field4
            onChange = { event -> onChangeTextArea(event) }
        }
    }

    Box {
        Label {
            label = "Contact point for questions about the report"
        }
        ESGTextAreaFieldWrapper {
            name = TheOrgAndItsRepPracticesField.SECTION_2_3_FIELD5.name
            value = state.section23Field5
            onChange = { event -> onChangeTextArea(event) }
        }
    }
}