package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGRadioButtonWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.helpers.esg.GovernanceField
import com.ecosave.watch.portal.models.esg.GovernanceState
import com.ecosave.watch.portal.styles.esg.GeneralDisclosuresStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLInputElement

external interface Section29Props : Props {
    var onChangeRadio: (ChangeEvent<HTMLInputElement>) -> Unit
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var onChangeNumber: (FormEvent<HTMLDivElement>) -> Unit
    var state: GovernanceState
}

val Section2_9 = FC<Section29Props> { props ->

    val onChangeRadio = props.onChangeRadio
    val onChangeTextArea = props.onChangeTextArea
    val onChangeNumber = props.onChangeNumber
    val state = props.state

    SectionMainTitle {
        title = "2-9 GOVERNANCE STRUCTURE AND COMPOSITION"
    }

    Box {
        className = GeneralDisclosuresStyles.SECTION_2_9_TABLE.cssClass
        SectionSubHeading {
            subHeading = "Highest Governance Body/Committee Demographics"
        }
        Box {
            Label {
                label = "Number of executive members"
            }
            ESGNumberFieldWrapper {
                name = GovernanceField.SECTION_2_9_A1.name
                value = state.section29A1
                onChange = { event -> onChangeNumber(event) }
            }
        }
        Box {
            Label {
                label = "Number of non-executive members"
            }
            ESGNumberFieldWrapper {
                name = GovernanceField.SECTION_2_9_A2.name
                value = state.section29A2
                onChange = { event -> onChangeNumber(event) }
            }
        }
        Box {
            Label {
                label = "Are they independent?"
            }
            ESGRadioButtonWrapper {
                name = GovernanceField.SECTION_2_9_A3.name
                value = state.section29A3
                onChange = { event, _ -> onChangeRadio(event) }
            }
        }
        Box {
            Label {
                label = "Tenure of members"
            }
            ESGNumberFieldWrapper {
                name = GovernanceField.SECTION_2_9_A4.name
                value = state.section29A4
                onChange = { event -> onChangeNumber(event) }
            }
        }
        Box {
            Label {
                label = "Number of other significant positions and commitments held by each member"
            }
            ESGNumberFieldWrapper {
                name = GovernanceField.SECTION_2_9_A5.name
                value = state.section29A5
                onChange = { event -> onChangeNumber(event) }
            }
        }
        Box {
            Label {
                label = "Number of male members"
            }
            ESGNumberFieldWrapper {
                name = GovernanceField.SECTION_2_9_A6.name
                value = state.section29A6
                onChange = { event -> onChangeNumber(event) }
            }
        }
        Box {
            Label {
                label = "Number of female members"
            }
            ESGNumberFieldWrapper {
                name = GovernanceField.SECTION_2_9_A7.name
                value = state.section29A7
                onChange = { event -> onChangeNumber(event) }
            }
        }
        Box {
            Label {
                label = "Number of under-represented social groups in committee"
            }
            ESGNumberFieldWrapper {
                name = GovernanceField.SECTION_2_9_A8.name
                value = state.section29A8
                onChange = { event -> onChangeNumber(event) }
            }
        }
        Box {
            Label {
                label = "Competencies relevant to the impacts of the organization"
            }
            ESGTextAreaFieldWrapper {
                name = GovernanceField.SECTION_2_9_A9.name
                value = state.section29A9
                onChange = { event -> onChangeTextArea(event) }
            }
        }
        Box {
            Label {
                label = "Stakeholder representation"
            }
            ESGTextAreaFieldWrapper {
                name = GovernanceField.SECTION_2_9_A10.name
                value = state.section29A10
                onChange = { event -> onChangeTextArea(event) }
            }
        }
    }

    Box {
        Label {
            label = "Describe the governance structure, including committees of the highest governance body;"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_9_FIELD1.name
            value = state.section29Field1
            onChange = { event -> onChangeTextArea(event) }
        }
    }

    Box {
        Label {
            label =
                "List the committees of the highest governance body that are responsible for decision- making on and overseeing the management of the organization’s impacts on the economy, environment, and people"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_9_FIELD2.name
            value = state.section29Field2
            onChange = { event -> onChangeTextArea(event) }
        }
    }
}