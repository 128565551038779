package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.GovernanceField
import com.ecosave.watch.portal.models.esg.GovernanceState
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section210Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var state: GovernanceState
}

val Section2_10 = FC<Section210Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val state = props.state

    SectionMainTitle {
        title = "2-10 NOMINATION AND SELECTION OF THE HIGHEST GOVERNANCE BODY"
    }

    Box {
        Label {
            label = "Views of stakeholders"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_10_A1.name
            value = state.section210A1
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "Views of shareholders"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_10_A2.name
            value = state.section210A2
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "Diversity"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_10_A3.name
            value = state.section210A3
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "Independence"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_10_A4.name
            value = state.section210A4
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "Competencies relevant to the impacts of the organization"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_10_A5.name
            value = state.section210A5
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "Other criteria"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_10_A6.name
            value = state.section210A6
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "Describe the nomination and selection processes for the highest governance body and it's committees"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_10_FIELD1.name
            value = state.section210Field1
            onChange = { event -> onChangeTextArea(event) }
        }
    }
}