package com.ecosave.watch.portal.components.esg.tax


import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.helpers.esg.TaxField
import com.ecosave.watch.portal.models.esg.TaxState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.TaxStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section2073Props : Props {
    var onChange: (FormEvent<HTMLDivElement>) -> Unit
    var state: TaxState
}

val Section207_3 = FC<Section2073Props> { props ->

    val onChangeFunction = props.onChange
    val state = props.state

    SectionMainTitle {
        title = "207-3 STAKEHOLDER ENGAGEMENT AND MANAGEMENT OF CONCERNS RELATED TO TAX"
    }
    SectionSubHeading {
        subHeading =
            "Any other information on the approach to stakeholder engagement and management of stakeholder concerns related to tax:"
    }

    Box {
        className = ESGCommonStyles.TABLE_WRAPPER.cssClass
        Box {
            className = TaxStyles.SECTION_207_TABLE.cssClass
            Label {
                label = "Approach to stakeholder engagement with tax authorities"
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_3_A1.name
                value = state.section2073A1
                onChange = { event -> onChangeFunction(event) }
            }
            Label {
                label = "Approach to public policy on tax"
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_3_A2.name
                value = state.section2073A2
                onChange = { event -> onChangeFunction(event) }
            }
            Label {
                label =
                    "Processes for collecting and considering the views and concerns of stakeholders, including external stakeholders"
            }
            ESGTextAreaFieldWrapper {
                name = TaxField.SECTION_207_3_A3.name
                value = state.section2073A3
                onChange = { event -> onChangeFunction(event) }
            }
        }
    }

}




