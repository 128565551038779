package com.ecosave.watch.portal.components.esg

import mui.material.Box
import mui.material.Typography
import mui.material.CircularProgress
import mui.material.CircularProgressColor
import mui.material.CircularProgressVariant
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.*

external interface EsgPercentageProps: Props {
    var currentPercent: Int
    var color: CircularProgressColor
}

val zeroPercentage = 0.pct

val EsgPercentComponent = FC<EsgPercentageProps> { props ->
    CircularProgress {
        variant = CircularProgressVariant.determinate
        value = props.currentPercent
        size = 3.5.rem
        color = props.color
    }
    Box {
        sx {
            top = zeroPercentage
            left = zeroPercentage
            bottom = zeroPercentage
            right = zeroPercentage
            margin = zeroPercentage
            position = Position.absolute
            display = Display.flex
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
        }
        Typography {
            variant = TypographyVariant.body1
            +"${props.currentPercent}%"
        }
    }
}