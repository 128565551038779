package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class BiodiversityState(
    var section3041A1: String? = null,
    var section3041A2: String? = null,
    var section3041A3: String? = null,
    var section3041B1: String? = null,
    var section3041B2: String? = null,
    var section3041B3: String? = null,
    var section3041C1: String? = null,
    var section3041C2: String? = null,
    var section3041C3: String? = null,
    var section3041D1: String? = null,
    var section3041D2: String? = null,
    var section3041D3: String? = null,
    var section3041E1: Int? = null,
    var section3041E2: Int? = null,
    var section3041E3: Int? = null,
    var section3041F1: String? = null,
    var section3041F2: String? = null,
    var section3041F3: String? = null,
    var section3041G1: String? = null,
    var section3041G2: String? = null,
    var section3041G3: String? = null,
    var section3042A1: String? = null,
    var section3042A2: String? = null,
    var section3042A3: String? = null,
    var section3042A4: String? = null,
    var section3042A5: String? = null,
    var section3042A6: String? = null,
    var section3042A7: String? = null,
    var section3042A8: String? = null,
    var section3042A9: String? = null,
    var section3042A10: String? = null,
    var section3042A11: String? = null,
    var section3043A1: String? = null,
    var section3043A2: String? = null,
    var section3043A3: String? = null,
    var section3043B1: Int? = null,
    var section3043B2: Int? = null,
    var section3043B3: Int? = null,
    var section3043C1: String? = null,
    var section3043C2: String? = null,
    var section3043C3: String? = null,
    var section3043D1: String? = null,
    var section3043D2: String? = null,
    var section3043D3: String? = null,
    var section3043E1: String? = null,
    var section3043E2: String? = null,
    var section3043E3: String? = null,
    var section3043F1: String? = null,
    var section3043F2: String? = null,
    var section3043F3: String? = null,
    var section3044A1: String? = null,
    var section3044A2: String? = null,
    var section3044A3: String? = null,
    var section3044B1: Int? = null,
    var section3044B2: Int? = null,
    var section3044B3: Int? = null,
    var section3044C1: String? = null,
    var section3044C2: String? = null,
    var section3044C3: String? = null,
    var section3044D1: String? = null,
    var section3044D2: String? = null,
    var section3044D3: String? = null,
    var section3044E1: String? = null,
    var section3044E2: String? = null,
    var section3044E3: String? = null,
    var section3044F1: String? = null,
    var section3044F2: String? = null,
    var section3044F3: String? = null
)