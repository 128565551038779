package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGRadioButtonWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.GovernanceField
import com.ecosave.watch.portal.models.esg.GovernanceState
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLInputElement

external interface Section218Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var onChangeRadio: (ChangeEvent<HTMLInputElement>) -> Unit
    var state: GovernanceState
}

val Section2_18 = FC<Section218Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onChangeRadio = props.onChangeRadio
    val state = props.state

    SectionMainTitle {
        title = "2-18 EVALUATION OF THE PERFORMANCE OF THE HIGHEST GOVERNANCE BODY"
    }

    Box {
        Label {
            label =
                "Describe the processes for evaluating the performance of the highest governance body in overseeing the management of the organization’s impacts on the economy, environment, and people"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_18_A1.name
            value = state.section218A1
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label = "Are the evaluations independent?"
        }
        ESGRadioButtonWrapper {
            name = GovernanceField.SECTION_2_18_A2.name
            value = state.section218A2
            onChange = { event, _ -> onChangeRadio(event) }
        }
    }
    Box {
        Label {
            label = "Frequency of the evaluations"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_18_A3.name
            value = state.section218A3
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label =
                "Describe actions taken in response to the evaluations, including changes to the composition of the highest governance body and organizational practices."
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_18_A4.name
            value = state.section218A4
            onChange = { event -> onChangeTextArea(event) }
        }
    }

}