package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class WaterAndEffluentsState(
    var section3031A1: String? = null,
    var section3031A2: String? = null,
    var section3031A3: String? = null,
    var section3031A4: String? = null,
    var section3031A5: String? = null,
    var section3032A1: String? = null,
    var section3032A2: String? = null,
    var section3032A3: String? = null,
    var section3032B1: String? = null,
    var section3032B2: String? = null,
    var section3032B3: String? = null,
    var section3033Unit1: String? = null,
    var section3033Unit2: String? = null,
    var section3033Unit3: String? = null,
    var section3033Unit4: String? = null,
    var section3033Unit5: String? = null,
    var section3033Unit6: String? = null,
    var section3033Unit7: String? = null,
    var section3033Unit8: String? = null,
    var section3033Unit9: String? = null,
    var section3033Unit10: String? = null,
    var section3033Unit11: String? = null,
    var section3033Unit12: String? = null,
    var section3033Unit13: String? = null,
    var section3033Unit14: String? = null,
    var section3033Unit15: String? = null,
    var section3033Unit16: String? = null,
    var section3033Unit17: String? = null,
    var section3033Unit18: String? = null,
    var section3033Unit19: String? = null,
    var section3033Unit20: String? = null,
    var section3033Unit21: String? = null,
    var section3033Unit22: String? = null,
    var section3033Unit23: String? = null,
    var section3033Unit24: String? = null,
    var section3033Unit25: String? = null,
    var section3033Unit26: String? = null,
    var section3033Unit27: String? = null,
    var section3033Unit28: String? = null,
    var section3033Unit29: String? = null,
    var section3033Unit30: String? = null,
    var section3033Current1: Int? = null,
    var section3033Current2: Int? = null,
    var section3033Current3: Int? = null,
    var section3033Current4: Int? = null,
    var section3033Current5: Int? = null,
    var section3033Current6: Int? = null,
    var section3033Current7: Int? = null,
    var section3033Current8: Int? = null,
    var section3033Current9: Int? = null,
    var section3033Current10: Int? = null,
    var section3033Current11: Int? = null,
    var section3033Current12: Int? = null,
    var section3033Current13: Int? = null,
    var section3033Current14: Int? = null,
    var section3033Current15: Int? = null,
    var section3033Current16: Int? = null,
    var section3033Current17: Int? = null,
    var section3033Current18: Int? = null,
    var section3033Current19: Int? = null,
    var section3033Current20: Int? = null,
    var section3033Current21: Int? = null,
    var section3033Current22: Int? = null,
    var section3033Current23: Int? = null,
    var section3033Current24: Int? = null,
    var section3033Current25: Int? = null,
    var section3033Current26: Int? = null,
    var section3033Current27: Int? = null,
    var section3033Current28: Int? = null,
    var section3033Current29: Int? = null,
    var section3033Current30: Int? = null,
    var section3033Previous1: Int? = null,
    var section3033Previous2: Int? = null,
    var section3033Previous3: Int? = null,
    var section3033Previous4: Int? = null,
    var section3033Previous5: Int? = null,
    var section3033Previous6: Int? = null,
    var section3033Previous7: Int? = null,
    var section3033Previous8: Int? = null,
    var section3033Previous9: Int? = null,
    var section3033Previous10: Int? = null,
    var section3033Previous11: Int? = null,
    var section3033Previous12: Int? = null,
    var section3033Previous13: Int? = null,
    var section3033Previous14: Int? = null,
    var section3033Previous15: Int? = null,
    var section3033Previous16: Int? = null,
    var section3033Previous17: Int? = null,
    var section3033Previous18: Int? = null,
    var section3033Previous19: Int? = null,
    var section3033Previous20: Int? = null,
    var section3033Previous21: Int? = null,
    var section3033Previous22: Int? = null,
    var section3033Previous23: Int? = null,
    var section3033Previous24: Int? = null,
    var section3033Previous25: Int? = null,
    var section3033Previous26: Int? = null,
    var section3033Previous27: Int? = null,
    var section3033Previous28: Int? = null,
    var section3033Previous29: Int? = null,
    var section3033Previous30: Int? = null,
    var section3033Baseline1: Int? = null,
    var section3033Baseline2: Int? = null,
    var section3033Baseline3: Int? = null,
    var section3033Baseline4: Int? = null,
    var section3033Baseline5: Int? = null,
    var section3033Baseline6: Int? = null,
    var section3033Baseline7: Int? = null,
    var section3033Baseline8: Int? = null,
    var section3033Baseline9: Int? = null,
    var section3033Baseline10: Int? = null,
    var section3033Baseline11: Int? = null,
    var section3033Baseline12: Int? = null,
    var section3033Baseline13: Int? = null,
    var section3033Baseline14: Int? = null,
    var section3033Baseline15: Int? = null,
    var section3033Baseline16: Int? = null,
    var section3033Baseline17: Int? = null,
    var section3033Baseline18: Int? = null,
    var section3033Baseline19: Int? = null,
    var section3033Baseline20: Int? = null,
    var section3033Baseline21: Int? = null,
    var section3033Baseline22: Int? = null,
    var section3033Baseline23: Int? = null,
    var section3033Baseline24: Int? = null,
    var section3033Baseline25: Int? = null,
    var section3033Baseline26: Int? = null,
    var section3033Baseline27: Int? = null,
    var section3033Baseline28: Int? = null,
    var section3033Baseline29: Int? = null,
    var section3033Baseline30: Int? = null,
)
