package com.ecosave.watch.portal.components.esg.marketpresence

import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.MarketPresenceField
import com.ecosave.watch.portal.helpers.esg.autoSaveEsgField
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.esg.savingErrorMessage
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.MarketPresenceState
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.Props
import react.StateSetter
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles


external interface MarketPresenceComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
    var setInputState: StateSetter<CurrentInputState>
    var setNotificationMessage: StateSetter<String>
    var setNotificationStatus: StateSetter<NotificationStatus>
    var setOpenAlertNotifications: StateSetter<Boolean>
}


val MarketPresenceComponent = FC<MarketPresenceComponentProps> { props ->

    val esgState = props.esgReportStateProps
    val esgStateSetter = props.esgReportStateSetterProps

    esgState.report?.marketPresence?.let { state ->
        fun stateSetter(state: MarketPresenceState) {
            esgStateSetter(
                esgState.copy(
                    report = esgState.report.copy(
                        marketPresence = state
                    )
                )
            )
        }

        val onChangeRadio: (ChangeEvent<HTMLInputElement>) -> Unit = { event ->
            resetPreviousPatchState(props.setInputState)
            var status: ApiCallStatus?
            val propertyName = MarketPresenceField.valueOf(event.target.name)
            val propertyValue = event.target.value
            stateSetter(
                state.copy(
                    section2021Field1 = if (propertyName == MarketPresenceField.SECTION_202_1_FIELD1) propertyValue else state.section2021Field1,
                    section2021Field2 = if (propertyName == MarketPresenceField.SECTION_202_1_FIELD2) propertyValue else state.section2021Field2
                )
            )
            mainScope.launch {
                status = autoSaveEsgField(
                    propertyName.fieldName,
                    JsonPrimitive(propertyValue),
                    EsgSection.MARKET_PRESENCE.name,
                    esgState.reportFileName
                )
                savingErrorMessage(props.setNotificationMessage, props.setNotificationStatus, props.setOpenAlertNotifications, status)
            }

        }

        val onChangeNumber: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = MarketPresenceField.valueOf(target.name)
                val propertyValue = target.value.toDoubleOrNull()
                stateSetter(
                    state.copy(
                        section2021A1 = if (propertyName == MarketPresenceField.SECTION_202_1_A1) propertyValue else state.section2021A1,
                        section2021A2 = if (propertyName == MarketPresenceField.SECTION_202_1_A2) propertyValue else state.section2021A2,
                        section2021A3 = if (propertyName == MarketPresenceField.SECTION_202_1_A3) propertyValue else state.section2021A3,
                        section2021A5 = if (propertyName == MarketPresenceField.SECTION_202_1_A5) propertyValue else state.section2021A5,
                        section2021A6 = if (propertyName == MarketPresenceField.SECTION_202_1_A6) propertyValue else state.section2021A6,
                        section2021A7 = if (propertyName == MarketPresenceField.SECTION_202_1_A7) propertyValue else state.section2021A7,

                        section2022A1 = if (propertyName == MarketPresenceField.SECTION_202_2_A1) propertyValue else state.section2022A1
                    )
                )
                props.setInputState(
                    CurrentInputState(
                        childPath = propertyName.fieldName,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.MARKET_PRESENCE.name,
                        reportName = esgState.reportFileName
                    )
                )
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = MarketPresenceField.valueOf(target.name)
            val propertyValue = target.value
            stateSetter(
                state.copy(
                    section2021Field3 = if (propertyName == MarketPresenceField.SECTION_202_1_FIELD3) propertyValue else state.section2021Field3,
                    section2021Field4 = if (propertyName == MarketPresenceField.SECTION_202_1_FIELD4) propertyValue else state.section2021Field4,

                    section2022A2 = if (propertyName == MarketPresenceField.SECTION_202_2_A2) propertyValue else state.section2022A2,
                    section2022A3 = if (propertyName == MarketPresenceField.SECTION_202_2_A3) propertyValue else state.section2022A3,
                    section2022A4 = if (propertyName == MarketPresenceField.SECTION_202_2_A4) propertyValue else state.section2022A4,
                )
            )
            props.setInputState(
                CurrentInputState(
                    childPath = propertyName.fieldName,
                    value = JsonPrimitive(propertyValue.trim()),
                    objectName = EsgSection.MARKET_PRESENCE.name,
                    reportName = esgState.reportFileName
                )
            )
        }

        val onChangeText: (FormEvent<HTMLDivElement>) -> Unit = { event ->
            val target = event.target as HTMLInputElement
            val propertyName = MarketPresenceField.valueOf(target.name)
            val propertyValue = target.value
            stateSetter(
                state.copy(
                    section2021A4 = if (propertyName == MarketPresenceField.SECTION_202_1_A4) propertyValue else state.section2021A4,
                    section2021A8 = if (propertyName == MarketPresenceField.SECTION_202_1_A8) propertyValue else state.section2021A8,
                )
            )
            props.setInputState(
                CurrentInputState(
                    childPath = propertyName.fieldName,
                    value = JsonPrimitive(propertyValue.trim()),
                    objectName = EsgSection.MARKET_PRESENCE.name,
                    reportName = esgState.reportFileName,
                )
            )
        }

        Box {
            className = styles.SECTION_MAIN_LAYOUT.cssClass
            Section202_1 {
                this.state = state
                this.onChangeRadio = onChangeRadio
                this.onChangeNumber = onChangeNumber
                this.onChangeText = onChangeText
                this.onChangeTextArea = onChangeTextArea
            }
            Section202_2 {
                this.state = state
                this.onChangeNumber = onChangeNumber
                this.onChangeTextArea = onChangeTextArea
            }
        }
    }
}

