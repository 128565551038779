package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class TheOrgAndItsRepPracticesState(
    var NATURE_OF_OWNERSHIP_LEGAL_FORM: String? = null,
    var section21Field2: String? = null,
    var section21Field3: String? = null,
    var section21Field4: String? = null,
    var section21Field5: String? = null,
    var section21Field6: String? = null,
    var section21Field7: String? = null,
    var section21Field8: String? = null,

    var section22Field1: String? = null,
    var section22Field2: String? = null,
    var section22Field3: String? = null,
    var section22Field4: String? = null,
    var section22Field5: String? = null,
    var section22Field6: String? = null,

    var section23Field1: String? = null,
    var section23Field2: String? = null,
    var section23Field3: String? = null,
    var section23Field4: String? = null,
    var section23Field5: String? = null,

    var section24FieldA1: String? = null,
    var section24FieldA2: String? = null,
    var section24FieldB1: String? = null,
    var section24FieldB2: String? = null,
    var section24FieldC1: String? = null,
    var section24FieldC2: String? = null,

    var section25Field1: String? = null,
    var section25Field2: String? = null,
    var section25Field3: String? = null,
    var section25Field4: String? = null,
    var section25Field5: String? = null,
    var section25Field6: String? = null,
)