package com.ecosave.watch.portal.components.esg.indirecteconomicimpacts

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.IndirectEconomicImpactsField
import com.ecosave.watch.portal.models.esg.*
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.IndirectEconomicImpacts
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section203_2Props : Props {
    var state: IndirectEconomicImpactsState
    var stateSetter: (IndirectEconomicImpactsState) -> Unit
    var onChange: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var addRow: (String) -> Unit
    var deleteRow: (String, Int) -> Unit
}

val Section203_2 = FC<Section203_2Props> { props ->

    val onChangeFunction = props.onChange
    val state = props.state
    val stateSetter = props.stateSetter

    SectionMainTitle {
        title = "203-2 Significant Indirect Economic Impacts"
    }

    Box {
        className = ESGCommonStyles.TABLE_WRAPPER.cssClass
        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRow(IndirectEconomicImpactsField.INDIRECT_ECONOMIC_IMPACT_LIST.fieldName)
                    state.indirectEconomicImpactList.add(IndirectEconomicImpactTable())
                    stateSetter(state)
                }
                items = state.indirectEconomicImpactList
                action = DynamicTableAction.ADD
                Box {
                    className = IndirectEconomicImpacts.SECTION_203_2_TABLE.cssClass
                    TableHeader {
                        header = "Significant identified indirect economic impacts of the organization"
                    }
                    TableHeader {
                        header =
                            "Significance in the context of external benchmarks and stakeholder priorities (such as national and international standards, protocols, and policy agendas)"
                    }
                }
            }
            state.indirectEconomicImpactList.forEachIndexed{index, it ->
                DynamicTableWrapper {
                    deleteRowOrSection = {
                        props.deleteRow(IndirectEconomicImpactsField.INDIRECT_ECONOMIC_IMPACT_LIST.fieldName,index)
                        state.indirectEconomicImpactList.removeAt(index)
                        stateSetter(state)
                    }
                    items = state.indirectEconomicImpactList
                    action = DynamicTableAction.DELETE
                    Box {
                        key = "index$index"
                        className = IndirectEconomicImpacts.SECTION_203_2_TABLE_INNER.cssClass
                        ESGTextAreaFieldWrapper {
                            name = IndirectEconomicImpactsField.SECTION_2032A1.name
                            value = it.section2032A1
                            onChange = { event ->
                                onChangeFunction(
                                    event,
                                    index,
                                    IndirectEconomicImpactsField.INDIRECT_ECONOMIC_IMPACT_LIST.fieldName
                                )
                            }
                        }
                        ESGTextAreaFieldWrapper {
                            name = IndirectEconomicImpactsField.SECTION_2032B1.name
                            value = it.section2032B1
                            onChange = { event ->
                                onChangeFunction(
                                    event,
                                    index,
                                    IndirectEconomicImpactsField.INDIRECT_ECONOMIC_IMPACT_LIST.fieldName
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}