package com.ecosave.watch.portal.components.esg.generaldisclosures

import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.GovernanceField
import com.ecosave.watch.portal.models.esg.GovernanceState
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FormEvent
import react.dom.onChange
import web.html.HTMLDivElement

external interface Section216Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>) -> Unit
    var state: GovernanceState
}

val Section2_16 = FC<Section216Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val state = props.state

    SectionMainTitle {
        title = "2-16 COMMUNICATION OF CRITICAL CONCERNS"
    }

    Box {
        Label {
            label = "How critical concerns are communicated to the highest governance body"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_16_A1.name
            value = state.section216A1
            onChange = { event -> onChangeTextArea(event) }
        }
    }
    Box {
        Label {
            label =
                "Number and nature of critical concerns that were communicated to the highest governance body during the reporting period"
        }
        ESGTextAreaFieldWrapper {
            name = GovernanceField.SECTION_2_16_A2.name
            value = state.section216A2
            onChange = { event -> onChangeTextArea(event) }
        }
    }

}