package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class TrainingAndEducationState(
    var section4041Current1: Int? = null,
    var section4041Current2: Int? = null,
    var section4041Current3: Int? = null,
    var section4041Previous1: Int? = null,
    var section4041Previous2: Int? = null,
    var section4041Previous3: Int? = null,
    var section40420A1: String? = null,
    var section40420A2: String? = null,
    var section40420A3: String? = null,
    var section40420B1: String? = null,
    var section40420B2: String? = null,
    var section40420B3: String? = null,
    var section4043Current1: Double? = null,
    var section4043Current2: Double? = null,
    var section4043Current3: Double? = null,
    var section4043Previous1: Double? = null,
    var section4043Previous2: Double? = null,
    var section4043Previous3: Double? = null,
)
