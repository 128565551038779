package com.ecosave.watch.portal.components.esg.marketingandlabeling

import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.helpers.esg.MarketingAndLabelingField
import com.ecosave.watch.portal.models.esg.MarketingAndLabelingState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.Props
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.html.HTMLDivElement
import web.html.HTMLElement

external interface Section4171Props : Props {
    var state: MarketingAndLabelingState
    var onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
}

val Section417_1 = FC<Section4171Props> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val state = props.state

    SectionMainTitle {
        title = "417-1 REQUIREMENTS FOR PRODUCT AND SERVICE INFORMATION AND LABELING"
    }

    Box {
        className = ESGCommonStyles.GRID_WRAPPER.cssClass
        Box {
            label {
                +"The sourcing of components of the product or service is/not required for product and service information and labeling."
            }
            ESGTextAreaFieldWrapper {
                name = MarketingAndLabelingField.SECTION_417_1_A1.name
                value = state.section4171A1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Content, particularly with regard to substances that might produce an environmental or social impact is/not required for product and service information and labeling."
            }
            ESGTextAreaFieldWrapper {
                name = MarketingAndLabelingField.SECTION_417_1_A2.name
                value = state.section4171A2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Safe use of the product or service is/not required for product and service information and labeling."
            }
            ESGTextAreaFieldWrapper {
                name = MarketingAndLabelingField.SECTION_417_1_A3.name
                value = state.section4171A3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Disposal of the product and environmental or social impacts is/not required for product and service information and labeling."
            }
            ESGTextAreaFieldWrapper {
                name = MarketingAndLabelingField.SECTION_417_1_A4.name
                value = state.section4171A4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Other Information (explain)"
            }
            ESGTextAreaFieldWrapper {
                name = MarketingAndLabelingField.SECTION_417_1_A5.name
                value = state.section4171A5
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"What is the percentage of significant product or service categories covered by and assessed for compliance with such procedures?"
            }
            ESGNumberFieldWrapper {
                name = MarketingAndLabelingField.SECTION_417_1_A6.name
                value = state.section4171A6
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
        }
    }
}